import React, { useEffect, useRef, useState } from "react";
import "./index.css";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ErrorMessage, Formik, Form as FormikForm } from "formik";
import { toast } from "react-toastify";
import axios from "../../axios";
import { API_CALL_URL_PRIVATE, DOMAIN_URL } from "../../constants/compensate";
import Texterror from "../Texterror";
import {
  ApplicationListValidation,
  ReasonListValidation,
} from "../../utils/validator";
import { useDispatch, useSelector } from "react-redux";
import ReactSelect from "react-select";

function EditReasonMaster() {
  const fileInputRef = useRef(null);
  const fileLogoInputRef = useRef(null);
  const { ID } = useParams();
  const dispatch = useDispatch();
  console.log(ID);
  const navigate = useNavigate();
  const customer_code = useSelector((state) => state.compensate.customerCode);
  const loading_page = useSelector((state) => state.compensate.LoadingPage);
  const [isLoading, setIsLoading] = useState(false);
  const [FormInitialData, setFormInitialData] = useState();
  const [selectedImage, setSelectedImage] = useState(null);
  const [initialImageUrl, setInitialImageUrl] = useState(null);
  const [initialIconUrl, setInitialIconUrl] = useState(null);
  const [isInitialData, setISInitialData] = useState(false);
  const [isCostData, setIsCostData] = useState(false);
  const [initialValues, setInitialValues] = useState({
    reason_id: "",
    reason: "",
    status: "",
    category: "",
    cost_center_number: "",
  });
  const [costCenterMaster, setCostCenterMaster] = useState(null);
  const [dropCostCenterMaster, setDropCostCenterMaster] = useState([]);

  const getCostCenter = async () => {
    const resp = await axios.post(
      `${API_CALL_URL_PRIVATE}/department/departmentList`,
      {
        customer_code: parseInt(customer_code),
        limit: "100000",
      }
    );

    setCostCenterMaster(
      resp?.data?.data?.data?.docs?.map((e) => {
        return {
          cost_center_name: e.cost_center_name,
          cost_center_number: e.cost_center_number,
        };
      })
    );
    // setISInitialData(true);
    console.log(resp);
    const DropCost = resp?.data?.data?.data?.docs?.map((e) => {
      return {
        label: `${e.cost_center_number} - ${e.cost_center_name}`,
        value: e.cost_center_number,
      };
    });
    setDropCostCenterMaster((prev) => {
      return DropCost?.length > 0
        ? [{ value: "", label: "Please Select" }, ...DropCost]
        : [];
    });
    setIsCostData(true);
  };

  useEffect(() => {
    const getInitalData = async () => {
      const resp = await axios.post(
        `${API_CALL_URL_PRIVATE}/reasonMaster/view`,
        { _id: ID }
      );
      const Detail = resp?.data?.data?.data;
      setInitialValues((prev) => {
        return {
          ...prev,
          reason_id: Detail?.reason_id,
          reason: Detail?.reason,
          status: String(Detail?.status),
          category: Detail?.category,
          cost_center_number: Detail?.cost_center_number || "",
        };
      });

      setISInitialData(true);
    };
    getCostCenter();
    getInitalData();
  }, [ID]);

  const onSubmit = (values, onSubmittingProps) => {
    console.log("form Values", values);
    formSubmit(values);
    // setTimeout(() => {
    //   onSubmittingProps.setSubmitting(false);
    // }, [2000]);
  };
  const formSubmit = async (values) => {
    const obj = {
      _id: ID,
      customer_code: parseInt(customer_code),
      reason_id: parseInt(values.reason_id),
      reason: values.reason,
      status: parseInt(values.status),
      category: values.category,
      cost_center_number: values.cost_center_number,
    };

    try {
      setIsLoading(true);
      const resp = await axios.post(
        `${API_CALL_URL_PRIVATE}/reasonMaster/create`,
        obj
      );
      console.log(resp);
      toast.success(resp?.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        
      });
      // navigate("/admin/location-master");
    } catch (error) {
      toast.error(error.response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        
      });

      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <>
      {isInitialData && isCostData ? (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validate={ReasonListValidation}
          enableReinitialize
          validateOnChange
        >
          {(formik) => (
            <div className="main-panel">
              {console.log(formik)}
              <div className="content-wrapper">
                <div className="page-header">
                  <h3 className="page-title">
                    <a href="#" className="badge badge-dark"></a>
                  </h3>
                  <div className></div>
                </div>
                <div className="row">
                  <div class="col-12 grid-margin stretch-card">
                    <div class="card">
                      <FormikForm className="forms-sample">
                        <div class="card-body">
                          <div className="row align-items-center">
                            <div className="col-3">
                              <Link
                                to={`/admin/reason-master`}
                                className="f-14 white-text-dark-mode"
                              >
                                <FontAwesomeIcon icon="fa-solid fa-arrow-left " />{" "}
                                Back
                              </Link>
                            </div>
                            <div className="col-6">
                              <h4 class="card-title text-center">
                                Update details for : {initialValues?.reason}
                              </h4>
                            </div>
                            {/* <div className="col-3 text-end">
                              <Button
                                className="ms-3"
                                variant="success"
                                type="submit"
                                disabled={isLoading}
                              >
                                {isLoading ? "Saving..." : "Save"}
                              </Button>
                            </div> */}
                          </div>

                          <div class="row">
                            <Form.Group
                              className="col-md-4 mb-3"
                              controlId="exampleForm.c_code"
                            >
                              <Form.Label>Reason Id</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=""
                                name="reason_id"
                                {...formik.getFieldProps("reason_id")}
                              />
                              <ErrorMessage
                                name="reason_id"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-4 mb-3"
                              controlId="exampleForm.c_name"
                            >
                              <Form.Label>Reason</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=""
                                name="reason"
                                {...formik.getFieldProps("reason")}
                              />
                              <ErrorMessage
                                name="reason"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-4 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>Status</Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                className="white"
                                name="status"
                                value={formik.values.status}
                                onChange={(e) => {
                                  formik.setFieldTouched("status", true, true);
                                  if (e.target.value !== "") {
                                    formik.setFieldValue(
                                      "status",
                                      e.target.value
                                    );
                                  }
                                  // formik.setFieldValue(
                                  //   "customer_status",
                                  //   e.target.value
                                  // );
                                  // console.log(e.target.value);
                                }}
                                onBlur={() => {
                                  formik.setFieldTouched("status", true, true);
                                }}
                                // onChange={formik.handleChange}
                              >
                                <option value="1">Active</option>
                                <option value="0">Inactive</option>
                              </Form.Select>
                              <ErrorMessage
                                name="status"
                                component={Texterror}
                              />
                            </Form.Group>

                            <Form.Group
                              className="col-md-4 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>Category</Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                className="white"
                                name="category"
                                value={formik.values.category}
                                onChange={(e) => {
                                  formik.setFieldTouched(
                                    "category",
                                    true,
                                    true
                                  );
                                  // if (e.target.value !== "") {
                                  //   formik.setFieldValue(
                                  //     "category",
                                  //     e.target.value
                                  //   );
                                  // }
                                  formik.setFieldValue(
                                    "category",
                                    e.target.value
                                  );
                                  console.log(e.target.value);
                                }}
                                onBlur={() => {
                                  formik.setFieldTouched(
                                    "category",
                                    true,
                                    true
                                  );
                                }}
                                // onChange={formik.handleChange}
                              >
                                <option value="">Select</option>
                                <option value="Overtime">Overtime</option>
                                <option value="Schedule">Schedule</option>
                                <option value="Missed Meal Break">
                                  Missed Meal Break
                                </option>
                                <option value="Agency">Agency</option>
                              </Form.Select>
                              <ErrorMessage
                                name="category"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-4 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>Cost Center</Form.Label>
                              <div className="d-flex flex-column col custom-normal-design">
                                <div
                                //   className="ms-4"
                                >
                                  <ReactSelect
                                    name="cost_center_number"
                                    isSearchable="true"
                                    value={dropCostCenterMaster?.find(
                                      (e) =>
                                        e.value ===
                                        formik.values.cost_center_number
                                    )}
                                    options={dropCostCenterMaster}
                                    className="custom-select"
                                    onChange={(e) => {
                                      formik.setFieldTouched(
                                        "cost_center_number",
                                        true,
                                        true
                                      );
                                      formik.setFieldValue(
                                        "cost_center_number",
                                        e.value
                                      );
                                    }}
                                    onBlur={() => {
                                      formik.setFieldTouched(
                                        "cost_center_number",
                                        true,
                                        true
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                              <ErrorMessage
                                name="cost_center_number"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group className="col-md-12 mb-3 d-flex justify-content-end">
                              {/* <Button
                              variant="primary"
                              type="button"
                              onClick={() => {
                                formik.resetForm();
                                setSelectedImage(null);
                                // const fileInput =
                                //   document.getElementById("logo");
                                // if (fileInput) {
                                //   fileInput.value = "";
                                // }
                              }}
                            >
                              Reset
                            </Button> */}
                              <Button
                                className="ms-3"
                                variant="success"
                                type="submit"
                                disabled={isLoading}
                              >
                                {isLoading ? "Saving..." : "Save"}
                              </Button>
                            </Form.Group>
                          </div>
                        </div>
                      </FormikForm>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Formik>
      ) : (
        <div className="d-flex align-items-center justify-content-center w-100vw h-100vh">
          <span class="loader" style={{ width: "50px", height: "50px" }}></span>
        </div>
      )}
    </>
  );
}

export default EditReasonMaster;
