import React, { useEffect, useState } from "react";
import axios from "../../../axios";
import axiosR from "../../../axios";
import { API_CALL_URL_PRIVATE } from "../../../constants/compensate";
import { API_CALL_URL_PUBLIC } from "../../../constants/compensate";
import "./index.css";
import logo from "./logo.jpeg";
import { useSelector, useDispatch } from "react-redux";
import { history, useNavigate, Link } from "react-router-dom";
import {
  userLogin,
  setUpdateUserData,
} from "../../../store/actions/Compensate";
import { useMsal } from "@azure/msal-react";
import { loginValidator } from "../../../utils/validator";
import { InteractionType } from "@azure/msal-browser";
import ProfileContent from "./ProfileContent";
import { get } from "lodash";
import { Formik, Field, Form } from "formik";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function Index() {
  const { instance } = useMsal(); // Use the context provided by MsalProvider
  const userData = JSON.parse(localStorage.getItem("psAdmin"));
  const navigate = useNavigate();
  const [errorObj, setErrorObj] = useState({});
  const dispatch = useDispatch();
  const [checkBox, setCheckBox] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loginValues, setLoginValues] = useState({
    email: "",
    password: "",
  });
  const handleLogin = () => {
    instance
      .loginPopup({ scopes: ["openid", "profile", "user.read"] })
      .then((loginResponse) => {
        loginSubmit(loginResponse?.account);
      })
      .catch((error) => {});
  };
  const loginSubmit = async (accounts) => {
    if (accounts?.username) {
      try {
        setIsLoading(true);
        // console.log("refresh prevented");
        setErrorObj({});
        let { data } = await axios.post(
          `${API_CALL_URL_PUBLIC}/auth/login`,
          {
            username: accounts.username,
            type: 1,
            tenant_id: accounts?.tenantId,
            //"checkbox" :  form.checkbox
          }
        );
        navigate("/admin/home");
        localStorage.setItem("accessToken", data?.token?.accessToken);
        localStorage.setItem("psAdmin", JSON.stringify(data));
        dispatch(setUpdateUserData(data));
        setIsLoading(false);

        // window.location.reload();
        // console.warn(form, data, { error: 1 });
      } catch (error) {
        toast.error(`${error?.response?.data?.message}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setIsLoading(false);
        if (error.response.status == 400) {
          setErrorObj(error.response.data);
        }
        console.log({ errorObj });
      }
    } else {
      try {
        setErrorObj({});
        let { data } = await axios.post(
          `${API_CALL_URL_PUBLIC}/auth/login`,
          {
            username: accounts.email,
            password: accounts.password,
          }
        );

        navigate("/home");

        localStorage.setItem("accessToken", data?.token?.accessToken);
        localStorage.setItem("psAdmin", JSON.stringify(data));
        dispatch(setUpdateUserData(data.data));
      } catch (error) {
        toast.error(`${error?.response?.data?.message}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
        if (error.response.status == 400) {
          setErrorObj(error.response.data);
        }
      }
    }
  };


  return (
    <>
    {!isLoading ? 
      <div class="dashboard-wrapper full-width h-100vh overflow-hidden">
        <div class="container py-3 pb-0 h-100vh ">
          <div class="row height-100 justify-content-center align-items-center h-100vh">
            <div class="login-screen max-width-300 ">
              <div class="text-center">
                <img src={logo} alt="" class="img-fluid mb-5" />
              </div>
              <Formik
                enableReinitialize
                initialValues={loginValues}
                validateOnChange
                validate={loginValidator}
                onSubmit={(values) => loginSubmit(values, false)}
              >
                {(formikBag) => {
                  return (
                    <Form style={{ width: "100%" }}>
                      <div class="mb-3">
                        <label>Username or Email *</label>
                        <Field name="email">
                          {({ field }) => (
                            <input
                              {...field}
                              type="username"
                              name="username"
                              class="form-control"
                              placeholder="Username or Email"
                              value={formikBag.values.email}
                              onChange={(e) => {
                                formikBag.setFieldValue(
                                  "email",
                                  e.target.value
                                );
                              }}
                            />
                          )}
                        </Field>
                        {formikBag.touched.email && formikBag.errors.email ? (
                          <>
                            <p
                              style={{
                                marginTop: 5,
                                fontSize: 13,
                                color: "red",
                              }}
                            >
                              {formikBag.errors.email}
                            </p>
                          </>
                        ) : null}
                      </div>
                      <div class="mb-3">
                        <label>Password *</label>
                        <Field name="password">
                          {({ field }) => (
                            <input
                              {...field}
                              type="password"
                              name="password"
                              class="form-control"
                              placeholder="Password"
                              value={formikBag.values.password}
                              onChange={(e) => {
                                formikBag.setFieldValue(
                                  "password",
                                  e.target.value
                                );
                              }}
                            />
                          )}
                        </Field>
                        {formikBag.touched.password &&
                        formikBag.errors.password ? (
                          <>
                            <p
                              style={{
                                marginTop: 5,
                                fontSize: 13,
                                color: "red",
                              }}
                            >
                              {formikBag.errors.password}
                            </p>
                          </>
                        ) : null}
                      </div>
                      <div class="mb-3 d-flex align-items-center justify-content-between">
                        <div class="form-check">
                          <label class="form-check-label show">
                            <inputw
                              type="checkbox"
                              class="form-check-input h-16 show"
                              value={checkBox}
                              onChange={(e) => setCheckBox(e.target.checked)}
                            />{" "}
                            Remember Me <i class="input-helper"></i>
                          </label>
                        </div>
                        {/* <Link to="/forgot" class="hover-text">
                          Forgot password
                        </Link> */}
                      </div>
                      <div class="col-12">
                        <button
                          type="submit"
                          class="btn btm btn-success w-100 text-capitalize"
                        >
                          Login
                        </button>
                      </div>
                      <div class="col-12">
                        <button
                          type="button"
                          onClick={handleLogin}
                          class="btn btm btn-primary w-100 text-capitalize"
                        >
                          Login With SSO
                        </button>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </div>: 
        <div className="d-flex align-items-center justify-content-center w-100vw h-100vh">
          <span class="loader" style={{ width: "50px", height: "50px" }}></span>
        </div>
    }
      <ToastContainer />
    </>
  );
}

export default Index;
