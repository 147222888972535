import React from "react";
import { Switch } from "antd";
import "./index.css";
import calcIcon from "../../assets/img/calculator_hires.png";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const PatientDataAccordian = ({
  formControl,
  realtimeFormulaHeaders,
  handlePatientSort,
  handlePatientLabel,
  handlePatientSwitchcheck,
  handlePatientSwitchOnChange,
}) => {
  return (
    <>
      <div class="row">
        <div className="col-md-12 m-0">
          <hr />
        </div>
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-12">
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th>Show/Hide/Sort</th>
                      <th></th>
                      <th>Key</th>
                      <th>Label</th>
                      <th>Mandatory</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.patient_data?.find(
                              (e) => e.field_name === "acuity"
                            ).display
                          }
                          onChange={(e) => {
                            handlePatientSort(
                              "acuity",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["acuity"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {realtimeFormulaHeaders?.["acuity"]}
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "acuity"
                              ).field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "acuity"
                              ).field_label
                            }
                            onChange={(e) => {
                              handlePatientLabel("acuity", e.target.value);
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handlePatientSwitchcheck("acuity")}
                          className="blue-b"
                          onChange={(e) => {
                            handlePatientSwitchOnChange("acuity", e);
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.patient_data?.find(
                              (e) => e.field_name === "total_patients"
                            ).display
                          }
                          onChange={(e) => {
                            handlePatientSort(
                              "total_patients",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["total_patients"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {realtimeFormulaHeaders?.["total_patients"]}
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "total_patients"
                              ).field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "total_patients"
                              ).field_label
                            }
                            onChange={(e) => {
                              handlePatientLabel(
                                "total_patients",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handlePatientSwitchcheck("total_patients")}
                          className="blue-b"
                          onChange={(e) => {
                            handlePatientSwitchOnChange("total_patients", e);
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.patient_data?.find(
                              (e) => e.field_name === "total_patients_two"
                            ).display
                          }
                          onChange={(e) => {
                            handlePatientSort(
                              "total_patients_two",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>

                      {realtimeFormulaHeaders?.["total_patients_two"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {
                                      realtimeFormulaHeaders?.[
                                        "total_patients_two"
                                      ]
                                    }
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "total_patients_two"
                              ).field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "total_patients_two"
                              ).field_label
                            }
                            onChange={(e) => {
                              handlePatientLabel(
                                "total_patients_two",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handlePatientSwitchcheck(
                            "total_patients_two"
                          )}
                          className="blue-b"
                          onChange={(e) => {
                            handlePatientSwitchOnChange(
                              "total_patients_two",
                              e
                            );
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.patient_data?.find(
                              (e) => e.field_name === "total_patients_three"
                            ).display
                          }
                          onChange={(e) => {
                            handlePatientSort(
                              "total_patients_three",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>

                      {realtimeFormulaHeaders?.["total_patients_three"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {
                                      realtimeFormulaHeaders?.[
                                        "total_patients_three"
                                      ]
                                    }
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "total_patients_three"
                              ).field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "total_patients_three"
                              ).field_label
                            }
                            onChange={(e) => {
                              handlePatientLabel(
                                "total_patients_three",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handlePatientSwitchcheck(
                            "total_patients_three"
                          )}
                          className="blue-b"
                          onChange={(e) => {
                            handlePatientSwitchOnChange(
                              "total_patients_three",
                              e
                            );
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.patient_data?.find(
                              (e) => e.field_name === "two_one"
                            ).display
                          }
                          onChange={(e) => {
                            handlePatientSort(
                              "two_one",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["two_one"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {realtimeFormulaHeaders?.["two_one"]}
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "two_one"
                              ).field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>{" "}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "two_one"
                              ).field_label
                            }
                            onChange={(e) => {
                              handlePatientLabel("two_one", e.target.value);
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handlePatientSwitchcheck("two_one")}
                          className="blue-b"
                          onChange={(e) => {
                            handlePatientSwitchOnChange("two_one", e);
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.patient_data?.find(
                              (e) => e.field_name === "one_one"
                            ).display
                          }
                          onChange={(e) => {
                            handlePatientSort(
                              "one_one",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["one_one"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {realtimeFormulaHeaders?.["one_one"]}
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "one_one"
                              ).field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "one_one"
                              ).field_label
                            }
                            onChange={(e) => {
                              handlePatientLabel("one_one", e.target.value);
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handlePatientSwitchcheck("one_one")}
                          className="blue-b"
                          onChange={(e) => {
                            handlePatientSwitchOnChange("one_one", e);
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.patient_data?.find(
                              (e) => e.field_name === "one_two"
                            ).display
                          }
                          onChange={(e) => {
                            handlePatientSort(
                              "one_two",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["one_two"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {realtimeFormulaHeaders?.["one_two"]}
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "one_two"
                              ).field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "one_two"
                              ).field_label
                            }
                            onChange={(e) => {
                              handlePatientLabel("one_two", e.target.value);
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handlePatientSwitchcheck("one_two")}
                          className="blue-b"
                          onChange={(e) => {
                            handlePatientSwitchOnChange("one_two", e);
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.patient_data?.find(
                              (e) => e.field_name === "one_three"
                            ).display
                          }
                          onChange={(e) => {
                            handlePatientSort(
                              "one_three",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>

                      {realtimeFormulaHeaders?.["one_three"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {realtimeFormulaHeaders?.["one_three"]}
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "one_three"
                              ).field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "one_three"
                              ).field_label
                            }
                            onChange={(e) => {
                              handlePatientLabel("one_three", e.target.value);
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handlePatientSwitchcheck("one_three")}
                          className="blue-b"
                          onChange={(e) => {
                            handlePatientSwitchOnChange("one_three", e);
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.patient_data?.find(
                              (e) => e.field_name === "one_four"
                            ).display
                          }
                          onChange={(e) => {
                            handlePatientSort(
                              "one_four",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>

                      {realtimeFormulaHeaders?.["one_four"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {realtimeFormulaHeaders?.["one_four"]}
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "one_four"
                              ).field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "one_four"
                              ).field_label
                            }
                            onChange={(e) => {
                              handlePatientLabel("one_four", e.target.value);
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handlePatientSwitchcheck("one_four")}
                          className="blue-b"
                          onChange={(e) => {
                            handlePatientSwitchOnChange("one_four", e);
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.patient_data?.find(
                              (e) => e.field_name === "one_five"
                            ).display
                          }
                          onChange={(e) => {
                            handlePatientSort(
                              "one_five",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["one_five"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {realtimeFormulaHeaders?.["one_five"]}
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "one_five"
                              ).field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "one_five"
                              ).field_label
                            }
                            onChange={(e) => {
                              handlePatientLabel("one_five", e.target.value);
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handlePatientSwitchcheck("one_five")}
                          className="blue-b"
                          onChange={(e) => {
                            handlePatientSwitchOnChange("one_five", e);
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.patient_data?.find(
                              (e) => e.field_name === "one_six"
                            ).display
                          }
                          onChange={(e) => {
                            handlePatientSort(
                              "one_six",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>

                      {realtimeFormulaHeaders?.["one_six"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {realtimeFormulaHeaders?.["one_six"]}
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "one_six"
                              ).field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "one_six"
                              ).field_label
                            }
                            onChange={(e) => {
                              handlePatientLabel("one_six", e.target.value);
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handlePatientSwitchcheck("one_six")}
                          className="blue-b"
                          onChange={(e) => {
                            handlePatientSwitchOnChange("one_six", e);
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.patient_data?.find(
                              (e) => e.field_name === "wit"
                            ).display
                          }
                          onChange={(e) => {
                            handlePatientSort(
                              "wit",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>

                      {realtimeFormulaHeaders?.["wit"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {realtimeFormulaHeaders?.["wit"]}
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "wit"
                              ).field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "wit"
                              ).field_label
                            }
                            onChange={(e) => {
                              handlePatientLabel("wit", e.target.value);
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handlePatientSwitchcheck("wit")}
                          className="blue-b"
                          onChange={(e) => {
                            handlePatientSwitchOnChange("wit", e);
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.patient_data?.find(
                              (e) => e.field_name === "wit_rn"
                            ).display
                          }
                          onChange={(e) => {
                            handlePatientSort(
                              "wit_rn",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>

                      {realtimeFormulaHeaders?.["wit_rn"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {realtimeFormulaHeaders?.["wit_rn"]}
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "wit_rn"
                              ).field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "wit_rn"
                              ).field_label
                            }
                            onChange={(e) => {
                              handlePatientLabel("wit_rn", e.target.value);
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handlePatientSwitchcheck("wit_rn")}
                          className="blue-b"
                          onChange={(e) => {
                            handlePatientSwitchOnChange("wit_rn", e);
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.patient_data?.find(
                              (e) => e.field_name === "rn_available_hours"
                            ).display
                          }
                          onChange={(e) => {
                            handlePatientSort(
                              "rn_available_hours",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["rn_available_hours"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {
                                      realtimeFormulaHeaders?.[
                                        "rn_available_hours"
                                      ]
                                    }
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "rn_available_hours"
                              ).field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "rn_available_hours"
                              ).field_label
                            }
                            onChange={(e) => {
                              handlePatientLabel(
                                "rn_available_hours",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handlePatientSwitchcheck(
                            "rn_available_hours"
                          )}
                          className="blue-b"
                          onChange={(e) => {
                            handlePatientSwitchOnChange(
                              "rn_available_hours",
                              e
                            );
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl?.patient_data?.find(
                              (e) => e?.field_name === "rn_variance"
                            )?.display
                          }
                          onChange={(e) => {
                            handlePatientSort(
                              "rn_variance",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>

                      {realtimeFormulaHeaders?.["rn_variance"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {realtimeFormulaHeaders?.["rn_variance"]}
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={"rn_variance"}
                            readOnly
                          />
                        </div>
                      </td>
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl?.patient_data?.find(
                                (e) => e?.field_name === "rn_variance"
                              )?.field_label
                            }
                            onChange={(e) => {
                              handlePatientLabel("rn_variance", e.target.value);
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handlePatientSwitchcheck("rn_variance")}
                          className="blue-b"
                          onChange={(e) => {
                            handlePatientSwitchOnChange("rn_variance", e);
                          }}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.patient_data?.find(
                              (e) => e.field_name === "observation_patients"
                            ).display
                          }
                          onChange={(e) => {
                            handlePatientSort(
                              "observation_patients",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>

                      {realtimeFormulaHeaders?.["observation_patients"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {
                                      realtimeFormulaHeaders?.[
                                        "observation_patients"
                                      ]
                                    }
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "observation_patients"
                              ).field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "observation_patients"
                              ).field_label
                            }
                            onChange={(e) => {
                              handlePatientLabel(
                                "observation_patients",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handlePatientSwitchcheck(
                            "observation_patients"
                          )}
                          className="blue-b"
                          onChange={(e) => {
                            handlePatientSwitchOnChange(
                              "observation_patients",
                              e
                            );
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.patient_data?.find(
                              (e) => e.field_name === "inpatient_boarders"
                            ).display
                          }
                          onChange={(e) => {
                            handlePatientSort(
                              "inpatient_boarders",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["inpatient_boarders"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {
                                      realtimeFormulaHeaders?.[
                                        "inpatient_boarders"
                                      ]
                                    }
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "inpatient_boarders"
                              ).field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "inpatient_boarders"
                              ).field_label
                            }
                            onChange={(e) => {
                              handlePatientLabel(
                                "inpatient_boarders",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handlePatientSwitchcheck(
                            "inpatient_boarders"
                          )}
                          className="blue-b"
                          onChange={(e) => {
                            handlePatientSwitchOnChange(
                              "inpatient_boarders",
                              e
                            );
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.patient_data?.find(
                              (e) => e.field_name === "pt_type1_0_20"
                            ).display
                          }
                          onChange={(e) => {
                            handlePatientSort(
                              "pt_type1_0_20",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["pt_type1_0_20"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {realtimeFormulaHeaders?.["pt_type1_0_20"]}
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "pt_type1_0_20"
                              ).field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "pt_type1_0_20"
                              ).field_label
                            }
                            onChange={(e) => {
                              handlePatientLabel(
                                "pt_type1_0_20",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handlePatientSwitchcheck("pt_type1_0_20")}
                          className="blue-b"
                          onChange={(e) => {
                            handlePatientSwitchOnChange("pt_type1_0_20", e);
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.patient_data?.find(
                              (e) => e.field_name === "pt_type2_21_28"
                            ).display
                          }
                          onChange={(e) => {
                            handlePatientSort(
                              "pt_type2_21_28",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>

                      {realtimeFormulaHeaders?.["pt_type2_21_28"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {realtimeFormulaHeaders?.["pt_type2_21_28"]}
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "pt_type2_21_28"
                              ).field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "pt_type2_21_28"
                              ).field_label
                            }
                            onChange={(e) => {
                              handlePatientLabel(
                                "pt_type2_21_28",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handlePatientSwitchcheck("pt_type2_21_28")}
                          className="blue-b"
                          onChange={(e) => {
                            handlePatientSwitchOnChange("pt_type2_21_28", e);
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.patient_data?.find(
                              (e) => e.field_name === "pt_type3_29_68"
                            ).display
                          }
                          onChange={(e) => {
                            handlePatientSort(
                              "pt_type3_29_68",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["pt_type3_29_68"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {realtimeFormulaHeaders?.["pt_type3_29_68"]}
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "pt_type3_29_68"
                              ).field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "pt_type3_29_68"
                              ).field_label
                            }
                            onChange={(e) => {
                              handlePatientLabel(
                                "pt_type3_29_68",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handlePatientSwitchcheck("pt_type3_29_68")}
                          className="blue-b"
                          onChange={(e) => {
                            handlePatientSwitchOnChange("pt_type3_29_68", e);
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.patient_data?.find(
                              (e) => e.field_name === "labor_patients_recovery"
                            ).display
                          }
                          onChange={(e) => {
                            handlePatientSort(
                              "labor_patients_recovery",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["labor_patients_recovery"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {
                                      realtimeFormulaHeaders?.[
                                        "labor_patients_recovery"
                                      ]
                                    }
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) =>
                                  e.field_name === "labor_patients_recovery"
                              ).field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) =>
                                  e.field_name === "labor_patients_recovery"
                              ).field_label
                            }
                            onChange={(e) => {
                              handlePatientLabel(
                                "labor_patients_recovery",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handlePatientSwitchcheck(
                            "labor_patients_recovery"
                          )}
                          className="blue-b"
                          onChange={(e) => {
                            handlePatientSwitchOnChange(
                              "labor_patients_recovery",
                              e
                            );
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.patient_data?.find(
                              (e) => e.field_name === "nicu_cpap"
                            ).display
                          }
                          onChange={(e) => {
                            handlePatientSort(
                              "nicu_cpap",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["nicu_cpap"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {realtimeFormulaHeaders?.["nicu_cpap"]}
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "nicu_cpap"
                              ).field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "nicu_cpap"
                              ).field_label
                            }
                            onChange={(e) => {
                              handlePatientLabel("nicu_cpap", e.target.value);
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handlePatientSwitchcheck("nicu_cpap")}
                          className="blue-b"
                          onChange={(e) => {
                            handlePatientSwitchOnChange("nicu_cpap", e);
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.patient_data?.find(
                              (e) => e.field_name === "nicu_feeder_growers"
                            ).display
                          }
                          onChange={(e) => {
                            handlePatientSort(
                              "nicu_feeder_growers",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["nicu_feeder_growers"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {
                                      realtimeFormulaHeaders?.[
                                        "nicu_feeder_growers"
                                      ]
                                    }
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "nicu_feeder_growers"
                              ).field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "nicu_feeder_growers"
                              ).field_label
                            }
                            onChange={(e) => {
                              handlePatientLabel(
                                "nicu_feeder_growers",
                                e.target.value
                              );
                            }}
                          />
                          
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handlePatientSwitchcheck(
                            "nicu_feeder_growers"
                          )}
                          className="blue-b"
                          onChange={(e) => {
                            handlePatientSwitchOnChange(
                              "nicu_feeder_growers",
                              e
                            );
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.patient_data?.find(
                              (e) => e.field_name === "post_op_pp_one_two"
                            ).display
                          }
                          onChange={(e) => {
                            handlePatientSort(
                              "post_op_pp_one_two",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["post_op_pp_one_two"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {realtimeFormulaHeaders?.["post_op_pp_one_two"]}
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "post_op_pp_one_two"
                              ).field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "post_op_pp_one_two"
                              ).field_label
                            }
                            onChange={(e) => {
                              handlePatientLabel(
                                "post_op_pp_one_two",
                                e.target.value
                              );
                            }}
                          />
                          
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handlePatientSwitchcheck(
                            "post_op_pp_one_two"
                          )}
                          className="blue-b"
                          onChange={(e) => {
                            handlePatientSwitchOnChange(
                              "post_op_pp_one_two",
                              e
                            );
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.patient_data?.find(
                              (e) => e.field_name === "stable_pp_one_three"
                            ).display
                          }
                          onChange={(e) => {
                            handlePatientSort(
                              "stable_pp_one_three",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["stable_pp_one_three"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {realtimeFormulaHeaders?.["stable_pp_one_three"]}
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "stable_pp_one_three"
                              ).field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "stable_pp_one_three"
                              ).field_label
                            }
                            onChange={(e) => {
                              handlePatientLabel(
                                "stable_pp_one_three",
                                e.target.value
                              );
                            }}
                          />
                          
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handlePatientSwitchcheck(
                            "stable_pp_one_three"
                          )}
                          className="blue-b"
                          onChange={(e) => {
                            handlePatientSwitchOnChange(
                              "stable_pp_one_three",
                              e
                            );
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.patient_data?.find(
                              (e) => e.field_name === "pp_rn_variance"
                            )?.display
                          }
                          onChange={(e) => {
                            handlePatientSort(
                              "pp_rn_variance",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["pp_rn_variance"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {realtimeFormulaHeaders?.["pp_rn_variance"]}
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "pp_rn_variance"
                              )?.field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "pp_rn_variance"
                              )?.field_label
                            }
                            onChange={(e) => {
                              handlePatientLabel(
                                "pp_rn_variance",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handlePatientSwitchcheck("pp_rn_variance")}
                          className="blue-b"
                          onChange={(e) => {
                            handlePatientSwitchOnChange("pp_rn_variance", e);
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.patient_data?.find(
                              (e) => e.field_name === "l_and_d_rn_variance"
                            )?.display
                          }
                          onChange={(e) => {
                            handlePatientSort(
                              "l_and_d_rn_variance",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["l_and_d_rn_variance"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {realtimeFormulaHeaders?.["l_and_d_rn_variance"]}
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "l_and_d_rn_variance"
                              )?.field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "l_and_d_rn_variance"
                              )?.field_label
                            }
                            onChange={(e) => {
                              handlePatientLabel(
                                "l_and_d_rn_variance",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handlePatientSwitchcheck(
                            "l_and_d_rn_variance"
                          )}
                          className="blue-b"
                          onChange={(e) => {
                            handlePatientSwitchOnChange(
                              "l_and_d_rn_variance",
                              e
                            );
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.patient_data?.find(
                              (e) => e.field_name === "nicu_variance"
                            )?.display
                          }
                          onChange={(e) => {
                            handlePatientSort(
                              "nicu_variance",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["nicu_variance"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {realtimeFormulaHeaders?.["nicu_variance"]}
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "nicu_variance"
                              )?.field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "nicu_variance"
                              )?.field_label
                            }
                            onChange={(e) => {
                              handlePatientLabel(
                                "nicu_variance",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handlePatientSwitchcheck("nicu_variance")}
                          className="blue-b"
                          onChange={(e) => {
                            handlePatientSwitchOnChange("nicu_variance", e);
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.patient_data?.find(
                              (e) => e.field_name === "custom_rn_variance"
                            )?.display
                          }
                          onChange={(e) => {
                            handlePatientSort(
                              "custom_rn_variance",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["custom_rn_variance"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {realtimeFormulaHeaders?.["custom_rn_variance"]}
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "custom_rn_variance"
                              )?.field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "custom_rn_variance"
                              )?.field_label
                            }
                            onChange={(e) => {
                              handlePatientLabel(
                                "custom_rn_variance",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handlePatientSwitchcheck(
                            "custom_rn_variance"
                          )}
                          className="blue-b"
                          onChange={(e) => {
                            handlePatientSwitchOnChange(
                              "custom_rn_variance",
                              e
                            );
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.patient_data?.find(
                              (e) => e.field_name === "total_uos"
                            )?.display
                          }
                          onChange={(e) => {
                            handlePatientSort(
                              "total_uos",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["total_uos"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {realtimeFormulaHeaders?.["total_uos"]}
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "total_uos"
                              )?.field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "total_uos"
                              )?.field_label
                            }
                            onChange={(e) => {
                              handlePatientLabel("total_uos", e.target.value);
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handlePatientSwitchcheck("total_uos")}
                          className="blue-b"
                          onChange={(e) => {
                            handlePatientSwitchOnChange("total_uos", e);
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.patient_data?.find(
                              (e) => e.field_name === "of_cardiac_procedures"
                            )?.display
                          }
                          onChange={(e) => {
                            handlePatientSort(
                              "of_cardiac_procedures",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["of_cardiac_procedures"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {realtimeFormulaHeaders?.["of_cardiac_procedures"]}
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "of_cardiac_procedures"
                              )?.field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "of_cardiac_procedures"
                              )?.field_label
                            }
                            onChange={(e) => {
                              handlePatientLabel(
                                "of_cardiac_procedures",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handlePatientSwitchcheck(
                            "of_cardiac_procedures"
                          )}
                          className="blue-b"
                          onChange={(e) => {
                            handlePatientSwitchOnChange(
                              "of_cardiac_procedures",
                              e
                            );
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.patient_data?.find(
                              (e) => e.field_name === "front_desk_reg"
                            )?.display
                          }
                          onChange={(e) => {
                            handlePatientSort(
                              "front_desk_reg",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["front_desk_reg"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {realtimeFormulaHeaders?.["front_desk_reg"]}
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "front_desk_reg"
                              )?.field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "front_desk_reg"
                              )?.field_label
                            }
                            onChange={(e) => {
                              handlePatientLabel(
                                "front_desk_reg",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handlePatientSwitchcheck("front_desk_reg")}
                          className="blue-b"
                          onChange={(e) => {
                            handlePatientSwitchOnChange("front_desk_reg", e);
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.patient_data?.find(
                              (e) => e.field_name === "ivs_encounters_processed"
                            )?.display
                          }
                          onChange={(e) => {
                            handlePatientSort(
                              "ivs_encounters_processed",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["ivs_encounters_processed"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {realtimeFormulaHeaders?.["ivs_encounters_processed"]}
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) =>
                                  e.field_name === "ivs_encounters_processed"
                              )?.field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) =>
                                  e.field_name === "ivs_encounters_processed"
                              )?.field_label
                            }
                            onChange={(e) => {
                              handlePatientLabel(
                                "ivs_encounters_processed",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handlePatientSwitchcheck(
                            "ivs_encounters_processed"
                          )}
                          className="blue-b"
                          onChange={(e) => {
                            handlePatientSwitchOnChange(
                              "ivs_encounters_processed",
                              e
                            );
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.patient_data?.find(
                              (e) => e.field_name === "pts_scheduled"
                            )?.display
                          }
                          onChange={(e) => {
                            handlePatientSort(
                              "pts_scheduled",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["pts_scheduled"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {realtimeFormulaHeaders?.["pts_scheduled"]}
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "pts_scheduled"
                              )?.field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "pts_scheduled"
                              )?.field_label
                            }
                            onChange={(e) => {
                              handlePatientLabel(
                                "pts_scheduled",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handlePatientSwitchcheck("pts_scheduled")}
                          className="blue-b"
                          onChange={(e) => {
                            handlePatientSwitchOnChange("pts_scheduled", e);
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.patient_data?.find(
                              (e) => e.field_name === "new_referral"
                            )?.display
                          }
                          onChange={(e) => {
                            handlePatientSort(
                              "new_referral",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["new_referral"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {realtimeFormulaHeaders?.["new_referral"]}
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "new_referral"
                              )?.field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "new_referral"
                              )?.field_label
                            }
                            onChange={(e) => {
                              handlePatientLabel(
                                "new_referral",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handlePatientSwitchcheck("new_referral")}
                          className="blue-b"
                          onChange={(e) => {
                            handlePatientSwitchOnChange("new_referral", e);
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.patient_data?.find(
                              (e) => e.field_name === "work_queue_calls"
                            )?.display
                          }
                          onChange={(e) => {
                            handlePatientSort(
                              "work_queue_calls",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["work_queue_calls"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {realtimeFormulaHeaders?.["work_queue_calls"]}
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "work_queue_calls"
                              )?.field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "work_queue_calls"
                              )?.field_label
                            }
                            onChange={(e) => {
                              handlePatientLabel(
                                "work_queue_calls",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handlePatientSwitchcheck("work_queue_calls")}
                          className="blue-b"
                          onChange={(e) => {
                            handlePatientSwitchOnChange("work_queue_calls", e);
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.patient_data?.find(
                              (e) => e.field_name === "procedures"
                            )?.display
                          }
                          onChange={(e) => {
                            handlePatientSort(
                              "procedures",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["procedures"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {realtimeFormulaHeaders?.["procedures"]}
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "procedures"
                              )?.field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "procedures"
                              )?.field_label
                            }
                            onChange={(e) => {
                              handlePatientLabel("procedures", e.target.value);
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handlePatientSwitchcheck("procedures")}
                          className="blue-b"
                          onChange={(e) => {
                            handlePatientSwitchOnChange("procedures", e);
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.patient_data?.find(
                              (e) => e.field_name === "echo"
                            )?.display
                          }
                          onChange={(e) => {
                            handlePatientSort(
                              "echo",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["echo"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {realtimeFormulaHeaders?.["echo"]}
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "echo"
                              )?.field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "echo"
                              )?.field_label
                            }
                            onChange={(e) => {
                              handlePatientLabel("echo", e.target.value);
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handlePatientSwitchcheck("echo")}
                          className="blue-b"
                          onChange={(e) => {
                            handlePatientSwitchOnChange("echo", e);
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.patient_data?.find(
                              (e) => e.field_name === "dobutamine_stress_echo"
                            )?.display
                          }
                          onChange={(e) => {
                            handlePatientSort(
                              "dobutamine_stress_echo",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["dobutamine_stress_echo"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {realtimeFormulaHeaders?.["dobutamine_stress_echo"]}
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "dobutamine_stress_echo"
                              )?.field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "dobutamine_stress_echo"
                              )?.field_label
                            }
                            onChange={(e) => {
                              handlePatientLabel(
                                "dobutamine_stress_echo",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handlePatientSwitchcheck(
                            "dobutamine_stress_echo"
                          )}
                          className="blue-b"
                          onChange={(e) => {
                            handlePatientSwitchOnChange(
                              "dobutamine_stress_echo",
                              e
                            );
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.patient_data?.find(
                              (e) => e.field_name === "stress_echo"
                            )?.display
                          }
                          onChange={(e) => {
                            handlePatientSort(
                              "stress_echo",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["stress_echo"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {realtimeFormulaHeaders?.["stress_echo"]}
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "stress_echo"
                              )?.field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "stress_echo"
                              )?.field_label
                            }
                            onChange={(e) => {
                              handlePatientLabel("stress_echo", e.target.value);
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handlePatientSwitchcheck("stress_echo")}
                          className="blue-b"
                          onChange={(e) => {
                            handlePatientSwitchOnChange("stress_echo", e);
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.patient_data?.find(
                              (e) => e.field_name === "transesopageal_echo"
                            )?.display
                          }
                          onChange={(e) => {
                            handlePatientSort(
                              "transesopageal_echo",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["transesopageal_echo"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {realtimeFormulaHeaders?.["transesopageal_echo"]}
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "transesopageal_echo"
                              )?.field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "transesopageal_echo"
                              )?.field_label
                            }
                            onChange={(e) => {
                              handlePatientLabel(
                                "transesopageal_echo",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handlePatientSwitchcheck(
                            "transesopageal_echo"
                          )}
                          className="blue-b"
                          onChange={(e) => {
                            handlePatientSwitchOnChange(
                              "transesopageal_echo",
                              e
                            );
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.patient_data?.find(
                              (e) => e.field_name === "gi"
                            )?.display
                          }
                          onChange={(e) => {
                            handlePatientSort(
                              "gi",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["gi"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {realtimeFormulaHeaders?.["gi"]}
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "gi"
                              )?.field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "gi"
                              )?.field_label
                            }
                            onChange={(e) => {
                              handlePatientLabel("gi", e.target.value);
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handlePatientSwitchcheck("gi")}
                          className="blue-b"
                          onChange={(e) => {
                            handlePatientSwitchOnChange("gi", e);
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.patient_data?.find(
                              (e) => e.field_name === "ercp"
                            )?.display
                          }
                          onChange={(e) => {
                            handlePatientSort(
                              "ercp",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["ercp"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {realtimeFormulaHeaders?.["ercp"]}
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "ercp"
                              )?.field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "ercp"
                              )?.field_label
                            }
                            onChange={(e) => {
                              handlePatientLabel("ercp", e.target.value);
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handlePatientSwitchcheck("ercp")}
                          className="blue-b"
                          onChange={(e) => {
                            handlePatientSwitchOnChange("ercp", e);
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.patient_data?.find(
                              (e) => e.field_name === "bronchoscopy"
                            )?.display
                          }
                          onChange={(e) => {
                            handlePatientSort(
                              "bronchoscopy",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["bronchoscopy"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {realtimeFormulaHeaders?.["bronchoscopy"]}
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "bronchoscopy"
                              )?.field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "bronchoscopy"
                              )?.field_label
                            }
                            onChange={(e) => {
                              handlePatientLabel(
                                "bronchoscopy",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handlePatientSwitchcheck("bronchoscopy")}
                          className="blue-b"
                          onChange={(e) => {
                            handlePatientSwitchOnChange("bronchoscopy", e);
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.patient_data?.find(
                              (e) => e.field_name === "planned_procedures"
                            )?.display
                          }
                          onChange={(e) => {
                            handlePatientSort(
                              "planned_procedures",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["planned_procedures"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {realtimeFormulaHeaders?.["planned_procedures"]}
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "planned_procedures"
                              )?.field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "planned_procedures"
                              )?.field_label
                            }
                            onChange={(e) => {
                              handlePatientLabel(
                                "planned_procedures",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handlePatientSwitchcheck(
                            "planned_procedures"
                          )}
                          className="blue-b"
                          onChange={(e) => {
                            handlePatientSwitchOnChange(
                              "planned_procedures",
                              e
                            );
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.patient_data?.find(
                              (e) => e.field_name === "of_minutes"
                            )?.display
                          }
                          onChange={(e) => {
                            handlePatientSort(
                              "of_minutes",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["of_minutes"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {realtimeFormulaHeaders?.["of_minutes"]}
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "of_minutes"
                              )?.field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "of_minutes"
                              )?.field_label
                            }
                            onChange={(e) => {
                              handlePatientLabel("of_minutes", e.target.value);
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handlePatientSwitchcheck("of_minutes")}
                          className="blue-b"
                          onChange={(e) => {
                            handlePatientSwitchOnChange("of_minutes", e);
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.patient_data?.find(
                              (e) => e.field_name === "of_cases"
                            )?.display
                          }
                          onChange={(e) => {
                            handlePatientSort(
                              "of_cases",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["of_cases"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {realtimeFormulaHeaders?.["of_cases"]}
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "of_cases"
                              )?.field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "of_cases"
                              )?.field_label
                            }
                            onChange={(e) => {
                              handlePatientLabel("of_cases", e.target.value);
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handlePatientSwitchcheck("of_cases")}
                          className="blue-b"
                          onChange={(e) => {
                            handlePatientSwitchOnChange("of_cases", e);
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.patient_data?.find(
                              (e) => e.field_name === "items"
                            )?.display
                          }
                          onChange={(e) => {
                            handlePatientSort(
                              "items",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["items"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {realtimeFormulaHeaders?.["items"]}
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "items"
                              )?.field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "items"
                              )?.field_label
                            }
                            onChange={(e) => {
                              handlePatientLabel("items", e.target.value);
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handlePatientSwitchcheck("items")}
                          className="blue-b"
                          onChange={(e) => {
                            handlePatientSwitchOnChange("items", e);
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.patient_data?.find(
                              (e) => e.field_name === "unprocessed_trays"
                            )?.display
                          }
                          onChange={(e) => {
                            handlePatientSort(
                              "unprocessed_trays",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["unprocessed_trays"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {realtimeFormulaHeaders?.["unprocessed_trays"]}
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "unprocessed_trays"
                              )?.field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "unprocessed_trays"
                              )?.field_label
                            }
                            onChange={(e) => {
                              handlePatientLabel(
                                "unprocessed_trays",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handlePatientSwitchcheck(
                            "unprocessed_trays"
                          )}
                          className="blue-b"
                          onChange={(e) => {
                            handlePatientSwitchOnChange("unprocessed_trays", e);
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.patient_data?.find(
                              (e) => e.field_name === "cases_scheduled_today"
                            )?.display
                          }
                          onChange={(e) => {
                            handlePatientSort(
                              "cases_scheduled_today",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["cases_scheduled_today"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {realtimeFormulaHeaders?.["cases_scheduled_today"]}
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "cases_scheduled_today"
                              )?.field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "cases_scheduled_today"
                              )?.field_label
                            }
                            onChange={(e) => {
                              handlePatientLabel(
                                "cases_scheduled_today",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handlePatientSwitchcheck(
                            "cases_scheduled_today"
                          )}
                          className="blue-b"
                          onChange={(e) => {
                            handlePatientSwitchOnChange(
                              "cases_scheduled_today",
                              e
                            );
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.patient_data?.find(
                              (e) => e.field_name === "cases_picked"
                            )?.display
                          }
                          onChange={(e) => {
                            handlePatientSort(
                              "cases_picked",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["cases_picked"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {realtimeFormulaHeaders?.["cases_picked"]}
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "cases_picked"
                              )?.field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "cases_picked"
                              )?.field_label
                            }
                            onChange={(e) => {
                              handlePatientLabel(
                                "cases_picked",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handlePatientSwitchcheck("cases_picked")}
                          className="blue-b"
                          onChange={(e) => {
                            handlePatientSwitchOnChange("cases_picked", e);
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.patient_data?.find(
                              (e) => e.field_name === "actual_minutes"
                            )?.display
                          }
                          onChange={(e) => {
                            handlePatientSort(
                              "actual_minutes",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["actual_minutes"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {realtimeFormulaHeaders?.["actual_minutes"]}
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "actual_minutes"
                              )?.field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "actual_minutes"
                              )?.field_label
                            }
                            onChange={(e) => {
                              handlePatientLabel(
                                "actual_minutes",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handlePatientSwitchcheck("actual_minutes")}
                          className="blue-b"
                          onChange={(e) => {
                            handlePatientSwitchOnChange("actual_minutes", e);
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.patient_data?.find(
                              (e) => e.field_name === "planned_minutes"
                            )?.display
                          }
                          onChange={(e) => {
                            handlePatientSort(
                              "planned_minutes",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["planned_minutes"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {realtimeFormulaHeaders?.["planned_minutes"]}
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "planned_minutes"
                              )?.field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "planned_minutes"
                              )?.field_label
                            }
                            onChange={(e) => {
                              handlePatientLabel(
                                "planned_minutes",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handlePatientSwitchcheck("planned_minutes")}
                          className="blue-b"
                          onChange={(e) => {
                            handlePatientSwitchOnChange("planned_minutes", e);
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.patient_data?.find(
                              (e) => e.field_name === "iol_pain_local_cases"
                            )?.display
                          }
                          onChange={(e) => {
                            handlePatientSort(
                              "iol_pain_local_cases",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["iol_pain_local_cases"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {realtimeFormulaHeaders?.["iol_pain_local_cases"]}
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "iol_pain_local_cases"
                              )?.field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "iol_pain_local_cases"
                              )?.field_label
                            }
                            onChange={(e) => {
                              handlePatientLabel(
                                "iol_pain_local_cases",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handlePatientSwitchcheck(
                            "iol_pain_local_cases"
                          )}
                          className="blue-b"
                          onChange={(e) => {
                            handlePatientSwitchOnChange(
                              "iol_pain_local_cases",
                              e
                            );
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.patient_data?.find(
                              (e) => e.field_name === "mac_cases"
                            )?.display
                          }
                          onChange={(e) => {
                            handlePatientSort(
                              "mac_cases",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["mac_cases"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {realtimeFormulaHeaders?.["mac_cases"]}
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "mac_cases"
                              )?.field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "mac_cases"
                              )?.field_label
                            }
                            onChange={(e) => {
                              handlePatientLabel("mac_cases", e.target.value);
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handlePatientSwitchcheck("mac_cases")}
                          className="blue-b"
                          onChange={(e) => {
                            handlePatientSwitchOnChange("mac_cases", e);
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.patient_data?.find(
                              (e) => e.field_name === "pacu_cases"
                            )?.display
                          }
                          onChange={(e) => {
                            handlePatientSort(
                              "pacu_cases",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["pacu_cases"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {realtimeFormulaHeaders?.["pacu_cases"]}
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "pacu_cases"
                              )?.field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "pacu_cases"
                              )?.field_label
                            }
                            onChange={(e) => {
                              handlePatientLabel("pacu_cases", e.target.value);
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handlePatientSwitchcheck("pacu_cases")}
                          className="blue-b"
                          onChange={(e) => {
                            handlePatientSwitchOnChange("pacu_cases", e);
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.patient_data?.find(
                              (e) => e.field_name === "cath_lab_cases"
                            )?.display
                          }
                          onChange={(e) => {
                            handlePatientSort(
                              "cath_lab_cases",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["cath_lab_cases"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {realtimeFormulaHeaders?.["cath_lab_cases"]}
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "cath_lab_cases"
                              )?.field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "cath_lab_cases"
                              )?.field_label
                            }
                            onChange={(e) => {
                              handlePatientLabel(
                                "cath_lab_cases",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handlePatientSwitchcheck("cath_lab_cases")}
                          className="blue-b"
                          onChange={(e) => {
                            handlePatientSwitchOnChange("cath_lab_cases", e);
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.patient_data?.find(
                              (e) => e.field_name === "in_clinic_visits"
                            )?.display
                          }
                          onChange={(e) => {
                            handlePatientSort(
                              "in_clinic_visits",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["in_clinic_visits"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {realtimeFormulaHeaders?.["in_clinic_visits"]}
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "in_clinic_visits"
                              )?.field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "in_clinic_visits"
                              )?.field_label
                            }
                            onChange={(e) => {
                              handlePatientLabel(
                                "in_clinic_visits",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handlePatientSwitchcheck("in_clinic_visits")}
                          className="blue-b"
                          onChange={(e) => {
                            handlePatientSwitchOnChange("in_clinic_visits", e);
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.patient_data?.find(
                              (e) => e.field_name === "level_1_visits"
                            )?.display
                          }
                          onChange={(e) => {
                            handlePatientSort(
                              "level_1_visits",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["level_1_visits"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {realtimeFormulaHeaders?.["level_1_visits"]}
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "level_1_visits"
                              )?.field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "level_1_visits"
                              )?.field_label
                            }
                            onChange={(e) => {
                              handlePatientLabel(
                                "level_1_visits",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handlePatientSwitchcheck("level_1_visits")}
                          className="blue-b"
                          onChange={(e) => {
                            handlePatientSwitchOnChange("level_1_visits", e);
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.patient_data?.find(
                              (e) => e.field_name === "level_2_visits"
                            )?.display
                          }
                          onChange={(e) => {
                            handlePatientSort(
                              "level_2_visits",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["level_2_visits"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {realtimeFormulaHeaders?.["level_2_visits"]}
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "level_2_visits"
                              )?.field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "level_2_visits"
                              )?.field_label
                            }
                            onChange={(e) => {
                              handlePatientLabel(
                                "level_2_visits",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handlePatientSwitchcheck("level_2_visits")}
                          className="blue-b"
                          onChange={(e) => {
                            handlePatientSwitchOnChange("level_2_visits", e);
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.patient_data?.find(
                              (e) => e.field_name === "level_3_visits"
                            )?.display
                          }
                          onChange={(e) => {
                            handlePatientSort(
                              "level_3_visits",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["level_3_visits"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {realtimeFormulaHeaders?.["level_3_visits"]}
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "level_3_visits"
                              )?.field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "level_3_visits"
                              )?.field_label
                            }
                            onChange={(e) => {
                              handlePatientLabel(
                                "level_3_visits",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handlePatientSwitchcheck("level_3_visits")}
                          className="blue-b"
                          onChange={(e) => {
                            handlePatientSwitchOnChange("level_3_visits", e);
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.patient_data?.find(
                              (e) => e.field_name === "level_4_visits"
                            )?.display
                          }
                          onChange={(e) => {
                            handlePatientSort(
                              "level_4_visits",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["level_4_visits"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {realtimeFormulaHeaders?.["level_4_visits"]}
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "level_4_visits"
                              )?.field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "level_4_visits"
                              )?.field_label
                            }
                            onChange={(e) => {
                              handlePatientLabel(
                                "level_4_visits",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handlePatientSwitchcheck("level_4_visits")}
                          className="blue-b"
                          onChange={(e) => {
                            handlePatientSwitchOnChange("level_4_visits", e);
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.patient_data?.find(
                              (e) => e.field_name === "level_5_visits"
                            )?.display
                          }
                          onChange={(e) => {
                            handlePatientSort(
                              "level_5_visits",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["level_5_visits"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {realtimeFormulaHeaders?.["level_5_visits"]}
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "level_5_visits"
                              )?.field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "level_5_visits"
                              )?.field_label
                            }
                            onChange={(e) => {
                              handlePatientLabel(
                                "level_5_visits",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handlePatientSwitchcheck("level_5_visits")}
                          className="blue-b"
                          onChange={(e) => {
                            handlePatientSwitchOnChange("level_5_visits", e);
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.patient_data?.find(
                              (e) => e.field_name === "of_roomed_er_patients"
                            )?.display
                          }
                          onChange={(e) => {
                            handlePatientSort(
                              "of_roomed_er_patients",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["of_roomed_er_patients"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {realtimeFormulaHeaders?.["of_roomed_er_patients"]}
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "of_roomed_er_patients"
                              )?.field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "of_roomed_er_patients"
                              )?.field_label
                            }
                            onChange={(e) => {
                              handlePatientLabel(
                                "of_roomed_er_patients",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handlePatientSwitchcheck(
                            "of_roomed_er_patients"
                          )}
                          className="blue-b"
                          onChange={(e) => {
                            handlePatientSwitchOnChange(
                              "of_roomed_er_patients",
                              e
                            );
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.patient_data?.find(
                              (e) =>
                                e.field_name === "psych_pts_awaiting_placement"
                            )?.display
                          }
                          onChange={(e) => {
                            handlePatientSort(
                              "psych_pts_awaiting_placement",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["psych_pts_awaiting_placement"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {realtimeFormulaHeaders?.["psych_pts_awaiting_placement"]}
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) =>
                                  e.field_name ===
                                  "psych_pts_awaiting_placement"
                              )?.field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) =>
                                  e.field_name ===
                                  "psych_pts_awaiting_placement"
                              )?.field_label
                            }
                            onChange={(e) => {
                              handlePatientLabel(
                                "psych_pts_awaiting_placement",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handlePatientSwitchcheck(
                            "psych_pts_awaiting_placement"
                          )}
                          className="blue-b"
                          onChange={(e) => {
                            handlePatientSwitchOnChange(
                              "psych_pts_awaiting_placement",
                              e
                            );
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.patient_data?.find(
                              (e) =>
                                e.field_name ===
                                "capped_pts_awaiting_hospitalist_assignment"
                            )?.display
                          }
                          onChange={(e) => {
                            handlePatientSort(
                              "capped_pts_awaiting_hospitalist_assignment",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["capped_pts_awaiting_hospitalist_assignment"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {realtimeFormulaHeaders?.["capped_pts_awaiting_hospitalist_assignment"]}
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) =>
                                  e.field_name ===
                                  "capped_pts_awaiting_hospitalist_assignment"
                              )?.field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) =>
                                  e.field_name ===
                                  "capped_pts_awaiting_hospitalist_assignment"
                              )?.field_label
                            }
                            onChange={(e) => {
                              handlePatientLabel(
                                "capped_pts_awaiting_hospitalist_assignment",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handlePatientSwitchcheck(
                            "capped_pts_awaiting_hospitalist_assignment"
                          )}
                          className="blue-b"
                          onChange={(e) => {
                            handlePatientSwitchOnChange(
                              "capped_pts_awaiting_hospitalist_assignment",
                              e
                            );
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.patient_data?.find(
                              (e) =>
                                e.field_name ===
                                "admits_awaiting_bed_assignment_boarders"
                            )?.display
                          }
                          onChange={(e) => {
                            handlePatientSort(
                              "admits_awaiting_bed_assignment_boarders",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["admits_awaiting_bed_assignment_boarders"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {realtimeFormulaHeaders?.["admits_awaiting_bed_assignment_boarders"]}
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) =>
                                  e.field_name ===
                                  "admits_awaiting_bed_assignment_boarders"
                              )?.field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) =>
                                  e.field_name ===
                                  "admits_awaiting_bed_assignment_boarders"
                              )?.field_label
                            }
                            onChange={(e) => {
                              handlePatientLabel(
                                "admits_awaiting_bed_assignment_boarders",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handlePatientSwitchcheck(
                            "admits_awaiting_bed_assignment_boarders"
                          )}
                          className="blue-b"
                          onChange={(e) => {
                            handlePatientSwitchOnChange(
                              "admits_awaiting_bed_assignment_boarders",
                              e
                            );
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.patient_data?.find(
                              (e) => e.field_name === "pts_in_waiting_room"
                            )?.display
                          }
                          onChange={(e) => {
                            handlePatientSort(
                              "pts_in_waiting_room",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["pts_in_waiting_room"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {realtimeFormulaHeaders?.["pts_in_waiting_room"]}
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "pts_in_waiting_room"
                              )?.field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "pts_in_waiting_room"
                              )?.field_label
                            }
                            onChange={(e) => {
                              handlePatientLabel(
                                "pts_in_waiting_room",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handlePatientSwitchcheck(
                            "pts_in_waiting_room"
                          )}
                          className="blue-b"
                          onChange={(e) => {
                            handlePatientSwitchOnChange(
                              "pts_in_waiting_room",
                              e
                            );
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.patient_data?.find(
                              (e) =>
                                e.field_name ===
                                "calculated_total_registrations"
                            )?.display
                          }
                          onChange={(e) => {
                            handlePatientSort(
                              "calculated_total_registrations",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["calculated_total_registrations"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {realtimeFormulaHeaders?.["calculated_total_registrations"]}
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) =>
                                  e.field_name ===
                                  "calculated_total_registrations"
                              )?.field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) =>
                                  e.field_name ===
                                  "calculated_total_registrations"
                              )?.field_label
                            }
                            onChange={(e) => {
                              handlePatientLabel(
                                "calculated_total_registrations",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handlePatientSwitchcheck(
                            "calculated_total_registrations"
                          )}
                          className="blue-b"
                          onChange={(e) => {
                            handlePatientSwitchOnChange(
                              "calculated_total_registrations",
                              e
                            );
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.patient_data?.find(
                              (e) =>
                                e.field_name === "calculated_units_of_service"
                            )?.display
                          }
                          onChange={(e) => {
                            handlePatientSort(
                              "calculated_units_of_service",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["calculated_units_of_service"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {realtimeFormulaHeaders?.["calculated_units_of_service"]}
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) =>
                                  e.field_name === "calculated_units_of_service"
                              )?.field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) =>
                                  e.field_name === "calculated_units_of_service"
                              )?.field_label
                            }
                            onChange={(e) => {
                              handlePatientLabel(
                                "calculated_units_of_service",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handlePatientSwitchcheck(
                            "calculated_units_of_service"
                          )}
                          className="blue-b"
                          onChange={(e) => {
                            handlePatientSwitchOnChange(
                              "calculated_units_of_service",
                              e
                            );
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.patient_data?.find(
                              (e) => e.field_name === "calculated_procedures_1"
                            )?.display
                          }
                          onChange={(e) => {
                            handlePatientSort(
                              "calculated_procedures_1",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["calculated_procedures_1"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {realtimeFormulaHeaders?.["calculated_procedures_1"]}
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) =>
                                  e.field_name === "calculated_procedures_1"
                              )?.field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) =>
                                  e.field_name === "calculated_procedures_1"
                              )?.field_label
                            }
                            onChange={(e) => {
                              handlePatientLabel(
                                "calculated_procedures_1",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handlePatientSwitchcheck(
                            "calculated_procedures_1"
                          )}
                          className="blue-b"
                          onChange={(e) => {
                            handlePatientSwitchOnChange(
                              "calculated_procedures_1",
                              e
                            );
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.patient_data?.find(
                              (e) => e.field_name === "calculated_procedures_2"
                            )?.display
                          }
                          onChange={(e) => {
                            handlePatientSort(
                              "calculated_procedures_2",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["calculated_procedures_2"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {realtimeFormulaHeaders?.["calculated_procedures_2"]}
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) =>
                                  e.field_name === "calculated_procedures_2"
                              )?.field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) =>
                                  e.field_name === "calculated_procedures_2"
                              )?.field_label
                            }
                            onChange={(e) => {
                              handlePatientLabel(
                                "calculated_procedures_2",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handlePatientSwitchcheck(
                            "calculated_procedures_2"
                          )}
                          className="blue-b"
                          onChange={(e) => {
                            handlePatientSwitchOnChange(
                              "calculated_procedures_2",
                              e
                            );
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.patient_data?.find(
                              (e) => e.field_name === "calculated_items"
                            )?.display
                          }
                          onChange={(e) => {
                            handlePatientSort(
                              "calculated_items",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["calculated_items"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {realtimeFormulaHeaders?.["calculated_items"]}
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "calculated_items"
                              )?.field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "calculated_items"
                              )?.field_label
                            }
                            onChange={(e) => {
                              handlePatientLabel(
                                "calculated_items",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handlePatientSwitchcheck("calculated_items")}
                          className="blue-b"
                          onChange={(e) => {
                            handlePatientSwitchOnChange("calculated_items", e);
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.patient_data?.find(
                              (e) => e.field_name === "calculated_minutes"
                            )?.display
                          }
                          onChange={(e) => {
                            handlePatientSort(
                              "calculated_minutes",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["calculated_minutes"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {realtimeFormulaHeaders?.["calculated_minutes"]}
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "calculated_minutes"
                              )?.field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "calculated_minutes"
                              )?.field_label
                            }
                            onChange={(e) => {
                              handlePatientLabel(
                                "calculated_minutes",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handlePatientSwitchcheck(
                            "calculated_minutes"
                          )}
                          className="blue-b"
                          onChange={(e) => {
                            handlePatientSwitchOnChange(
                              "calculated_minutes",
                              e
                            );
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.patient_data?.find(
                              (e) => e.field_name === "seven_ave_reg_patient"
                            )?.display
                          }
                          onChange={(e) => {
                            handlePatientSort(
                              "seven_ave_reg_patient",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["seven_ave_reg_patient"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {realtimeFormulaHeaders?.["seven_ave_reg_patient"]}
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "seven_ave_reg_patient"
                              )?.field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "seven_ave_reg_patient"
                              )?.field_label
                            }
                            onChange={(e) => {
                              handlePatientLabel(
                                "seven_ave_reg_patient",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handlePatientSwitchcheck(
                            "seven_ave_reg_patient"
                          )}
                          className="blue-b"
                          onChange={(e) => {
                            handlePatientSwitchOnChange(
                              "seven_ave_reg_patient",
                              e
                            );
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.patient_data?.find(
                              (e) => e.field_name === "ed_reg_patient"
                            )?.display
                          }
                          onChange={(e) => {
                            handlePatientSort(
                              "ed_reg_patient",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["ed_reg_patient"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {realtimeFormulaHeaders?.["ed_reg_patient"]}
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "ed_reg_patient"
                              )?.field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "ed_reg_patient"
                              )?.field_label
                            }
                            onChange={(e) => {
                              handlePatientLabel(
                                "ed_reg_patient",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handlePatientSwitchcheck("ed_reg_patient")}
                          className="blue-b"
                          onChange={(e) => {
                            handlePatientSwitchOnChange("ed_reg_patient", e);
                          }}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.patient_data?.find(
                              (e) => e.field_name === "iol_mac_pacu_minutes"
                            )?.display
                          }
                          onChange={(e) => {
                            handlePatientSort(
                              "iol_mac_pacu_minutes",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["iol_mac_pacu_minutes"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {realtimeFormulaHeaders?.["iol_mac_pacu_minutes"]}
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "iol_mac_pacu_minutes"
                              )?.field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "iol_mac_pacu_minutes"
                              )?.field_label
                            }
                            onChange={(e) => {
                              handlePatientLabel(
                                "iol_mac_pacu_minutes",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handlePatientSwitchcheck(
                            "iol_mac_pacu_minutes"
                          )}
                          className="blue-b"
                          onChange={(e) => {
                            handlePatientSwitchOnChange(
                              "iol_mac_pacu_minutes",
                              e
                            );
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.patient_data?.find(
                              (e) => e.field_name === "in_chair_minutes"
                            )?.display
                          }
                          onChange={(e) => {
                            handlePatientSort(
                              "in_chair_minutes",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["in_chair_minutes"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {realtimeFormulaHeaders?.["in_chair_minutes"]}
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "in_chair_minutes"
                              )?.field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "in_chair_minutes"
                              )?.field_label
                            }
                            onChange={(e) => {
                              handlePatientLabel(
                                "in_chair_minutes",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handlePatientSwitchcheck("in_chair_minutes")}
                          className="blue-b"
                          onChange={(e) => {
                            handlePatientSwitchOnChange("in_chair_minutes", e);
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.patient_data?.find(
                              (e) => e.field_name === "pain_management"
                            )?.display
                          }
                          onChange={(e) => {
                            handlePatientSort(
                              "pain_management",
                              e.target.value !== "" ? e.target.value : "0"
                            );
                          }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["pain_management"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {realtimeFormulaHeaders?.["pain_management"]}
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "pain_management"
                              )?.field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "pain_management"
                              )?.field_label
                            }
                            onChange={(e) => {
                              handlePatientLabel(
                                "pain_management",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handlePatientSwitchcheck("pain_management")}
                          className="blue-b"
                          onChange={(e) => {
                            handlePatientSwitchOnChange("pain_management", e);
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="sm-transparent-input">
                        <input
                          type="text"
                          class="form-control"
                          value={
                            formControl.patient_data?.find(
                              (e) => e.field_name === "uos_24"
                            )?.display
                          }
                          readOnly
                          // onChange={(e) => {
                          //   handlePatientSort(
                          //     "uos_24",
                          //     e.target
                          //       .value !== ""
                          //       ? e.target
                          //           .value
                          //       : "0"
                          //   );
                          // }}
                        />
                      </td>
                      {realtimeFormulaHeaders?.["uos_24"] ? (
                        <td
                          style={{
                            width: "30px",
                            height: "30px",
                            textAlign: "left",
                            lineHeight: "35px",
                          }}
                        >
                          <>
                            {
                              <OverlayTrigger
                                key={"top"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-${"top"}`}>
                                    <strong>{"Formula:"}</strong>
                                    &nbsp;
                                    {realtimeFormulaHeaders?.["uos_24"]}
                                  </Tooltip>
                                }
                              >
                                <img
                                  src={calcIcon}
                                  alt=""
                                  style={{
                                    width: "36px",
                                    height: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </OverlayTrigger>
                            }
                          </>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "uos_24"
                              )?.field_name
                            }
                            readOnly
                          />
                        </div>
                      </td>

                      <td className="pe-5">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control mx-w-340"
                            value={
                              formControl.patient_data?.find(
                                (e) => e.field_name === "uos_24"
                              )?.field_label
                            }
                            onChange={(e) => {
                              handlePatientLabel("uos_24", e.target.value);
                            }}
                          />
                        </div>
                      </td>
                      <td className="vertical-aling-middle">
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={handlePatientSwitchcheck("uos_24")}
                          className="blue-b"
                          onChange={(e) => {
                            handlePatientSwitchOnChange("uos_24", e);
                          }}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PatientDataAccordian;
