import React, { useEffect, useState } from "react";
import "./index.css";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ErrorMessage, Formik, Form as FormikForm } from "formik";
import { toast } from "react-toastify";
import axios from "../../axios";
import { API_CALL_URL_PRIVATE, DOMAIN_URL } from "../../constants/compensate";
import Texterror from "../Texterror";
import { PaycodeFormValidation } from "../../utils/validator";
import { useSelector } from "react-redux";

function EditPaycodeForm() {
  const { ID } = useParams();
  const navigate = useNavigate();
  const customer_code = useSelector((state) => state.compensate.customerCode);
  const [isLoading, setIsLoading] = useState(false);
  const [isInitialData, setISInitialData] = useState(false);
  const [TimezoneOptions, setTimezoneOptions] = useState([]);
  const [initialValues, setInitialValues] = useState({
    interface_code: "",
    description: "",
    client_mapping: "",
    ps_mapping: "",
  });

  useEffect(() => {
    const getInitalData = async () => {
      const resp = await axios.post(
        `${API_CALL_URL_PRIVATE}/paycode/detail`,
        { _id: ID, customer_code: customer_code }
      );
      console.log(resp);
      const Detail = resp?.data?.data;
      setInitialValues((prev) => {
        return {
          ...prev,
          customer_code: Detail?.customer_code,
          interface_code: Detail?.interface_code,
          description: Detail?.description,
          client_mapping: Detail?.client_mapping,
          ps_mapping: Detail?.ps_mapping,
        };
      });
      setISInitialData(true);
    };
    getInitalData();
  }, [ID]);

  const onSubmit = (values, onSubmittingProps) => {
    console.log("form Values", values);
    formSubmit(values);
    // setTimeout(() => {
    //   onSubmittingProps.setSubmitting(false);
    // }, [2000]);
  };
  const formSubmit = async (values) => {
    var formData = {
      _id: ID,
      interface_code: values.interface_code,
      customer_code: parseInt(values.customer_code),
      description: values.description,
      client_mapping: values.client_mapping,
      ps_mapping: values.ps_mapping,
    };
    try {
      setIsLoading(true);
      const resp = await axios.post(
        `${API_CALL_URL_PRIVATE}/paycode/create`,
        formData
      );
      console.log(resp);
      toast.success(resp?.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
      });
      navigate("/admin/paycode");
    } catch (error) {
      toast.error(error.response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
      });

      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <>
      {isInitialData ? (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validate={PaycodeFormValidation}
          enableReinitialize
          validateOnChange
        >
          {(formik) => (
            <div className="main-panel">
              {console.log(formik)}
              <div className="content-wrapper">
                <div className="page-header">
                  <h3 className="page-title">
                    <a href="#" className="badge badge-dark"></a>
                  </h3>
                  <div className></div>
                </div>
                <div className="row">
                  <div class="col-12 grid-margin stretch-card">
                    <div class="card">
                      <div class="card-body">
                        <div className="d-flex justify-content-between">
                          <h4 class="card-title">Edit Paycode</h4>
                          <Link
                            to={`/admin/paycode/`}
                            className="f-14 white-text-dark-mode"
                          >
                            <FontAwesomeIcon icon="fa-solid fa-arrow-left " />{" "}
                            Back
                          </Link>
                        </div>

                        <FormikForm className="forms-sample">
                          <div class="row">
                            <Form.Group
                              className="col-md-6 mb-3"
                              controlId="exampleForm.c_code"
                            >
                              <Form.Label>Paycode</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Paycode"
                                name="interface_code"
                                {...formik.getFieldProps("interface_code")}
                              />
                              <ErrorMessage
                                name="interface_code"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-6 mb-3"
                              controlId="exampleForm.c_name"
                            >
                              <Form.Label>Description</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Description"
                                name="description"
                                {...formik.getFieldProps("description")}
                              />
                              <ErrorMessage
                                name="description"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-6 mb-3"
                              controlId="exampleForm.c_name"
                            >
                              <Form.Label>Client Mapping</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Client Mapping"
                                name="client_mapping"
                                {...formik.getFieldProps("client_mapping")}
                              />
                              <ErrorMessage
                                name="client_mapping"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-6 mb-3"
                              controlId="exampleForm.c_name"
                            >
                              <Form.Label>PS Mapping</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="PS Mapping"
                                name="ps_mapping"
                                {...formik.getFieldProps("ps_mapping")}
                              />
                              <ErrorMessage
                                name="ps_mapping"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group className="col-md-12 mb-3 d-flex justify-content-end">
                              <Button
                                className="ms-3"
                                variant="success"
                                type="submit"
                                disabled={isLoading}
                              >
                                {isLoading ? "Saving..." : "Save"}
                              </Button>
                            </Form.Group>
                          </div>
                        </FormikForm>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Formik>
      ) : (
        <div className="d-flex align-items-center justify-content-center w-100vw h-100vh">
          <span class="loader" style={{ width: "50px", height: "50px" }}></span>
        </div>
      )}
    </>
  );
}

export default EditPaycodeForm;
