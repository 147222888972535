import React, { useRef, useState } from "react";
import "./index.css";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from "react-router-dom";
import { ErrorMessage, Formik, Form as FormikForm } from "formik";
import { toast } from "react-toastify";
import axios from "../../axios";
import { API_CALL_URL_PRIVATE } from "../../constants/compensate";
import { ApplicationListValidation } from "../../utils/validator";
import Texterror from "../Texterror";

function AddApplicationForm() {
  const fileInputRef = useRef(null);
  const fileLogoInputRef = useRef(null);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [logoListLoader, setLogoListLoader] = useState(false);
  const [faviconListLoader, setFaviconListLoader] = useState(false);
  const [initialValues, setInitialValues] = useState({
    application_id: "",
    title: "",
    active: "1",
    homepage_widget: "0",
    sidebar: "0",
    header: "0",
    logo: "",
    favicon: "",
    sort: "",
  });
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedIcon, setSelectedIcon] = useState(null);
  const onSubmit = (values, onSubmittingProps) => {
    console.log("form Values", values);
    formSubmit(values);
    // setTimeout(() => {
    //   onSubmittingProps.setSubmitting(false);
    // }, [2000]);
  };

  const handleAttachmentChange = async (e, formik) => {
    formik.setFieldTouched("logo", true, true);

    const file = e.target.files[0];
    console.log("file", file);

    if (file) {
      const formData = new FormData();
      formData.append("logo", file);

      try {
        const response = await axios.post(
          `${API_CALL_URL_PRIVATE}/application/applicationAddImage`,
          formData
        );

        if (response.status === 200) {
          console.log("Image uploaded successfully. Path:", response);
          setSelectedImage(response.data.data.displayPath);
          formik.setFieldValue("logo", response.data.data.logoPath);
          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
          });
          return false;
        } else {
          toast.error("Failed to Upload Image..! Try Again", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
          });
          return false;
        }
      } catch (error) {
        const errorMessage =
          error.response?.data?.message || error.message || "An error occurred";
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
        });
        if (fileLogoInputRef.current) {
          fileLogoInputRef.current.value = "";
        }
        return false;
      }
    }
  };
  const handleAttachmentIconChange = async (e, formik) => {
    formik.setFieldTouched("favicon", true, true);

    const file = e.target.files[0];
    console.log("file", file);

    if (file) {
      const formData = new FormData();
      formData.append("logo", file);

      try {
        const response = await axios.post(
          `${API_CALL_URL_PRIVATE}/application/applicationAddImage`,
          formData
        );

        if (response.status === 200) {
          console.log("Image uploaded successfully. Path:", response);
          setSelectedIcon(response.data.data.displayPath);
          formik.setFieldValue("favicon", response.data.data.logoPath);
          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
          });
          return false;
        } else {
          toast.error("Failed to Upload Image..! Try Again", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
          });
          return false;
        }
      } catch (error) {
        console.log(error);
        const errorMessage =
          error.response?.data?.message || error.message || "An error occurred";
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
        });
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
        return false;
      }
    }
  };

  const formSubmit = async (values) => {
    // var formData = new FormData();
    // formData.append("application_id", values.application_id);
    // formData.append("title", values.title);
    // formData.append("active", parseInt(values.active));
    // formData.append("homepage_widget", parseInt(values.homepage_widget));
    // formData.append("sidebar", parseInt(values.sidebar));
    // formData.append("sort", parseInt(values.sort));
    // formData.append("header", parseInt(values.header));
    // formData.append("logo", values.logo);

    const obj = {
      application_id: parseInt(values.application_id),
      title: values.title,
      active: parseInt(values.active),
      homepage_widget: parseInt(values.homepage_widget),
      sidebar: parseInt(values.sidebar),
      sort: parseInt(values.sort),
      header: parseInt(values.header),
      logo: values.logo,
      favicon: values.favicon,
    };
    try {
      setIsLoading(true);
      const resp = await axios.post(
        `${API_CALL_URL_PRIVATE}/application/applicationAddUpdate`,
        obj
      );
      console.log(resp);
      toast.success(resp?.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
      });
      navigate("/admin/application");
    } catch (error) {
      toast.error(error.response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
      });

      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validate={ApplicationListValidation}
        enableReinitialize
        validateOnChange
      >
        {(formik) => (
          <div className="main-panel">
            {console.log(formik)}
            <div className="content-wrapper">
              <div className="page-header">
                <h3 className="page-title">
                  <a href="#" className="badge badge-dark"></a>
                </h3>
                <div className></div>
              </div>
              <div className="row">
                <div class="col-12 grid-margin stretch-card">
                  <div class="card">
                    <FormikForm className="forms-sample">
                      <div class="card-body">
                        <div className="row align-items-center">
                          <div className="col-3">
                            <Link
                              to={`/admin/application`}
                              className="f-14 white-text-dark-mode"
                            >
                              <FontAwesomeIcon icon="fa-solid fa-arrow-left " />{" "}
                              Back
                            </Link>
                          </div>
                          <div className="col-6">
                            <h4 class="card-title text-center">
                              Add Application
                            </h4>
                          </div>
                          <div className="col-3 text-end">
                            <Button
                              className="ms-3"
                              variant="success"
                              type="submit"
                              disabled={isLoading}
                            >
                              {isLoading ? "Saving..." : "Save"}
                            </Button>
                          </div>
                        </div>

                        <div class="row">
                          <Form.Group
                            className="col-md-4 mb-3"
                            controlId="exampleForm.c_code"
                          >
                            <Form.Label>Application Id</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder=""
                              name="application_id"
                              {...formik.getFieldProps("application_id")}
                            />
                            <ErrorMessage
                              name="application_id"
                              component={Texterror}
                            />
                          </Form.Group>
                          <Form.Group
                            className="col-md-4 mb-3"
                            controlId="exampleForm.c_name"
                          >
                            <Form.Label>Title</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder=""
                              name="title"
                              {...formik.getFieldProps("title")}
                            />
                            <ErrorMessage name="title" component={Texterror} />
                          </Form.Group>
                          <Form.Group
                            className="col-md-4 mb-3"
                            controlId="exampleForm.c_email"
                          >
                            <Form.Label>Active</Form.Label>
                            <Form.Select
                              aria-label="Default select example"
                              className="white"
                              name="active"
                              value={formik.values.active}
                              onChange={(e) => {
                                formik.setFieldTouched("active", true, true);
                                if (e.target.value !== "") {
                                  formik.setFieldValue(
                                    "active",
                                    e.target.value
                                  );
                                }
                                // formik.setFieldValue(
                                //   "customer_status",
                                //   e.target.value
                                // );
                                // console.log(e.target.value);
                              }}
                              onBlur={() => {
                                formik.setFieldTouched("active", true, true);
                              }}
                              // onChange={formik.handleChange}
                            >
                              <option value="1">Active</option>
                              <option value="0">Inactive</option>
                            </Form.Select>
                            <ErrorMessage name="active" component={Texterror} />
                          </Form.Group>
                          <Form.Group
                            className="col-md-4 mb-3"
                            controlId="exampleForm.c_email"
                          >
                            <Form.Label>Homepage Widget</Form.Label>
                            <Form.Select
                              aria-label="Default select example"
                              className="white"
                              name="homepage_widget"
                              value={formik.values.homepage_widget}
                              onChange={(e) => {
                                formik.setFieldTouched(
                                  "homepage_widget",
                                  true,
                                  true
                                );
                                if (e.target.value !== "") {
                                  formik.setFieldValue(
                                    "homepage_widget",
                                    e.target.value
                                  );
                                }
                                // formik.setFieldValue(
                                //   "customer_status",
                                //   e.target.value
                                // );
                                // console.log(e.target.value);
                              }}
                              onBlur={() => {
                                formik.setFieldTouched(
                                  "homepage_widget",
                                  true,
                                  true
                                );
                              }}
                              // onChange={formik.handleChange}
                            >
                              <option value="1">1</option>
                              <option value="0">0</option>
                            </Form.Select>
                            <ErrorMessage
                              name="homepage_widget"
                              component={Texterror}
                            />
                          </Form.Group>
                          <Form.Group
                            className="col-md-4 mb-3"
                            controlId="exampleForm.c_email"
                          >
                            <Form.Label>Sidebar</Form.Label>
                            <Form.Select
                              aria-label="Default select example"
                              className="white"
                              name="sidebar"
                              value={formik.values.sidebar}
                              onChange={(e) => {
                                formik.setFieldTouched("sidebar", true, true);
                                if (e.target.value !== "") {
                                  formik.setFieldValue(
                                    "sidebar",
                                    e.target.value
                                  );
                                }
                                // formik.setFieldValue(
                                //   "customer_status",
                                //   e.target.value
                                // );
                                // console.log(e.target.value);
                              }}
                              onBlur={() => {
                                formik.setFieldTouched("sidebar", true, true);
                              }}
                              // onChange={formik.handleChange}
                            >
                              <option value="1">1</option>
                              <option value="0">0</option>
                            </Form.Select>
                            <ErrorMessage
                              name="sidebar"
                              component={Texterror}
                            />
                          </Form.Group>
                          <Form.Group
                            className="col-md-4 mb-3"
                            controlId="exampleForm.c_email"
                          >
                            <Form.Label>Header</Form.Label>
                            <Form.Select
                              aria-label="Default select example"
                              className="white"
                              name="header"
                              value={formik.values.header}
                              onChange={(e) => {
                                formik.setFieldTouched("header", true, true);
                                if (e.target.value !== "") {
                                  formik.setFieldValue(
                                    "header",
                                    e.target.value
                                  );
                                }
                                // formik.setFieldValue(
                                //   "customer_status",
                                //   e.target.value
                                // );
                                // console.log(e.target.value);
                              }}
                              onBlur={() => {
                                formik.setFieldTouched("header", true, true);
                              }}
                              // onChange={formik.handleChange}
                            >
                              <option value="1">1</option>
                              <option value="0">0</option>
                            </Form.Select>
                            <ErrorMessage name="header" component={Texterror} />
                          </Form.Group>
                          <Form.Group
                            className="col-md-4 mb-3"
                            controlId="exampleForm.c_number"
                          >
                            <Form.Label>Sort</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder=""
                              name="sort"
                              {...formik.getFieldProps("sort")}
                            />
                            <ErrorMessage name="sort" component={Texterror} />
                          </Form.Group>

                          <Form.Group
                            controlId="formFile"
                            className="mb-0 col-md-4 "
                          >
                            <Form.Label className="w-100">
                              <div className="d-flex align-items-center  w-100">
                                Logo
                                {logoListLoader && (
                                  <div className="d-flex ms-3">
                                    <span
                                      className="loader"
                                      style={{ width: "15px", height: "15px" }}
                                    ></span>
                                  </div>
                                )}
                              </div>
                            </Form.Label>
                            <Form.Control
                              type="file"
                              name="logo"
                              ref={fileLogoInputRef}
                              onChange={async (e) => {
                                setLogoListLoader(true);
                                await handleAttachmentChange(e, formik);
                                setLogoListLoader(false);
                              }}
                              onBlur={() => {
                                formik.setFieldTouched("logo", true, true);
                              }}
                            />
                            <ErrorMessage name="logo" component={Texterror} />
                            {selectedImage && (
                              <div
                                style={{
                                  height: "100px",
                                  width: "100px",
                                  marginTop: "10px",
                                }}
                              >
                                <img
                                  src={selectedImage}
                                  alt="Uploaded preview"
                                  style={{
                                    maxWidth: "100%",
                                    height: "100%",
                                    objectFit: "contain",
                                  }}
                                />
                              </div>
                            )}
                          </Form.Group>
                          <Form.Group
                            controlId="formFile"
                            className="mb-0 col-md-4 "
                          >
                            <Form.Label className="w-100">
                              <div className="d-flex align-items-center w-100">
                                Favicon
                                {faviconListLoader && (
                                  <div className="d-flex ms-3">
                                    <span
                                      className="loader"
                                      style={{ width: "15px", height: "15px" }}
                                    ></span>
                                  </div>
                                )}
                              </div>
                            </Form.Label>
                            <Form.Control
                              type="file"
                              name="favicon"
                              ref={fileInputRef}
                              onChange={async (e) => {
                                setFaviconListLoader(true);
                                await handleAttachmentIconChange(e, formik);
                                setFaviconListLoader(false);
                              }}
                              onBlur={() => {
                                formik.setFieldTouched("favicon", true, true);
                              }}
                              style={{ color: "black" }}
                            />
                            <ErrorMessage
                              name="favicon"
                              component={Texterror}
                            />
                            {selectedIcon && (
                              <div
                                style={{
                                  height: "100px",
                                  width: "100px",
                                  marginTop: "10px",
                                }}
                              >
                                <img
                                  src={selectedIcon}
                                  alt="Uploaded preview"
                                  style={{
                                    maxWidth: "100%",
                                    height: "100%",
                                    objectFit: "contain",
                                  }}
                                />
                              </div>
                            )}
                          </Form.Group>

                          {/* <Form.Group
                            className="col-md-12 mb-3"
                            controlId="exampleForm.c_email"
                          >
                            <div class="checkbox-item">
                              <input
                                type="checkbox"
                                class="custom-checkbox"
                                onChange={(p) => {
                                  if (p.target.checked) {
                                    formik.setFieldValue(
                                      "show_ps_logo",
                                      parseInt("1")
                                    );
                                  } else {
                                    formik.setFieldValue(
                                      "show_ps_logo",
                                      parseInt("0")
                                    );
                                  }
                                }}
                              />
                              Show PS Logo
                            </div>
                            <ErrorMessage
                              name="customer_address"
                              component={Texterror}
                            />
                          </Form.Group> */}

                          <Form.Group className="col-md-12 mb-3 d-flex justify-content-end">
                            {/* <Button
                              variant="primary"
                              type="button"
                              onClick={() => {
                                formik.resetForm();
                                setSelectedImage(null);
                                // const fileInput =
                                //   document.getElementById("logo");
                                // if (fileInput) {
                                //   fileInput.value = "";
                                // }
                              }}
                            >
                              Reset
                            </Button> */}
                            <Button
                              className="ms-3"
                              variant="success"
                              type="submit"
                              disabled={isLoading}
                            >
                              {isLoading ? "Saving..." : "Save"}
                            </Button>
                          </Form.Group>
                        </div>
                      </div>
                    </FormikForm>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Formik>
    </>
  );
}

export default AddApplicationForm;
