import React, { useEffect, useState } from "react";
import "./index.css";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ErrorMessage, Formik, Form as FormikForm } from "formik";
import { toast } from "react-toastify";
import axios from "../../axios";
import { API_CALL_URL_PRIVATE, DOMAIN_URL } from "../../constants/compensate";
import Texterror from "../Texterror";
import {
  payPeriodValidation,
  portalUserValidation,
} from "../../utils/validator";
import ReactDatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import CROSS from "../../../src/assets/img/close.png";
import { setLoadingPage } from "../../store/actions";

function AddPayPeriodForm() {
  const dispatch = useDispatch();
  const { employeeID } = useParams();
  const customer_code = useSelector((state) => state.compensate.customerCode);
  const loading_page = useSelector((state) => state.compensate.LoadingPage);
  console.log(customer_code, "customercode");
  const navigate = useNavigate();
  const [FormInitialData, setFormInitialData] = useState();
  const [selectedImage, setSelectedImage] = useState(null);
  const [initialImageUrl, setInitialImageUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [costCenterMaster, setCostCenterMaster] = useState(null);
  const [costCenterData, setCostCenterData] = useState(null);
  const [costCenterFilteredData, setCostCenterFilteredData] = useState(null);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [facilityMaster, setFacilityMaster] = useState(null);
  const [applications, setApplications] = useState(null);
  const [selectAllApplication, setSelectAllApplication] = useState(false);
  const [DeselectAllApplication, setDeselectAllApplication] = useState(false);
  const [selectAllCostCenter, setSelectAllCostCenter] = useState(false);
  const [DeselectAllCostCenter, setDeselectAllCostCenter] = useState(false);
  const [beginDate, setBeginDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isInitialData, setISInitialData] = useState(false);
  const [dropUserType, setDropUserType] = useState(null);
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 4 }, (_, index) => currentYear + index);
  console.log("date", beginDate, endDate);

  const [initialValues, setInitialValues] = useState({
    name: "",
    pay_period_hours: "",
    pay_period_sequence: "",
    begin_date: "",
    end_date: "",
    fy_sequence: "",
    fiscal_year: currentYear.toString(),
    calendar_year: currentYear.toString(),
  });

  // const getFormatDate = (val) => {
  //   const inputDate = new Date(`${val}`);
  //   const year = inputDate.getFullYear();
  //   const month = String(inputDate.getMonth() + 1).padStart(2, "0");
  //   const day = String(inputDate.getDate()).padStart(2, "0");
  //   return val ? `${day}/${month}/${year}` : "";
  // };

  const getFormatDate = (val) => {
    if (!val) return ""; // Handle empty or undefined input

    let inputDate;

    if (typeof val === "string") {
      // If the input is a string, attempt to parse it
      const dateComponents = val.split("/");
      if (dateComponents.length !== 3) return ""; // Handle invalid date format

      const [month, day, year] = dateComponents.map((component) =>
        parseInt(component, 10)
      );

      // Check if the parsed components are valid numbers
      if (isNaN(month) || isNaN(day) || isNaN(year)) {
        return ""; // Handle invalid numeric values
      }

      inputDate = new Date(year, month - 1, day); // Note: months are zero-based in JavaScript dates
    } else if (val instanceof Date) {
      // If the input is a Date object, use it directly
      inputDate = val;
    } else {
      return ""; // Handle unsupported input type
    }

    const formattedYear = inputDate.getFullYear();
    const formattedMonth = String(inputDate.getMonth() + 1).padStart(2, "0");
    const formattedDay = String(inputDate.getDate()).padStart(2, "0");

    return `${formattedDay}-${formattedMonth}-${formattedYear}`;
  };

  useEffect(() => {
    const getSequenceData = async () => {
      const resp = await axios.post(
        `${API_CALL_URL_PRIVATE}/payperiod/nextData`,
        {
          customer_code: parseInt(customer_code),
        }
      );
      console.log(resp);
      const Detail = resp?.data?.data?.next_data;
      setInitialValues((prev) => {
        return {
          ...prev,
          name: Detail?.name,
          fy_sequence: Detail?.fy_sequence,
          pay_period_sequence: Detail?.pay_period_sequence,
          begin_date: Detail?.start_date,
          end_date: Detail?.end_date,
        };
      });
      setBeginDate(new Date(Detail?.start_date));
      setEndDate(new Date(Detail?.end_date));
      setISInitialData(true);
    };
    getSequenceData();
  }, []);

  // const performSearch = (data, query) => {
  //   if (query.trim() === "") {
  //     return data || [];
  //   } else {
  //     return (data || []).filter((e) =>
  //       e.cost_center_name.toLowerCase().includes(query.toLowerCase())
  //     );
  //   }
  // };

  const performSearch = (data, query) => {
    if (query.trim() === "") {
      return data || [];
    } else {
      return data.filter(
        (e) =>
          e.cost_center_name.toLowerCase().includes(query.toLowerCase()) ||
          e.cost_center_number.toString().includes(query.toLowerCase())
      );
    }
  };

  const onSubmit = (values, onSubmittingProps) => {
    console.log("form Values", values);
    formSubmit(values);
    // setTimeout(() => {
    //   onSubmittingProps.setSubmitting(false);
    // }, [2000]);
  };
  const formSubmit = async (values) => {
    // var formData = new FormData();
    // formData.append("_id", values.customer_id);
    // formData.append("customer_code", values.customer_code);
    // formData.append("customer_name", values.customer_name);
    // formData.append("customer_number", values.customer_number);
    // formData.append("customer_email", values.customer_email);
    // formData.append("address", values.customer_address);
    // formData.append("status", values.customer_status);
    // formData.append("logo", values.logo);

    const obj = {
      customer_code: customer_code,
      name: values.name,
      pay_period_hours: values.pay_period_hours,
      pay_period_sequence: values.pay_period_sequence,
      begin_date: getFormatDate(new Date(values.begin_date)),
      end_date: getFormatDate(new Date(values.end_date)),
      fy_sequence: values.fy_sequence,
      fiscal_year: values.fiscal_year,
      calendar_year: values.calendar_year,
    };
    try {
      setIsLoading(true);
      const resp = await axios.post(
        `${API_CALL_URL_PRIVATE}/payperiod/create`,
        obj
      );
      console.log(resp);
      toast.success(resp?.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
      });
      // navigate("/admin/customer");
    } catch (error) {
      toast.error(error?.response?.data?.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
      });

      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  function formatNumberWithCommas(number) {
    return number.toLocaleString("en-US"); // Force using US locale
  }

  return (
    <>
      {isInitialData && !loading_page ? (
        <>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validate={payPeriodValidation}
            enableReinitialize
            validateOnChange
          >
            {(formik) => (
              <div className="main-panel">
                {console.log(formik)}
                <div className="content-wrapper">
                  <div className="page-header">
                    <h3 className="page-title">
                      <a href="#" className="badge badge-dark"></a>
                    </h3>
                    <div className></div>
                  </div>
                  <div className="row">
                    <div class="col-12 grid-margin stretch-card">
                      <div class="card">
                        <FormikForm className="forms-sample">
                          <div class="card-body">
                            <div className="row align-items-center">
                              <div className="col-3">
                                <Link
                                  to={`/admin/pay-period`}
                                  className="f-14 white-text-dark-mode"
                                >
                                  <FontAwesomeIcon icon="fa-solid fa-arrow-left " />{" "}
                                  Back
                                </Link>
                              </div>
                              <div className="col-6">
                                <h4 class="card-title text-center">
                                  Add Pay Period
                                </h4>
                              </div>
                              <div className="col-3 text-end">
                                <Button
                                  className="ms-3"
                                  variant="success"
                                  type="submit"
                                  disabled={isLoading}
                                >
                                  {isLoading ? "Saving..." : "Save"}
                                </Button>
                              </div>
                            </div>

                            <div class="row">
                              <Form.Group
                                className="col-md-3 mb-3"
                                controlId="exampleForm.c_code"
                              >
                                <Form.Label>Name</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder=""
                                  name="name"
                                  value={formik.values.name}
                                  {...formik.getFieldProps("name")}
                                />
                                <ErrorMessage
                                  name="name"
                                  component={Texterror}
                                />
                              </Form.Group>
                              <Form.Group
                                className="col-md-3 mb-3"
                                controlId="exampleForm.c_name"
                              >
                                <Form.Label>Pay Period Hours</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder=""
                                  name="pay_period_hours"
                                  {...formik.getFieldProps("pay_period_hours")}
                                />
                                <ErrorMessage
                                  name="pay_period_hours"
                                  component={Texterror}
                                />
                              </Form.Group>
                              <Form.Group
                                className="col-md-3 mb-3"
                                controlId="exampleForm.c_number"
                              >
                                <Form.Label>Calendar Sequence</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder=""
                                  name="pay_period_sequence"
                                  value={formik.values.pay_period_sequence}
                                  {...formik.getFieldProps("pay_period_sequence")}
                                />
                                <ErrorMessage
                                  name="pay_period_sequence"
                                  component={Texterror}
                                />
                              </Form.Group>
                              <Form.Group
                                className="col-md-3 mb-3"
                                controlId="exampleForm.c_number"
                              >
                                <Form.Label>FY Sequence</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder=""
                                  name="fy_sequence"
                                  value={formik.values.fy_sequence}
                                  {...formik.getFieldProps("fy_sequence")}
                                />
                                <ErrorMessage
                                  name="fy_sequence"
                                  component={Texterror}
                                />
                              </Form.Group>
                              <Form.Group
                                className="col-md-3 mb-3"
                                controlId="exampleForm.c_email"
                              >
                                <Form.Label>Begin Date</Form.Label>
                                <div>
                                  <ReactDatePicker
                                    closeOnScroll={(e) => e.target === document}
                                    selected={beginDate}
                                    dateFormat="MM/dd/yyyy"
                                    onChange={(date) => {
                                      formik.setFieldTouched(
                                        "begin_date",
                                        true,
                                        true
                                      );
                                      formik.setFieldValue("begin_date", date);
                                      setBeginDate(date);
                                    }}
                                    onBlur={() => {
                                      formik.setFieldTouched(
                                        "begin_date",
                                        true,
                                        true
                                      );
                                    }}
                                  />
                                </div>
                                <ErrorMessage
                                  name="begin_date"
                                  component={Texterror}
                                />
                              </Form.Group>
                              <Form.Group
                                className="col-md-3 mb-3"
                                controlId="exampleForm.c_email"
                              >
                                <Form.Label>End Date</Form.Label>
                                <div>
                                  <ReactDatePicker
                                    closeOnScroll={(e) => e.target === document}
                                    selected={endDate}
                                    dateFormat="MM/dd/yyyy"
                                    // minDate={new Date()}
                                    onChange={(date) => {
                                      formik.setFieldTouched(
                                        "end_date",
                                        true,
                                        true
                                      );
                                      formik.setFieldValue("end_date", date);
                                      setEndDate(date);
                                    }}
                                    onBlur={() => {
                                      formik.setFieldTouched(
                                        "end_date",
                                        true,
                                        true
                                      );
                                    }}
                                  />
                                </div>
                                <ErrorMessage
                                  name="end_date"
                                  component={Texterror}
                                />
                              </Form.Group>
                              <Form.Group
                                className="col-md-3 mb-3"
                                controlId="exampleForm.c_email"
                              >
                                <Form.Label>Calendar Year</Form.Label>
                                <Form.Select
                                  aria-label="Default select example"
                                  className="white"
                                  name="calendar_year"
                                  value={`${formik.values.calendar_year}`}
                                  onChange={(e) => {
                                    // formik.setFieldTouched(
                                    //   "customer_status",
                                    //   true,
                                    //   true
                                    // );
                                    formik.setFieldValue(
                                      "calendar_year",
                                      e.target.value
                                    );
                                    console.log(e.target.value);
                                  }}
                                  // onChange={formik.handleChange}
                                >
                                  {years.map((year) => (
                                    <option key={year} value={year}>
                                      {year}
                                    </option>
                                  ))}
                                </Form.Select>
                                <ErrorMessage
                                  name="calender_year"
                                  component={Texterror}
                                />
                              </Form.Group>
                              <Form.Group
                                className="col-md-3 mb-3"
                                controlId="exampleForm.c_email"
                              >
                                <Form.Label>Fiscal Year</Form.Label>
                                <Form.Select
                                  aria-label="Default select example"
                                  className="white"
                                  name="fiscal_year"
                                  value={`${formik.values.fiscal_year}`}
                                  onChange={(e) => {
                                    // formik.setFieldTouched(
                                    //   "customer_status",
                                    //   true,
                                    //   true
                                    // );
                                    formik.setFieldValue(
                                      "fiscal_year",
                                      e.target.value
                                    );
                                    console.log(e.target.value);
                                  }}
                                  // onChange={formik.handleChange}
                                >
                                  {years.map((year) => (
                                    <option key={year} value={year}>
                                      {year}
                                    </option>
                                  ))}
                                </Form.Select>
                                <ErrorMessage
                                  name="fiscal_year"
                                  component={Texterror}
                                />
                              </Form.Group>
                              <Form.Group className="col-md-12 mb-3 d-flex justify-content-end">
                                {/* <Button variant="primary" type="submit">
                              Reset
                            </Button> */}
                                <Button
                                  className="ms-3"
                                  variant="success"
                                  type="submit"
                                  disabled={isLoading}
                                >
                                  {isLoading ? "Saving..." : "Save"}
                                </Button>
                              </Form.Group>
                            </div>
                          </div>
                        </FormikForm>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Formik>
        </>
      ) : (
        <div className="d-flex align-items-center justify-content-center w-100vw h-100vh">
          <span class="loader" style={{ width: "50px", height: "50px" }}></span>
        </div>
      )}
    </>
  );
}

export default AddPayPeriodForm;
