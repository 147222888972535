import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./pages/auth/login/authConfig";
import { BrowserRouter } from "react-router-dom";
import { createMemoryHistory } from "history";
import { store } from "./store";
import { Provider } from "react-redux";
import "react-quill/dist/quill.snow.css";
// import { SocketContext, socket } from "./socket";

const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(document.getElementById("root"));
const history = createMemoryHistory();
root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      {/* <SocketContext.Provider value={socket}> */}
      <Provider store={store}>
        <BrowserRouter location={history.location} navigator={history}>
          <App />
        </BrowserRouter>
      </Provider>
      {/* </SocketContext.Provider> */}
    </MsalProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
