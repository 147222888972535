let API_CALL_URL_PUBLIC;
let API_CALL_URL_PRIVATE;
let DOMAIN_URL;
let USER_PORTAL;

//  Local Url
if (process.env.React_App_ENVIRONMENT === "local") {
  API_CALL_URL_PUBLIC = "http://localhost:3900/api/v1";
  API_CALL_URL_PRIVATE = "http://localhost:3900/api/v1/private";
  DOMAIN_URL = "http://localhost:3900/";
  USER_PORTAL = "http://localhost:3900/";
}

// production Url
if (process.env.React_App_ENVIRONMENT === "production") {
  API_CALL_URL_PUBLIC = "https://config.perfectshift.com/api/v1";
  API_CALL_URL_PRIVATE = "https://config.perfectshift.com/api/v1/private";
  DOMAIN_URL = "https://config.perfectshift.com";
  USER_PORTAL = "https://platform.perfectshift.com/";
}

// Development Url
if (process.env.React_App_ENVIRONMENT === "development") {
  API_CALL_URL_PUBLIC = "https://dev.config.perfectshift.com/api/v1";
  API_CALL_URL_PRIVATE = "https://dev.config.perfectshift.com/api/v1/private";
  DOMAIN_URL = "https://dev.config.perfectshift.com";
  USER_PORTAL = "https://dev.perfectshift.com/";
}
// Staging Url
if (process.env.React_App_ENVIRONMENT === "staging") {
  API_CALL_URL_PUBLIC = "https://staging.config.perfectshift.com/api/v1";
  API_CALL_URL_PRIVATE =
    "https://staging.config.perfectshift.com/api/v1/private";
  DOMAIN_URL = "https://staging.config.perfectshift.com";
  USER_PORTAL = "https://staging.perfectshift.com/";
}

const COM_SCREEN_ = {};
export const COM_SCREEN = COM_SCREEN_;

// export const USER_PORTAL = "https://dev.perfectshift.com/";
export { API_CALL_URL_PUBLIC, API_CALL_URL_PRIVATE, DOMAIN_URL, USER_PORTAL };
