import React, { useEffect, useState } from "react";
// import "./index.css";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from "react-router-dom";
import { ErrorMessage, Formik, Form as FormikForm } from "formik";
import { toast } from "react-toastify";
import axios from "../../axios";
import { API_CALL_URL_PRIVATE } from "../../constants/compensate";

import Texterror from "../Texterror";
import { useSelector } from "react-redux";
import { CostCenterCategoryValidation } from "../../utils/validator";

function AddCostCenterCategory() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [TimezoneOptions, setTimezoneOptions] = useState([]);
  const [isInitialData, setISInitialData] = useState(true);
  const customer_code = useSelector((state) => state.compensate.customerCode);

  const [initialValues, setInitialValues] = useState({
    cost_center_category_id: "",
    cost_center_category: "",
    note: "",
  });
  const [selectedImage, setSelectedImage] = useState(null);

  const onSubmit = (values, onSubmittingProps) => {
    console.log("form Values", values);
    formSubmit(values);
    // setTimeout(() => {
    //   onSubmittingProps.setSubmitting(false);
    // }, [2000]);
  };
  const formSubmit = async (values) => {
    const obj = {
      customer_code: parseInt(customer_code),
      cost_center_category_id: values.cost_center_category_id,
      cost_center_category: values.cost_center_category,
      note: values.note,
    };
    try {
      setIsLoading(true);
      const resp = await axios.post(
        `${API_CALL_URL_PRIVATE}/costcentercategory/addUpdate`,
        obj
      );
      console.log(resp);
      toast.success(resp?.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
      });
      navigate("/admin/cost-center-category");
    } catch (error) {
      toast.error(error.response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
      });

      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <>
      {isInitialData ? (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validate={CostCenterCategoryValidation}
          enableReinitialize
          validateOnChange
        >
          {(formik) => (
            <div className="main-panel">
              {console.log(formik)}
              <div className="content-wrapper">
                <div className="page-header">
                  <h3 className="page-title">
                    <a href="#" className="badge badge-dark"></a>
                  </h3>
                  <div className></div>
                </div>
                <div className="row">
                  <div class="col-12 grid-margin stretch-card">
                    <div class="card">
                      <div class="card-body">
                        <div className="d-flex justify-content-between">
                          <h4 class="card-title">Add Cost Center Category</h4>
                          <Link
                            to={`/admin/cost-center-category`}
                            className="f-14 white-text-dark-mode"
                          >
                            <FontAwesomeIcon icon="fa-solid fa-arrow-left " />{" "}
                            Back
                          </Link>
                        </div>

                        <FormikForm className="forms-sample">
                          <div class="row">
                            <Form.Group
                              className="col-md-6 mb-3"
                              controlId="exampleForm.c_code"
                            >
                              <Form.Label>Category Id</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=""
                                name="cost_center_category_id"
                                {...formik.getFieldProps(
                                  "cost_center_category_id"
                                )}
                              />
                              <ErrorMessage
                                name="cost_center_category_id"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-6 mb-3"
                              controlId="exampleForm.c_name"
                            >
                              <Form.Label>Category Name</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=""
                                name="cost_center_category"
                                {...formik.getFieldProps(
                                  "cost_center_category"
                                )}
                              />
                              <ErrorMessage
                                name="cost_center_category"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-12 mb-3"
                              controlId="exampleForm.c_name"
                            >
                              <Form.Label>Note</Form.Label>
                              <Form.Control
                                as="textarea"
                                rows={3}
                                placeholder=""
                                name="note"
                                {...formik.getFieldProps("note")}
                              />
                              <ErrorMessage name="note" component={Texterror} />
                            </Form.Group>

                            <Form.Group className="col-md-12 mb-3 d-flex justify-content-end">
                              {/* <Button
                              variant="primary"
                              type="button"
                              onClick={() => {
                                formik.resetForm();
                                setSelectedImage(null);
                                // const fileInput =
                                //   document.getElementById("logo");
                                // if (fileInput) {
                                //   fileInput.value = "";
                                // }
                              }}
                            >
                              Reset
                            </Button> */}
                              <Button
                                className="ms-3"
                                variant="success"
                                type="submit"
                                disabled={isLoading}
                              >
                                {isLoading ? "Saving..." : "Save"}
                              </Button>
                            </Form.Group>
                          </div>
                        </FormikForm>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Formik>
      ) : (
        <div className="d-flex align-items-center justify-content-center w-100vw h-100vh">
          <span class="loader" style={{ width: "50px", height: "50px" }}></span>
        </div>
      )}
    </>
  );
}

export default AddCostCenterCategory;
