import * as DS from "../../Styled/DashStyle";
import React, { useContext, useEffect, useState } from "react";
import { SiSpeedtest } from "react-icons/si";
import ReactApexChart from "react-apexcharts";
import { ThemeContext } from "../../providers/ThemeProvider";
import ReactDatePicker from "react-datepicker";
import { CiCalendarDate } from "react-icons/ci";
import axios from "../../axios";
import { API_CALL_URL_PRIVATE } from "../../constants";
import { useSelector, useDispatch } from "react-redux";

const NotificationCard = ({ dateFrom, dateTo, userType }) => {
  const [themeMode, setTheme] = useContext(ThemeContext);
  const customer_code = useSelector((state) => state.compensate.customerCode);
  // const [dateFrom, setDateFrom] = useState(new Date());
  // const [dateTo, setDateTo] = useState(new Date());
  const [apiData, setApiData] = useState(null);
  const [xData, setXData] = useState([]);
  const [yData, setYData] = useState([]);
  console.log(dateFrom, "dateFrom");
  console.log(dateTo, "dateTo");
  const [heading, setHeading] = useState("User Clicks per hour");

  function formatDate(date) {
    if (date instanceof Date && !isNaN(date.getTime())) {
      const day = date.getDate().toString().padStart(2, "0"); // Add leading zero if needed
      const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
      const year = date.getFullYear();

      // Return the formatted date string
      return `${month}/${day}/${year}`;
    } else {
      // If the date is not available or is not a valid Date object, return an empty string
      return "";
    }
  }

  useEffect(() => {
    const getGraphData = async () => {
      try {
        const resp = await axios.post(
          `${API_CALL_URL_PRIVATE}/home/getUserClicks`,
          {
            customer_code: customer_code,
            user_type: userType,
            from_date: formatDate(dateFrom),
            to_date: formatDate(dateTo),
          }
        );

        setApiData(resp?.data?.data?.graphData);
        setXData(
          resp?.data?.data?.graphData?.map((data) => {
            return data.displayDates;
          })
        );
        setYData(
          resp?.data?.data?.graphData?.map((data) => {
            return data.data;
          })
        );
        setHeading(resp?.data?.data?.heading);
      } catch (error) {
        console.log(error);
      }
    };
    getGraphData();
  }, [dateFrom, dateTo, customer_code, userType]);

  const options = {
    chart: {
      id: "basic-bar",
      toolbar: {
        show: false,
      },
    },
    stroke: {
      curve: "straight",
      width: "2",
    },
    // dataLabels: {
    //   enabled: true,
    //   hideOverflowingLabels: true,

    //   offsetY: -20,
    //   style: {
    //     fontSize: "12px",
    //     colors: themeMode.theme === "DARK" ? ["white"] : ["black"],
    //     padding: {
    //       top: 30
    //     },
    //   },

    // },
    colors: themeMode.theme === "DARK" ? ["#dfed40"] : ["#033e8d"],
    xaxis: {
      // categories: [
      //   "00:00",
      //   "01:00",
      //   "02:00",
      //   "03:00",
      //   "04:00",
      //   "05:00",
      //   "06:00",
      //   "07:00",
      //   "08:00",
      //   "09:00",
      //   "10:00",
      //   "11:00",
      //   "12:00",
      //   "13:00",
      //   "14:00",
      //   "15:00",
      //   "16:00",
      //   "17:00",
      //   "18:00",
      //   "19:00",
      //   "20:00",
      //   "21:00",
      //   "22:00",
      //   "23:00",
      // ],
      categories: xData,

      labels: {
        style: {
          fontSize: "12px",
          colors: themeMode.theme === "DARK" ? "white" : "black",
        },
        //   rotate: 0,
        //   rotateAlways: true,
        //   hideOverlappingLabels: false,
        //   showDuplicates: true,
        // //  offsetY: 10,
        // //  maxHeight: 80,
        //   show: true,
      },
    },
    yaxis: {
      labels: {
        offsetX: -10,
        offsetY: 5,
        style: {
          fontSize: "12px",
          colors: themeMode.theme === "DARK" ? "white" : "black",
        },
      },
    },
  };

  return (
    <>
      <DS.NotificationCardParent className="flx-r">
        <DS.Heading style={{ padding: "0rem 0.5rem" }}>{heading}</DS.Heading>
        {/* <DS.Heading style={{padding:"0.5rem 0.5rem"}} className="d-flex gap-5">
          <div className="d-flex flex-column col dt-pi">
            <h5 class="m-0 fs-6 d-flex align-items-center">
              From Date
              <CiCalendarDate className="fs-5 mx-2" />
            </h5>
            <ReactDatePicker
              // showIcon={true}
              closeOnScroll={(e) => e.target === document}
              selected={dateFrom}
              dateFormat="MM/dd/yyyy"
              isClearable={true}
              onChange={(date) => {
                setDateFrom(date);
                // setListLoader(true);
              }}
            />
          </div>
          <div
            className="d-flex flex-column col dt-pi"
            style={{ width: "100px" }}
          >
            <h5 class="m-0 fs-6 d-flex align-items-center">
              To Date
              <CiCalendarDate className="fs-5 mx-2" />
            </h5>
            <div
            //   className="ms-4"
            >
              <ReactDatePicker
                // showIcon={true}
                closeOnScroll={(e) => e.target === document}
                selected={dateTo}
                minDate={dateFrom || null}
                dateFormat="MM/dd/yyyy"
                isClearable={true}
                onChange={(date) => {
                  setDateTo(date);
                  // setListLoader(true);
                }}
              />
            </div>
          </div>
        </DS.Heading> */}
        <div className="subheading">
          How many times did a user take any action with in the platform
        </div>

        <div className="mixed-chart" style={{ height: "100%" }}>
          <ReactApexChart
            series={[
              {
                name: "User Clicks",
                data: yData,
                // data: [
                //   30, 40, 45, 50, 49, 60, 70, 91, 30, 40, 45, 50, 49, 60, 70,
                //   91, 30, 40, 45, 50, 49, 60, 70, 91,
                // ],
              },
            ]}
            type="line"
            // width="500"
            options={options}
          />
        </div>

        {/* {[1, 2, 3, 4, 5].map((i) => {
          return (
            <DS.NotificationBox key={i}>
              <DS.IconBox>
                <SiSpeedtest size={"1.2rem"} />
              </DS.IconBox>
              <DS.ContentBox>
                <DS.ContentRowOne>
                  <DS.Value>1 west</DS.Value>
                  <DS.SubValue>08/21/2023, 03:00</DS.SubValue>
                </DS.ContentRowOne>
                <DS.ContentRow>
                  <DS.SubValue style={{ color: "white" }}>
                    07:00 <DS.SubValue>Shift has been added</DS.SubValue>
                  </DS.SubValue>
                </DS.ContentRow>
                <DS.ContentRow>
                  <DS.SubValue style={{ color: "white" }}>
                    Submitted by : <DS.SubValue>PERFECTSHIP</DS.SubValue>
                  </DS.SubValue>
                </DS.ContentRow>
              </DS.ContentBox>
            </DS.NotificationBox>
          );
        })} */}
      </DS.NotificationCardParent>
    </>
  );
};

export default NotificationCard;
