import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./index.css";
import axios from "../../axios";
import { API_CALL_URL_PRIVATE } from "../../constants";

function DetailModal(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState(null);
  const [page,setPage] = useState(1);

  const fetchUserData = async () => {
    setIsLoading(true);
    try {
      const resp = await axios.post(
        `${API_CALL_URL_PRIVATE}/adminActivityLog/detail`,
        {
          _id: props.id,
        }
      );
      setUserData(resp.data.data);
      console.log(resp);
    } catch (error) {
      console.error("Failed to fetch user data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (props.show) {
      fetchUserData();
    }
  }, [props.show]);

  function formatDate(inputDate) {
    const timeZone = "America/Los_Angeles";
    const date = new Date(inputDate).toLocaleString("en-US", {
      timeZone,
      hour12: true,
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    });

    return date;
  }

  function MyComponent({ htmlContent }) {
    console.log("htmlContent", htmlContent);
    return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
  }
  const handleTest = () => {};

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton className="custom-font-size">
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="fw-normal color-black f-18 text-center" 
        >
          Activity Detail
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="custom-font-size text-indent-15">
        {isLoading ? (
          <div
            className="d-flex align-items-center justify-content-center w-100"
            style={{ height: "230px" }}
          >
            <span
              class="loader"
              style={{
                width: "50px",
                height: "50px",
              }}
            ></span>
          </div>
        ) : (
          userData && (
            <>
              <div className="d-flex flex-column px-3 py-4 gap-3">
                <div className="d-flex">
                  <div style={{ width: "30%" }}>Activity Date/Time</div>
                  &nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;
                  {userData?.createdAt
                    ? formatDate(userData?.createdAt)
                    : "N/A"}
                </div>
                <div className="d-flex">
                  <div style={{ width: "30%" }}>Employee Id</div>
                  &nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;
                  {userData?.employee_id ? userData.employee_id : "N/A"}
                </div>
                <div className="d-flex">
                  <div style={{ width: "30%" }}>Employee Name</div>
                  &nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;
                  {userData?.users?.employee_name
                    ? userData.users.employee_name
                    : "N/A"}
                </div>
                <div className="d-flex">
                  <div style={{ width: "30%" }}>Cost Center Name</div>
                  &nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;
                  {userData?.cost_center_masters?.cost_center_name || "N/A"}
                </div>
                <div className="d-flex">
                  <div style={{ width: "30%" }}>Application Name</div>
                  &nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;
                  {userData?.applications?.title || "N/A"}
                </div>
                <div className="d-flex">
                  <div style={{ width: "30%" }}>Activity Type</div>
                  &nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;
                  {userData?.activity_masters?.title || "N/A"}
                </div>
                <div className="d-flex">
                  <div style={{ width: "31%" }}>Detail</div>
                  &nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;
                  {userData.note ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: userData.note,
                      }}
                      style={{ width: "70%" }}
                    >
                      {/* {userData?.note || "N/A"} */}
                    </div>
                  ) : (
                    <div>N/A</div>
                  )}
                </div>
                
                {userData.user_agents ? (
                  <>
                    <div className="text-center mt-2">Browser Details</div>
                    <div className="d-flex">
                      <div style={{ width: "30%" }}>IP</div>
                      &nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;
                      {userData?.user_agents?.ip || "N/A"}
                    </div>
                    <div className="d-flex">
                      <div style={{ width: "30%" }}>Country</div>
                      &nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;
                      {userData?.user_agents?.country || "N/A"}
                    </div>
                    <div className="d-flex">
                      <div style={{ width: "30%" }}>City</div>
                      &nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;
                      {userData?.user_agents?.city || "N/A"}
                    </div>
                    <div className="d-flex">
                      <div style={{ width: "31%" }}>Browser</div>
                      &nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;
                      <div style={{ width: "70%" }}>
                        {userData?.user_agents?.user_agent}
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
            </>
          )
        )}
      </Modal.Body>
    </Modal>
  );
}

export default DetailModal;
