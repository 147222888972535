import { COM_SCREEN } from "../../constants";
import {
  HEADCOUNTE,
  GET_USERS,
  FOOTERHOME,
  MAINSIDEBAR,
  EHREXCEPTIONEVENT,
  DEPARTMENTDETAIL,
  PREVIOUSSHIFTDATA,
  GETPREVIOUSANDCURRENTSHIFT,
  USERLOGIN,
  ACTIVEDATEFILTER,
  SELECTEDSHIFT,
  PREVIOUSSHIFTDATAMODAL,
  GRIDSHIFTDATAMODAL,
  GETPREVIOUSSHIFTDATA,
  UPDATE_DATA,
  USER_DATA,
  UPDATED_DATE,
  UPDATED_INDEX,
  STACKED_DATE,
  CUSTOMER_CODE,
  LOADING_PAGE,
  IS_NAVIGATE
} from "../constants";

let usersData = JSON.parse(localStorage.getItem("psAdmin"));
const customer_code = localStorage.customerCode;
export const initCompensateState = {
  airlines: [],
  getusers: [],
  mainsidebar: [],
  ehrexceptionevent: [],
  footerhome: [],
  compensateStep: COM_SCREEN.NUMBER_OF_PASSENGERS,
  addressLookup: [],
  headcount: {},
  departmentdetail: {},
  previousAndCurrentShift: {},
  previousShiftData: {},
  activedatefilter: {},
  selectedshift: "",
  previousshiftdatamodal: [],
  gridshiftdatamodal: [],
  getPreviousShiftData: [],
  update_data: [],
  userData: usersData,
  updateDate: "",
  updatedIndex: 420,
  stackDate: "",
  customerCode: customer_code,
  LoadingPage: false,
  IsNavigate:false,
};

const compensate = (state = initCompensateState, { type, data }) => {
  //console.log(data);

  switch (type) {
    case HEADCOUNTE:
      return {
        ...state,
        headcount: data,
      };
    case GET_USERS:
      return {
        ...state,
        getusers: data,
      };
    case FOOTERHOME:
      return {
        ...state,
        footerhome: data,
      };
    case MAINSIDEBAR:
      return {
        ...state,
        mainsidebar: data,
      };
    case EHREXCEPTIONEVENT:
      return {
        ...state,
        ehrexceptionevent: data,
      };
    case DEPARTMENTDETAIL:
      return {
        ...state,
        departmentdetail: data,
      };
    case GETPREVIOUSANDCURRENTSHIFT:
      return {
        ...state,
        previousAndCurrentShift: data,
      };
    case PREVIOUSSHIFTDATA:
      return {
        ...state,
        previousShiftData: data,
      };
    case USERLOGIN:
      return {
        ...state,
        loginData: data,
      };
    case ACTIVEDATEFILTER:
      return {
        ...state,
        activedatefilter: data,
      };
    case SELECTEDSHIFT:
      return {
        ...state,
        selectedshift: data,
      };
    case PREVIOUSSHIFTDATAMODAL:
      return {
        ...state,
        previousshiftdatamodal: data,
      };
    case GRIDSHIFTDATAMODAL:
      return {
        ...state,
        gridshiftdatamodal: data,
      };
    case GETPREVIOUSSHIFTDATA:
      return {
        ...state,
        getPreviousShiftData: data,
      };
    //  User Data
    case UPDATE_DATA:
      return {
        ...state,
        update_data: data,
      };
    case CUSTOMER_CODE:
      return {
        ...state,
        customerCode: data,
      };
    case LOADING_PAGE:
      return {
        ...state,
        LoadingPage: data,
      };
    case IS_NAVIGATE:
      return {
        ...state,
        IsNavigate: data,
      };
    case USER_DATA:
      return {
        ...state,
        userData: data,
      };
    case UPDATED_DATE:
      return {
        ...state,
        updateDate: data,
      };
    case UPDATED_INDEX:
      return {
        ...state,
        updatedIndex: data,
      };
    case STACKED_DATE:
      return {
        ...state,
        stackDate: data,
      };
    default:
      return state;
  }
};

export default compensate;
