import React, { useEffect, useState } from "react";
import "./index.css";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ErrorMessage, Formik, Form as FormikForm } from "formik";
import { toast } from "react-toastify";
import axios from "../../axios";
import { API_CALL_URL_PRIVATE, DOMAIN_URL } from "../../constants/compensate";
import Texterror from "../Texterror";
import { departmentValidation } from "../../utils/validator";
import ReactDatePicker from "react-datepicker";
import { Switch } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { setLoadingPage } from "../../store/actions";
import Select from "react-select";
import Ico1 from "../../assets/img/edit.png";

function EditCostCenterForm() {
  const { ID, costCenter } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const customer_code = useSelector((state) => state.compensate.customerCode);
  const loading_page = useSelector((state) => state.compensate.LoadingPage);
  const [FormInitialData, setFormInitialData] = useState();
  const [selectedImage, setSelectedImage] = useState(null);
  const [initialImageUrl, setInitialImageUrl] = useState(null);
  const [costCenterMaster, setCostCenterMaster] = useState(null);
  const [dropCostCenterMaster, setDropCostCenterMaster] = useState([]);
  const [facilityMaster, setFacilityMaster] = useState(null);
  const [applications, setApplications] = useState(null);
  const [hireDate, setHireDate] = useState(null);
  const [isInitialData, setISInitialData] = useState(false);
  const [terminateDate, setTerminateDate] = useState(null);
  const [selectAllApplication, setSelectAllApplication] = useState(false);
  const [DeselectAllApplication, setDeselectAllApplication] = useState(false);
  const [selectAllCostCenter, setSelectAllCostCenter] = useState(false);
  const [DeselectAllCostCenter, setDeselectAllCostCenter] = useState(false);
  const [isRealtime, setIsRealtime] = useState(false);
  const [formControl, setFormControl] = useState([]);
  // const [checkedAllFormControll, setcheckedAllFormControll] = useState(null);
  console.log(formControl);
  const [initialValues, setInitialValues] = useState({
    cost_center_name: "",
    cost_center_number: "",
    cost_center_category: "",
    business_unit_description: "",
    target: "",
    target_type: "",
    tier2: "",
    tier3: "",
    tier4: "",
    tier5: "",
    uos_description: "",
    realtime: 0,
    customer_code: "",
    fixed_or_variable: "",
    facility_id: "",
    facility_masters: "",
    director: "",
    manager: "",
    is10day: "0",
    adm: "0",
    accepted_acuity_per_nurse: "",
    warm_up: 30,
  });

  // const getFormatDate = (val) => {
  //   const inputDate = new Date(`${val}`);
  //   const year = inputDate.getFullYear();
  //   const month = String(inputDate.getMonth() + 1).padStart(2, "0");
  //   const day = String(inputDate.getDate()).padStart(2, "0");
  //   return val ? `${day}/${month}/${year}` : "";
  // };

  const getFormatDate = (val) => {
    if (!val) return ""; // Handle empty or undefined input

    let inputDate;

    if (typeof val === "string") {
      // If the input is a string, attempt to parse it
      const dateComponents = val.split("/");
      if (dateComponents.length !== 3) return ""; // Handle invalid date format

      const [month, day, year] = dateComponents.map((component) =>
        parseInt(component, 10)
      );

      // Check if the parsed components are valid numbers
      if (isNaN(month) || isNaN(day) || isNaN(year)) {
        return ""; // Handle invalid numeric values
      }

      inputDate = new Date(year, month - 1, day); // Note: months are zero-based in JavaScript dates
    } else if (val instanceof Date) {
      // If the input is a Date object, use it directly
      inputDate = val;
    } else {
      return ""; // Handle unsupported input type
    }

    const formattedYear = inputDate.getFullYear();
    const formattedMonth = String(inputDate.getMonth() + 1).padStart(2, "0");
    const formattedDay = String(inputDate.getDate()).padStart(2, "0");

    return `${formattedDay}/${formattedMonth}/${formattedYear}`;
  };

  useEffect(() => {
    const getInitalData = async () => {
      const resp = await axios.post(
        `${API_CALL_URL_PRIVATE}/department/departmentDetail`,
        {
          cost_center_number: parseInt(costCenter),
          customer_code: parseInt(customer_code),
        }
      );
      // setcheckedAllFormControll(resp?.data?.data?.checkedAllFormControll);
      const Detail = resp?.data?.data?.department_details;
      setFormControl(resp?.data?.data?.form_control);
      console.log(Detail);
      const hdateArray = Detail?.hire_date?.split("/");
      // if (hdateArray && hdateArray.length === 3) {
      //   const [hday, hmonth, hyear] = hdateArray;
      //   setHireDate(new Date(`${hyear}-${hmonth}-${hday}`));
      // }
      // // setHireDate(new Date(`${Detail?.hire_date}`))

      // const tdateArray = Detail?.termination_date?.split("/");
      // if (tdateArray && tdateArray.length === 3) {
      //   const [day, month, year] = tdateArray;
      //   setTerminateDate(new Date(`${year}-${month}-${day}`));
      // }
      if (hdateArray && hdateArray.length === 3) {
        const [hday, hmonth, hyear] = hdateArray;

        // Check if day, month, and year are valid numbers
        if (!isNaN(hday) && !isNaN(hmonth) && !isNaN(hyear)) {
          setHireDate(new Date(`${hyear}-${hmonth}-${hday}`));
        }
      }

      const tdateArray = Detail?.termination_date?.split("/");
      if (tdateArray && tdateArray.length === 3) {
        const [day, month, year] = tdateArray;

        // Check if day, month, and year are valid numbers
        if (!isNaN(day) && !isNaN(month) && !isNaN(year)) {
          setTerminateDate(new Date(`${year}-${month}-${day}`));
        }
      }
      setIsRealtime(Detail?.realtime === 1);
      setInitialValues((prev) => {
        return {
          ...prev,
          cost_center_name: Detail?.cost_center_name,
          cost_center_number: Detail?.cost_center_number,
          cost_center_category: Detail?.cost_center_category,
          business_unit_description: Detail?.business_unit_description,
          target: Detail?.target,
          target_type: Detail?.target_type,
          tier2: Detail?.tier2,
          tier3: Detail?.tier3,
          tier4: Detail?.tier4,
          tier5: Detail?.tier5,
          uos_description: Detail?.uos_description,
          realtime: Detail?.realtime,
          customer_code: Detail?.customer_code,
          facility_id: Detail?.facility_id,
          facility_masters: Detail?.facility_masters,
          fixed_or_variable: Detail?.fixed_or_variable,
          manager: Detail?.manager,
          director: Detail?.director,
          is10day: Detail?.is10day || "0",
          adm: Detail?.adm || "0",
          accepted_acuity_per_nurse: Detail?.accepted_acuity_per_nurse,
          warm_up: Detail?.warm_up ? Detail.warm_up : 30,
        };
      });

      setFacilityMaster(Detail?.facility_masters);

      setISInitialData(true);
      // setInitialImageUrl(`${DOMAIN_URL}/${Detail?.logo}`);
    };
    getInitalData();
  }, [ID]);

  // const getCostCenter = async () => {
  //   const resp = await axios.post(
  //     `${API_CALL_URL_PRIVATE}/department/departmentList`,
  //     {
  //       customer_code: parseInt(customer_code),
  //       limit: "100000",
  //     }
  //   );
  //   setCostCenterMaster(
  //     resp?.data?.data?.data?.docs?.map((e) => {
  //       return {
  //         cost_center_name: e.cost_center_name,
  //         cost_center_number: e.cost_center_number,
  //       };
  //     })
  //   );
  //   // setISInitialData(true);
  //   console.log(resp);
  //   setDropCostCenterMaster(
  //     resp?.data?.data?.data?.docs?.map((e) => {
  //       return {
  //         label: e.cost_center_name,
  //         value: e.cost_center_number,
  //       };
  //     })
  //   );
  //   dispatch(setLoadingPage(false));
  // };
  // useEffect(() => {
  //   getCostCenter();
  // }, [customer_code]);

  //Patient Data
  const handlePatientCheck = (val) => {
    // const Vari = checkedAllFormControll ? formControlTemp : formControl;
    const person = formControl?.patient_data?.find((p) => p.field_name === val);
    if (person) {
      return person?.display === 1;
    }
    return false;
  };
  const handlePatientSwitchcheck = (val) => {
    // const Vari = checkedAllFormControll ? formControlTemp : formControl;
    const person = formControl?.patient_data?.find((p) => p.field_name === val);
    if (person?.display === 1) {
      return person?.required === 1;
    } else return false;
  };
  // const handlePatientOnChange = (fieldName, val) => {
  //   setFormControl((prevFormControl) => {
  //     const updatedPatientData = prevFormControl?.patient_data.map((item) => {
  //       if (item?.field_name === fieldName) {
  //         return parseInt(val)
  //           ? { ...item, display: parseInt(val) }
  //           : { ...item, display: parseInt(val), required: parseInt(val) };
  //       }
  //       return item;
  //     });

  //     return {
  //       ...prevFormControl,
  //       patient_data: updatedPatientData,
  //     };
  //   });
  // };

  const handlePatientOnChange = (fieldName, val) => {
    setFormControl((prevFormControl) => {
      // Check if the fieldName exists in the patient_data
      const fieldExists = prevFormControl.patient_data.some(
        (item) => item.field_name === fieldName
      );

      let updatedPatientData;
      if (fieldExists) {
        // If the field exists, update it
        updatedPatientData = prevFormControl.patient_data.map((item) => {
          if (item.field_name === fieldName) {
            // Conditionally update based on whether `val` is parseable as an integer
            return parseInt(val)
              ? { ...item, display: parseInt(val) }
              : { ...item, display: parseInt(val), required: parseInt(val) }; // The requirement logic seems ambiguous; adjust as needed
          }
          return item;
        });
      } else {
        // If the field doesn't exist, add a new item
        updatedPatientData = [
          ...prevFormControl.patient_data,
          {
            field_name: fieldName,
            display: parseInt(val),
            required: parseInt(val),
            field_label: null,
          },
        ];
      }

      return {
        ...prevFormControl,
        patient_data: updatedPatientData,
      };
    });
  };

  const handlePatientSwitchOnChange = (fieldName, val) => {
    setFormControl((prevFormControl) => {
      const updatedPatientData = prevFormControl?.patient_data.map((item) => {
        if (item?.field_name === fieldName) {
          if (item?.display === 1) {
            return { ...item, required: parseInt(val ? "1" : "0") };
          } else {
            return { ...item, required: 0 };
          }
          // Toggle the display value (0 to 1, or 1 to 0)
        }
        return item;
      });

      return {
        ...prevFormControl,
        patient_data: updatedPatientData,
      };
    });
  };

  //Staffing
  const handleStaffingCheck = (val) => {
    // const Vari = checkedAllFormControll ? formControlTemp : formControl;
    const person = formControl?.staffing?.find((p) => p?.field_name === val);
    if (person) {
      return person?.display === 1;
    }
    return false;
  };
  const handleStaffingSwitchcheck = (val) => {
    // const Vari = checkedAllFormControll ? formControlTemp : formControl;
    const person = formControl?.staffing?.find((p) => p?.field_name === val);
    if (person?.display === 1) {
      return person?.required === 1;
    } else return false;
  };
  // const handleStaffingOnChange = (fieldName, val) => {
  //   setFormControl((prevFormControl) => {
  //     const updatedstaffingData = prevFormControl?.staffing.map((item) => {
  //       if (item?.field_name === fieldName) {
  //         console.log(item?.field_name);
  //         return parseInt(val)
  //           ? { ...item, display: parseInt(val) }
  //           : { ...item, display: parseInt(val), required: parseInt(val) };
  //       }
  //       return item;
  //     });

  //     return {
  //       ...prevFormControl,
  //       staffing: updatedstaffingData,
  //     };
  //   });
  // };
  const handleStaffingOnChange = (fieldName, val) => {
    setFormControl((prevFormControl) => {
      // Check if the fieldName exists in the patient_data
      const fieldExists = prevFormControl?.staffing.some(
        (item) => item?.field_name === fieldName
      );

      let updatedStaffingData;
      if (fieldExists) {
        // If the field exists, update it
        updatedStaffingData = prevFormControl?.staffing?.map((item) => {
          if (item.field_name === fieldName) {
            // Conditionally update based on whether `val` is parseable as an integer
            return parseInt(val)
              ? { ...item, display: parseInt(val) }
              : { ...item, display: parseInt(val), required: parseInt(val) }; // The requirement logic seems ambiguous; adjust as needed
          }
          return item;
        });
      } else {
        // If the field doesn't exist, add a new item
        updatedStaffingData = [
          ...prevFormControl.staffing,
          {
            field_name: fieldName,
            display: parseInt(val), // Assuming you want to store the parsed int or the original value
            required: parseInt(val),
            field_label: null, // This seems to imply that `required` is true when val is parseable as a non-zero integer
          },
        ];
      }

      return {
        ...prevFormControl,
        staffing: updatedStaffingData,
      };
    });
  };
  const handleStaffingSwitchOnChange = (fieldName, val) => {
    setFormControl((prevFormControl) => {
      const updatedstaffingData = prevFormControl?.staffing.map((item) => {
        if (item?.field_name === fieldName) {
          if (item?.display === 1) {
            return { ...item, required: parseInt(val ? "1" : "0") };
          } else {
            return { ...item, required: 0 };
          }
          // Toggle the display value (0 to 1, or 1 to 0)
        }
        return item;
      });

      return {
        ...prevFormControl,
        staffing: updatedstaffingData,
      };
    });
  };

  //Additional Data Tracking
  const handleAddDataCheck = (val) => {
    // const Vari = checkedAllFormControll ? formControlTemp : formControl;
    const person = formControl?.additional_data_tracking?.find(
      (p) => p?.field_name === val
    );
    if (person) {
      return person?.display === 1;
    }
    return false;
  };
  const handleAddDataSwitchcheck = (val) => {
    // const Vari = checkedAllFormControll ? formControlTemp : formControl;
    const person = formControl?.additional_data_tracking?.find(
      (p) => p?.field_name === val
    );
    if (person?.display === 1) {
      return person?.required === 1;
    } else return false;
  };
  // const handleAddDataOnChange = (fieldName, val) => {
  //   setFormControl((prevFormControl) => {
  //     const updatedAddDataData = prevFormControl?.additional_data_tracking.map(
  //       (item) => {
  //         if (item?.field_name === fieldName) {
  //           return parseInt(val)
  //             ? { ...item, display: parseInt(val) }
  //             : { ...item, display: parseInt(val), required: parseInt(val) };
  //         }
  //         return item;
  //       }
  //     );

  //     return {
  //       ...prevFormControl,
  //       additional_data_tracking: updatedAddDataData,
  //     };
  //   });
  // };
  const handleAddDataOnChange = (fieldName, val) => {
    setFormControl((prevFormControl) => {
      // Check if the fieldName exists in the patient_data
      const fieldExists = prevFormControl?.additional_data_tracking.some(
        (item) => item?.field_name === fieldName
      );

      let updatedAddDataData;
      if (fieldExists) {
        // If the field exists, update it
        updatedAddDataData = prevFormControl?.additional_data_tracking?.map(
          (item) => {
            if (item.field_name === fieldName) {
              // Conditionally update based on whether `val` is parseable as an integer
              return parseInt(val)
                ? { ...item, display: parseInt(val) }
                : { ...item, display: parseInt(val), required: parseInt(val) }; // The requirement logic seems ambiguous; adjust as needed
            }
            return item;
          }
        );
      } else {
        // If the field doesn't exist, add a new item
        updatedAddDataData = [
          ...prevFormControl.additional_data_tracking,
          {
            field_name: fieldName,
            display: parseInt(val), // Assuming you want to store the parsed int or the original value
            required: parseInt(val),
            field_label: null, // This seems to imply that `required` is true when val is parseable as a non-zero integer
            display_graph: 0,
          },
        ];
      }

      return {
        ...prevFormControl,
        additional_data_tracking: updatedAddDataData,
      };
    });
  };
  const handleAddDataSwitchOnChange = (fieldName, val) => {
    setFormControl((prevFormControl) => {
      const updatedAddData = prevFormControl?.additional_data_tracking.map(
        (item) => {
          if (item?.field_name === fieldName) {
            if (item?.display === 1) {
              return { ...item, required: parseInt(val ? "1" : "0") };
            } else {
              return { ...item, required: 0 };
            }
            // Toggle the display value (0 to 1, or 1 to 0)
          }
          return item;
        }
      );

      return {
        ...prevFormControl,
        additional_data_tracking: updatedAddData,
      };
    });
  };

  const onSubmit = (values, onSubmittingProps) => {
    console.log("form Values", values);
    formSubmit(values);
    // setTimeout(() => {
    //   onSubmittingProps.setSubmitting(false);
    // }, [2000]);
  };
  const formSubmit = async (values) => {
    const obj = {
      _id: ID,
      cost_center_name: values.cost_center_name,
      cost_center_number: parseInt(values.cost_center_number),
      cost_center_category: values.cost_center_category,
      business_unit_description: values.business_unit_description,
      target: parseFloat(values.target),
      target_type: values.target_type,
      tier2: values.tier2,
      tier3: values.tier3,
      tier4: values.tier4,
      tier5: values.tier5,
      uos_description: values.uos_description,
      realtime: parseInt(values.realtime),
      customer_code: parseInt(values.customer_code),
      facility_id: parseInt(values.facility_id),
      fixed_or_variable: values.fixed_or_variable,
      form_control: formControl,
      manager: values.manager,
      director: values.director,
      is10day: parseInt(values.is10day),
      adm: parseInt(values.adm),
      warm_up: parseInt(values.warm_up),
      accepted_acuity_per_nurse:
        values.accepted_acuity_per_nurse !== ""
          ? parseFloat(values.accepted_acuity_per_nurse)
          : 0,
    };
    console.log(obj);
    try {
      setIsLoading(true);
      const resp = await axios.post(
        `${API_CALL_URL_PRIVATE}/department/departmentAddUpdate`,
        obj
      );
      console.log(resp);
      toast.success(resp?.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
      });
      // navigate("/admin/customer");
    } catch (error) {
      toast.error(error.response, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
      });

      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <>
      {isInitialData ? (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validate={departmentValidation}
          enableReinitialize
          validateOnChange
        >
          {(formik) => (
            <div className="main-panel ">
              {console.log(formik)}
              <div className="content-wrapper ">
                <div className="page-header">
                  <h3 className="page-title">
                    <a href="#" className="badge badge-dark"></a>
                  </h3>
                  <div className></div>
                </div>
                <div className="row ">
                  <div class="col-12 grid-margin stretch-card">
                    <div class="card">
                      <FormikForm className="forms-sample">
                        <div class="card-body">
                          <div className="row align-items-center">
                            <div className="col-3">
                              <Link
                                to={`/admin/department`}
                                className="f-14 white-text-dark-mode"
                              >
                                <FontAwesomeIcon icon="fa-solid fa-arrow-left " />{" "}
                                Back
                              </Link>
                            </div>
                            <div className="col-6">
                              <h4 class="card-title text-center">
                                Update details for :{" "}
                                {initialValues?.cost_center_name}
                              </h4>
                            </div>
                            <div className="col-3 text-end">
                              <Button
                                className="ms-3"
                                variant="success"
                                type="submit"
                                disabled={isLoading}
                              >
                                {isLoading ? "Saving..." : "Save"}
                              </Button>
                            </div>
                          </div>

                          {/* <Form.Group className="col-md-12 mb-3 d-flex justify-content-end">
                            
                            <Button
                              className="ms-3"
                              variant="success"
                              type="submit"
                              disabled={isLoading}
                            >
                              {isLoading ? "Saving..." : "Save"}
                            </Button>
                          </Form.Group> */}
                          <div class="row">
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_code"
                            >
                              <Form.Label>Cost center number</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=""
                                name="cost_center_number"
                                value={formik.values.cost_center_number}
                                readonly
                              />
                              <ErrorMessage
                                name="cost_center_number"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_name"
                            >
                              <Form.Label>Cost Center Name</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=""
                                name="cost_center_name"
                                {...formik.getFieldProps("cost_center_name")}
                              />
                              <ErrorMessage
                                name="cost_center_name"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_name"
                            >
                              <Form.Label>Director</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=""
                                name="director"
                                {...formik.getFieldProps("director")}
                              />
                              <ErrorMessage
                                name="director"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_name"
                            >
                              <Form.Label>Manager</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=""
                                name="manager"
                                {...formik.getFieldProps("manager")}
                              />
                              <ErrorMessage
                                name="manager"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_number"
                            >
                              <Form.Label>Cost center category</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=""
                                name="cost_center_category"
                                {...formik.getFieldProps(
                                  "cost_center_category"
                                )}
                              />
                              <ErrorMessage
                                name="cost_center_category"
                                component={Texterror}
                              />
                            </Form.Group>

                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>Business Unit Description</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=""
                                name="business_unit_description"
                                {...formik.getFieldProps(
                                  "business_unit_description"
                                )}
                              />
                              <ErrorMessage
                                name="business_unit_description"
                                component={Texterror}
                              />
                            </Form.Group>

                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>Target</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=""
                                name="target"
                                {...formik.getFieldProps("target")}
                              />
                              <ErrorMessage
                                name="target"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>Target type</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=""
                                name="target_type"
                                {...formik.getFieldProps("target_type")}
                              />
                              <ErrorMessage
                                name="target_type"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>Tier 2</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=""
                                name="tier2"
                                {...formik.getFieldProps("tier2")}
                              />
                              <ErrorMessage
                                name="tier2"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>Tier 3</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=""
                                name="tier3"
                                {...formik.getFieldProps("tier3")}
                              />
                              <ErrorMessage
                                name="tier3"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>Tier 4</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=""
                                name="tier4"
                                {...formik.getFieldProps("tier4")}
                              />
                              <ErrorMessage
                                name="tier4"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>Tier 5</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=""
                                name="tier5"
                                {...formik.getFieldProps("tier5")}
                              />
                              <ErrorMessage
                                name="tier5"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>Accepted Acuity per Nurse</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=""
                                name="accepted_acuity_per_nurse"
                                {...formik.getFieldProps(
                                  "accepted_acuity_per_nurse"
                                )}
                              />
                              <ErrorMessage
                                name="accepted_acuity_per_nurse"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>UOS Description</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=""
                                name="uos_description"
                                {...formik.getFieldProps("uos_description")}
                              />
                              <ErrorMessage
                                name="uos_description"
                                component={Texterror}
                              />
                            </Form.Group>

                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>Realtime</Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                className="white"
                                name="realtime"
                                value={`${formik.values.realtime}`}
                                onChange={(e) => {
                                  // formik.setFieldTouched(
                                  //   "customer_status",
                                  //   true,
                                  //   true
                                  // );
                                  formik.setFieldValue(
                                    "realtime",
                                    e.target.value
                                  );
                                  setIsRealtime(parseInt(e.target.value) === 1);
                                }}
                                // onChange={formik.handleChange}
                              >
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                              </Form.Select>
                              <ErrorMessage
                                name="realtime"
                                component={Texterror}
                              />
                            </Form.Group>

                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>ADM</Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                className="white"
                                name="adm"
                                value={`${formik.values.adm}`}
                                onChange={(e) => {
                                  // formik.setFieldTouched(
                                  //   "customer_status",
                                  //   true,
                                  //   true
                                  // );
                                  formik.setFieldValue("adm", e.target.value);
                                  // setIsRealtime(parseInt(e.target.value) === 1);
                                }}
                                // onChange={formik.handleChange}
                              >
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                              </Form.Select>
                              <ErrorMessage name="adm" component={Texterror} />
                            </Form.Group>

                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>Facility Masters</Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                className="white"
                                name="facility_id"
                                value={`${formik.values.facility_id}`}
                                onChange={(e) => {
                                  formik.setFieldTouched(
                                    "facility_id",
                                    true,
                                    true
                                  );
                                  formik.setFieldValue(
                                    "facility_id",
                                    e.target.value
                                  );
                                  console.log(e.target.value);
                                }}
                                // onChange={formik.handleChange}
                              >
                                {facilityMaster?.map((e) => {
                                  return (
                                    <option value={e?.facility_id}>
                                      {e?.facility_name}
                                    </option>
                                  );
                                })}
                              </Form.Select>
                              <ErrorMessage
                                name="facility_id"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>Fixed or Variable</Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                className="white"
                                name="fixed_or_variable"
                                value={`${formik.values.fixed_or_variable}`}
                                onChange={(e) => {
                                  formik.setFieldTouched(
                                    "fixed_or_variable",
                                    true,
                                    true
                                  );
                                  formik.setFieldValue(
                                    "fixed_or_variable",
                                    e.target.value
                                  );
                                }}
                                onBlur={() => {
                                  formik.setFieldTouched(
                                    "fixed_or_variable",
                                    true,
                                    true
                                  );
                                }}
                                // onChange={formik.handleChange}
                              >
                                <option value="">Select</option>
                                <option value="Fixed">Fixed</option>
                                <option value="Variable">Variable</option>
                              </Form.Select>
                              <ErrorMessage
                                name="fixed_or_variable"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>Is10day</Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                className="white"
                                name="is10day"
                                value={`${formik.values.is10day}`}
                                onChange={(e) => {
                                  formik.setFieldTouched("is10day", true, true);
                                  formik.setFieldValue(
                                    "is10day",
                                    e.target.value
                                  );
                                }}
                                onBlur={() => {
                                  formik.setFieldTouched("is10day", true, true);
                                }}
                                // onChange={formik.handleChange}
                              >
                                <option value="0">No</option>
                                <option value="1">Yes</option>
                              </Form.Select>
                              <ErrorMessage
                                name="is10day"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>Warm Up</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=""
                                name="warm_up"
                                {...formik.getFieldProps("warm_up")}
                              />
                              <ErrorMessage
                                name="warm_up"
                                component={Texterror}
                              />
                            </Form.Group>

                            {isRealtime && (
                              <div>
                                {/* Patient Data */}
                                <div className="row mt-2">
                                  <div class="col-12 grid-margin stretch-card">
                                    <div class="card">
                                      <div class="card-body py-4">
                                        <div className="d-flex justify-content-between">
                                          <div className="d-flex">
                                            <h4 class="card-title mb-4 fs-5">
                                              Patient Data
                                            </h4>
                                          </div>
                                        </div>

                                        <div class="row">
                                          {/* <div className="col-md-12">
                                            <div className="row">
                                              <div className="col-md-12">
                                                <div class="table-responsive">
                                                  <table class="table">
                                                    <thead>
                                                      <tr>
                                                        <th>Show/Hide</th>
                                                        <th>Label</th>
                                                        <th>Mandatory</th>
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                      <tr>
                                                        <td className="sm-transparent-input">
                                                          <input
                                                            type="text"
                                                            class="form-control"
                                                            value="0"
                                                          />
                                                        </td>
                                                        <td className="pe-5">
                                                          <div className="d-flex align-items-center">
                                                            <input
                                                              type="text"
                                                              class="form-control mx-w-340"
                                                              value="Acuity"
                                                            />
                                                            <span className="d-inline-block ms-3">
                                                              {" "}
                                                              <img
                                                                src={Ico1}
                                                                className="w-a cursor-pointer"
                                                              />
                                                            </span>
                                                          </div>
                                                        </td>
                                                        <td className="vertical-aling-middle">
                                                          <Switch
                                                            checkedChildren="Yes"
                                                            unCheckedChildren="No"
                                                            checked={handlePatientSwitchcheck(
                                                              "acuity"
                                                            )}
                                                            className="blue-b"
                                                            onChange={(e) => {
                                                              handlePatientSwitchOnChange(
                                                                "acuity",
                                                                e
                                                              );
                                                            }}
                                                          />
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td className="sm-transparent-input">
                                                          <input
                                                            type="text"
                                                            class="form-control"
                                                            value="0"
                                                          />
                                                        </td>
                                                        <td className="pe-5">
                                                          <div className="d-flex align-items-center">
                                                            <input
                                                              type="text"
                                                              class="form-control mx-w-340"
                                                              value="Acuity"
                                                            />
                                                            <span className="d-inline-block ms-3">
                                                              {" "}
                                                              <img
                                                                src={Ico1}
                                                                className="w-a cursor-pointer"
                                                              />
                                                            </span>
                                                          </div>
                                                        </td>
                                                        <td className="vertical-aling-middle">
                                                          <Switch
                                                            checkedChildren="Yes"
                                                            unCheckedChildren="No"
                                                            checked={handlePatientSwitchcheck(
                                                              "acuity"
                                                            )}
                                                            className="blue-b"
                                                            onChange={(e) => {
                                                              handlePatientSwitchOnChange(
                                                                "acuity",
                                                                e
                                                              );
                                                            }}
                                                          />
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td className="sm-transparent-input">
                                                          <input
                                                            type="text"
                                                            class="form-control"
                                                            value="0"
                                                          />
                                                        </td>
                                                        <td className="pe-5">
                                                          <div className="d-flex align-items-center">
                                                            <input
                                                              type="text"
                                                              class="form-control mx-w-340"
                                                              value="Actual Admits/ Transfers in the previous Shift
"
                                                            />
                                                            <span className="d-inline-block ms-3">
                                                              {" "}
                                                              <img
                                                                src={Ico1}
                                                                className="w-a cursor-pointer"
                                                              />
                                                            </span>
                                                          </div>
                                                        </td>
                                                        <td className="vertical-aling-middle">
                                                          <Switch
                                                            checkedChildren="Yes"
                                                            unCheckedChildren="No"
                                                            checked={handlePatientSwitchcheck(
                                                              "acuity"
                                                            )}
                                                            className="blue-b"
                                                            onChange={(e) => {
                                                              handlePatientSwitchOnChange(
                                                                "acuity",
                                                                e
                                                              );
                                                            }}
                                                          />
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td className="sm-transparent-input">
                                                          <input
                                                            type="text"
                                                            class="form-control"
                                                            value="0"
                                                          />
                                                        </td>
                                                        <td className="pe-5">
                                                          <div className="d-flex align-items-center">
                                                            <input
                                                              type="text"
                                                              class="form-control mx-w-340"
                                                              value="Acuity"
                                                            />
                                                            <span className="d-inline-block ms-3">
                                                              {" "}
                                                              <img
                                                                src={Ico1}
                                                                className="w-a cursor-pointer"
                                                              />
                                                            </span>
                                                          </div>
                                                        </td>
                                                        <td className="vertical-aling-middle">
                                                          <Switch
                                                            checkedChildren="Yes"
                                                            unCheckedChildren="No"
                                                            checked={handlePatientSwitchcheck(
                                                              "acuity"
                                                            )}
                                                            className="blue-b"
                                                            onChange={(e) => {
                                                              handlePatientSwitchOnChange(
                                                                "acuity",
                                                                e
                                                              );
                                                            }}
                                                          />
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                </div>
                                              </div>
                                            </div>
                                          </div> */}
                                          <Form.Group
                                            className="col-md-4 mb-5"
                                            controlId="exampleForm.c_name"
                                          >
                                            <div className="d-flex align-items-center">
                                              <div class="checkbox-group">
                                                <div class="checkbox-item no-wrap d-flex fs-5 lh-1">
                                                  <input
                                                    type="checkbox"
                                                    class="custom-checkbox "
                                                    checked={handlePatientCheck(
                                                      "acuity"
                                                    )}
                                                    onChange={(e) => {
                                                      if (e.target.checked) {
                                                        handlePatientOnChange(
                                                          "acuity",
                                                          1
                                                        );
                                                      } else {
                                                        handlePatientOnChange(
                                                          "acuity",
                                                          0
                                                        );
                                                      }
                                                    }}
                                                  />
                                                  Acuity
                                                </div>
                                              </div>
                                              <div className="d-flex align-items-center">
                                                <span className="ms-4 me-2 grey-color">
                                                  Mandatory
                                                </span>
                                                <Switch
                                                  checkedChildren="Yes"
                                                  unCheckedChildren="No"
                                                  checked={handlePatientSwitchcheck(
                                                    "acuity"
                                                  )}
                                                  className="blue-b"
                                                  onChange={(e) => {
                                                    handlePatientSwitchOnChange(
                                                      "acuity",
                                                      e
                                                    );
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </Form.Group>
                                          <Form.Group
                                            className="col-md-4 mb-5"
                                            controlId="exampleForm.c_name"
                                          >
                                            <div className="d-flex align-items-center">
                                              <div class="checkbox-group">
                                                <div class="checkbox-item no-wrap d-flex fs-5 lh-1">
                                                  <input
                                                    type="checkbox"
                                                    class="custom-checkbox "
                                                    checked={handlePatientCheck(
                                                      "total_patients_two"
                                                    )}
                                                    onChange={(e) => {
                                                      if (e.target.checked) {
                                                        handlePatientOnChange(
                                                          "total_patients_two",
                                                          1
                                                        );
                                                      } else {
                                                        handlePatientOnChange(
                                                          "total_patients_two",
                                                          0
                                                        );
                                                      }
                                                    }}
                                                  />
                                                  Total Patients 2
                                                </div>
                                              </div>
                                              <div className="d-flex align-items-center">
                                                <span className="ms-4 me-2 grey-color">
                                                  Mandatory
                                                </span>
                                                <Switch
                                                  checkedChildren="Yes"
                                                  unCheckedChildren="No"
                                                  checked={handlePatientSwitchcheck(
                                                    "total_patients_two"
                                                  )}
                                                  className="blue-b"
                                                  onChange={(e) => {
                                                    handlePatientSwitchOnChange(
                                                      "total_patients_two",
                                                      e
                                                    );
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </Form.Group>
                                          <Form.Group
                                            className="col-md-4 mb-5"
                                            controlId="exampleForm.c_name"
                                          >
                                            <div className="d-flex align-items-center">
                                              <div class="checkbox-group">
                                                <div class="checkbox-item no-wrap d-flex fs-5 lh-1">
                                                  <input
                                                    type="checkbox"
                                                    class="custom-checkbox "
                                                    checked={handlePatientCheck(
                                                      "total_patients_three"
                                                    )}
                                                    onChange={(e) => {
                                                      if (e.target.checked) {
                                                        handlePatientOnChange(
                                                          "total_patients_three",
                                                          1
                                                        );
                                                      } else {
                                                        handlePatientOnChange(
                                                          "total_patients_three",
                                                          0
                                                        );
                                                      }
                                                    }}
                                                  />
                                                  Total Patients 3
                                                </div>
                                              </div>
                                              <div className="d-flex align-items-center">
                                                <span className="ms-4 me-2 grey-color">
                                                  Mandatory
                                                </span>
                                                <Switch
                                                  checkedChildren="Yes"
                                                  unCheckedChildren="No"
                                                  checked={handlePatientSwitchcheck(
                                                    "total_patients_three"
                                                  )}
                                                  className="blue-b"
                                                  onChange={(e) => {
                                                    handlePatientSwitchOnChange(
                                                      "total_patients_three",
                                                      e
                                                    );
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </Form.Group>
                                          <Form.Group
                                            className="col-md-4 mb-5"
                                            controlId="exampleForm.c_name"
                                          >
                                            <div className="d-flex align-items-center">
                                              <div class="checkbox-group">
                                                <div class="checkbox-item no-wrap d-flex fs-5 lh-1">
                                                  <input
                                                    type="checkbox"
                                                    class="custom-checkbox "
                                                    checked={handlePatientCheck(
                                                      "two_one"
                                                    )}
                                                    onChange={(e) => {
                                                      if (e.target.checked) {
                                                        handlePatientOnChange(
                                                          "two_one",
                                                          1
                                                        );
                                                      } else {
                                                        handlePatientOnChange(
                                                          "two_one",
                                                          0
                                                        );
                                                      }
                                                    }}
                                                  />
                                                  Patient Acuity: (2:1)
                                                </div>
                                              </div>
                                              <div className="d-flex align-items-center">
                                                <span className="ms-4 me-2 grey-color">
                                                  Mandatory
                                                </span>
                                                <Switch
                                                  checkedChildren="Yes"
                                                  unCheckedChildren="No"
                                                  checked={handlePatientSwitchcheck(
                                                    "two_one"
                                                  )}
                                                  className="blue-b"
                                                  onChange={(e) => {
                                                    handlePatientSwitchOnChange(
                                                      "two_one",
                                                      e
                                                    );
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </Form.Group>
                                          <Form.Group
                                            className="col-md-4 mb-5"
                                            controlId="exampleForm.c_name"
                                          >
                                            <div className="d-flex align-items-center">
                                              <div class="checkbox-group">
                                                <div class="checkbox-item no-wrap d-flex fs-5 lh-1">
                                                  <input
                                                    type="checkbox"
                                                    class="custom-checkbox "
                                                    checked={handlePatientCheck(
                                                      "one_one"
                                                    )}
                                                    onChange={(e) => {
                                                      if (e.target.checked) {
                                                        handlePatientOnChange(
                                                          "one_one",
                                                          1
                                                        );
                                                      } else {
                                                        handlePatientOnChange(
                                                          "one_one",
                                                          0
                                                        );
                                                      }
                                                    }}
                                                  />
                                                  Patient Acuity: (1:1)
                                                </div>
                                              </div>
                                              <div className="d-flex align-items-center">
                                                <span className="ms-4 me-2 grey-color">
                                                  Mandatory
                                                </span>
                                                <Switch
                                                  checkedChildren="Yes"
                                                  unCheckedChildren="No"
                                                  checked={handlePatientSwitchcheck(
                                                    "one_one"
                                                  )}
                                                  className="blue-b"
                                                  onChange={(e) => {
                                                    handlePatientSwitchOnChange(
                                                      "one_one",
                                                      e
                                                    );
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </Form.Group>
                                          <Form.Group
                                            className="col-md-4 mb-5"
                                            controlId="exampleForm.c_name"
                                          >
                                            <div className="d-flex align-items-center">
                                              <div class="checkbox-group">
                                                <div class="checkbox-item no-wrap d-flex fs-5 lh-1">
                                                  <input
                                                    type="checkbox"
                                                    class="custom-checkbox "
                                                    checked={handlePatientCheck(
                                                      "one_two"
                                                    )}
                                                    onChange={(e) => {
                                                      if (e.target.checked) {
                                                        handlePatientOnChange(
                                                          "one_two",
                                                          1
                                                        );
                                                      } else {
                                                        handlePatientOnChange(
                                                          "one_two",
                                                          0
                                                        );
                                                      }
                                                    }}
                                                  />
                                                  Patient Acuity: (1:2)
                                                </div>
                                              </div>
                                              <div className="d-flex align-items-center">
                                                <span className="ms-4 me-2 grey-color">
                                                  Mandatory
                                                </span>
                                                <Switch
                                                  checkedChildren="Yes"
                                                  unCheckedChildren="No"
                                                  checked={handlePatientSwitchcheck(
                                                    "one_two"
                                                  )}
                                                  className="blue-b"
                                                  onChange={(e) => {
                                                    handlePatientSwitchOnChange(
                                                      "one_two",
                                                      e
                                                    );
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </Form.Group>
                                          <Form.Group
                                            className="col-md-4 mb-5"
                                            controlId="exampleForm.c_name"
                                          >
                                            <div className="d-flex align-items-center">
                                              <div class="checkbox-group">
                                                <div class="checkbox-item no-wrap d-flex fs-5 lh-1">
                                                  <input
                                                    type="checkbox"
                                                    class="custom-checkbox "
                                                    checked={handlePatientCheck(
                                                      "one_three"
                                                    )}
                                                    onChange={(e) => {
                                                      if (e.target.checked) {
                                                        handlePatientOnChange(
                                                          "one_three",
                                                          1
                                                        );
                                                      } else {
                                                        handlePatientOnChange(
                                                          "one_three",
                                                          0
                                                        );
                                                      }
                                                    }}
                                                  />
                                                  Patient Acuity: (1:3)
                                                </div>
                                              </div>
                                              <div className="d-flex align-items-center">
                                                <span className="ms-4 me-2 grey-color">
                                                  Mandatory
                                                </span>
                                                <Switch
                                                  checkedChildren="Yes"
                                                  unCheckedChildren="No"
                                                  checked={handlePatientSwitchcheck(
                                                    "one_three"
                                                  )}
                                                  className="blue-b"
                                                  onChange={(e) => {
                                                    handlePatientSwitchOnChange(
                                                      "one_three",
                                                      e
                                                    );
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </Form.Group>
                                          <Form.Group
                                            className="col-md-4 mb-5"
                                            controlId="exampleForm.c_name"
                                          >
                                            <div className="d-flex align-items-center">
                                              <div class="checkbox-group">
                                                <div class="checkbox-item no-wrap d-flex fs-5 lh-1">
                                                  <input
                                                    type="checkbox"
                                                    class="custom-checkbox "
                                                    checked={handlePatientCheck(
                                                      "one_four"
                                                    )}
                                                    onChange={(e) => {
                                                      if (e.target.checked) {
                                                        handlePatientOnChange(
                                                          "one_four",
                                                          1
                                                        );
                                                      } else {
                                                        handlePatientOnChange(
                                                          "one_four",
                                                          0
                                                        );
                                                      }
                                                    }}
                                                  />
                                                  Patient Acuity: (1:4)
                                                </div>
                                              </div>
                                              <div className="d-flex align-items-center">
                                                <span className="ms-4 me-2 grey-color">
                                                  Mandatory
                                                </span>
                                                <Switch
                                                  checkedChildren="Yes"
                                                  unCheckedChildren="No"
                                                  checked={handlePatientSwitchcheck(
                                                    "one_four"
                                                  )}
                                                  className="blue-b"
                                                  onChange={(e) => {
                                                    handlePatientSwitchOnChange(
                                                      "one_four",
                                                      e
                                                    );
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </Form.Group>
                                          <Form.Group
                                            className="col-md-4 mb-5"
                                            controlId="exampleForm.c_name"
                                          >
                                            <div className="d-flex align-items-center">
                                              <div class="checkbox-group">
                                                <div class="checkbox-item no-wrap d-flex fs-5 lh-1">
                                                  <input
                                                    type="checkbox"
                                                    class="custom-checkbox "
                                                    checked={handlePatientCheck(
                                                      "one_five"
                                                    )}
                                                    onChange={(e) => {
                                                      if (e.target.checked) {
                                                        handlePatientOnChange(
                                                          "one_five",
                                                          1
                                                        );
                                                      } else {
                                                        handlePatientOnChange(
                                                          "one_five",
                                                          0
                                                        );
                                                      }
                                                    }}
                                                  />
                                                  Patient Acuity: (1:5)
                                                </div>
                                              </div>
                                              <div className="d-flex align-items-center">
                                                <span className="ms-4 me-2 grey-color">
                                                  Mandatory
                                                </span>
                                                <Switch
                                                  checkedChildren="Yes"
                                                  unCheckedChildren="No"
                                                  checked={handlePatientSwitchcheck(
                                                    "one_five"
                                                  )}
                                                  className="blue-b"
                                                  onChange={(e) => {
                                                    handlePatientSwitchOnChange(
                                                      "one_five",
                                                      e
                                                    );
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </Form.Group>
                                          <Form.Group
                                            className="col-md-4 mb-5"
                                            controlId="exampleForm.c_name"
                                          >
                                            <div className="d-flex align-items-center">
                                              <div class="checkbox-group">
                                                <div class="checkbox-item no-wrap d-flex fs-5 lh-1">
                                                  <input
                                                    type="checkbox"
                                                    class="custom-checkbox "
                                                    checked={handlePatientCheck(
                                                      "one_six"
                                                    )}
                                                    onChange={(e) => {
                                                      if (e.target.checked) {
                                                        handlePatientOnChange(
                                                          "one_six",
                                                          1
                                                        );
                                                      } else {
                                                        handlePatientOnChange(
                                                          "one_six",
                                                          0
                                                        );
                                                      }
                                                    }}
                                                  />
                                                  Patient Acuity: (1:6)
                                                </div>
                                              </div>
                                              <div className="d-flex align-items-center">
                                                <span className="ms-4 me-2 grey-color">
                                                  Mandatory
                                                </span>
                                                <Switch
                                                  checkedChildren="Yes"
                                                  unCheckedChildren="No"
                                                  checked={handlePatientSwitchcheck(
                                                    "one_six"
                                                  )}
                                                  className="blue-b"
                                                  onChange={(e) => {
                                                    handlePatientSwitchOnChange(
                                                      "one_six",
                                                      e
                                                    );
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </Form.Group>
                                          <Form.Group
                                            className="col-md-4 mb-5"
                                            controlId="exampleForm.c_name"
                                          >
                                            <div className="d-flex align-items-center">
                                              <div class="checkbox-group">
                                                <div class="checkbox-item no-wrap d-flex fs-5 lh-1">
                                                  <input
                                                    type="checkbox"
                                                    class="custom-checkbox "
                                                    checked={handlePatientCheck(
                                                      "wit"
                                                    )}
                                                    onChange={(e) => {
                                                      if (e.target.checked) {
                                                        handlePatientOnChange(
                                                          "wit",
                                                          1
                                                        );
                                                      } else {
                                                        handlePatientOnChange(
                                                          "wit",
                                                          0
                                                        );
                                                      }
                                                    }}
                                                  />
                                                  WIT
                                                </div>
                                              </div>
                                              <div className="d-flex align-items-center">
                                                <span className="ms-4 me-2 grey-color">
                                                  Mandatory
                                                </span>
                                                <Switch
                                                  checkedChildren="Yes"
                                                  unCheckedChildren="No"
                                                  checked={handlePatientSwitchcheck(
                                                    "wit"
                                                  )}
                                                  className="blue-b"
                                                  onChange={(e) => {
                                                    handlePatientSwitchOnChange(
                                                      "wit",
                                                      e
                                                    );
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </Form.Group>
                                          <Form.Group
                                            className="col-md-4 mb-5"
                                            controlId="exampleForm.c_name"
                                          >
                                            <div className="d-flex align-items-center">
                                              <div class="checkbox-group">
                                                <div class="checkbox-item no-wrap d-flex fs-5 lh-1">
                                                  <input
                                                    type="checkbox"
                                                    class="custom-checkbox "
                                                    checked={handlePatientCheck(
                                                      "wit_rn"
                                                    )}
                                                    onChange={(e) => {
                                                      if (e.target.checked) {
                                                        handlePatientOnChange(
                                                          "wit_rn",
                                                          1
                                                        );
                                                      } else {
                                                        handlePatientOnChange(
                                                          "wit_rn",
                                                          0
                                                        );
                                                      }
                                                    }}
                                                  />
                                                  WIT/RN
                                                </div>
                                              </div>
                                              <div className="d-flex align-items-center">
                                                <span className="ms-4 me-2 grey-color">
                                                  Mandatory
                                                </span>
                                                <Switch
                                                  checkedChildren="Yes"
                                                  unCheckedChildren="No"
                                                  checked={handlePatientSwitchcheck(
                                                    "wit_rn"
                                                  )}
                                                  className="blue-b"
                                                  onChange={(e) => {
                                                    handlePatientSwitchOnChange(
                                                      "wit_rn",
                                                      e
                                                    );
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </Form.Group>
                                          <Form.Group
                                            className="col-md-4 mb-5"
                                            controlId="exampleForm.c_name"
                                          >
                                            <div className="d-flex align-items-center">
                                              <div class="checkbox-group">
                                                <div class="checkbox-item no-wrap d-flex fs-5 lh-1">
                                                  <input
                                                    type="checkbox"
                                                    class="custom-checkbox "
                                                    checked={handlePatientCheck(
                                                      "rn_available_hours"
                                                    )}
                                                    onChange={(e) => {
                                                      if (e.target.checked) {
                                                        handlePatientOnChange(
                                                          "rn_available_hours",
                                                          1
                                                        );
                                                      } else {
                                                        handlePatientOnChange(
                                                          "rn_available_hours",
                                                          0
                                                        );
                                                      }
                                                    }}
                                                  />
                                                  RN Available Hours
                                                </div>
                                              </div>
                                              <div className="d-flex align-items-center">
                                                <span className="ms-4 me-2 grey-color">
                                                  Mandatory
                                                </span>
                                                <Switch
                                                  checkedChildren="Yes"
                                                  unCheckedChildren="No"
                                                  checked={handlePatientSwitchcheck(
                                                    "rn_available_hours"
                                                  )}
                                                  className="blue-b"
                                                  onChange={(e) => {
                                                    handlePatientSwitchOnChange(
                                                      "rn_available_hours",
                                                      e
                                                    );
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </Form.Group>
                                          <Form.Group
                                            className="col-md-4 mb-5"
                                            controlId="exampleForm.c_name"
                                          >
                                            <div className="d-flex align-items-center">
                                              <div class="checkbox-group">
                                                <div class="checkbox-item no-wrap d-flex fs-5 lh-1">
                                                  <input
                                                    type="checkbox"
                                                    class="custom-checkbox "
                                                    checked={handlePatientCheck(
                                                      "rn_variance"
                                                    )}
                                                    onChange={(e) => {
                                                      if (e.target.checked) {
                                                        handlePatientOnChange(
                                                          "rn_variance",
                                                          1
                                                        );
                                                      } else {
                                                        handlePatientOnChange(
                                                          "rn_variance",
                                                          0
                                                        );
                                                      }
                                                    }}
                                                  />
                                                  RN Variance
                                                </div>
                                              </div>
                                              <div className="d-flex align-items-center">
                                                <span className="ms-4 me-2 grey-color">
                                                  Mandatory
                                                </span>
                                                <Switch
                                                  checkedChildren="Yes"
                                                  unCheckedChildren="No"
                                                  checked={handlePatientSwitchcheck(
                                                    "rn_variance"
                                                  )}
                                                  className="blue-b"
                                                  onChange={(e) => {
                                                    handlePatientSwitchOnChange(
                                                      "rn_variance",
                                                      e
                                                    );
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </Form.Group>
                                          <Form.Group
                                            className="col-md-4 mb-5"
                                            controlId="exampleForm.c_name"
                                          >
                                            <div className="d-flex align-items-center">
                                              <div class="checkbox-group">
                                                <div class="checkbox-item no-wrap d-flex fs-5 lh-1">
                                                  <input
                                                    type="checkbox"
                                                    class="custom-checkbox "
                                                    checked={handlePatientCheck(
                                                      "total_patients"
                                                    )}
                                                    onChange={(e) => {
                                                      if (e.target.checked) {
                                                        handlePatientOnChange(
                                                          "total_patients",
                                                          1
                                                        );
                                                      } else {
                                                        handlePatientOnChange(
                                                          "total_patients",
                                                          0
                                                        );
                                                      }
                                                    }}
                                                  />
                                                  Total Patients
                                                </div>
                                              </div>
                                              <div className="d-flex align-items-center">
                                                <span className="ms-4 me-2 grey-color">
                                                  Mandatory
                                                </span>
                                                <Switch
                                                  checkedChildren="Yes"
                                                  unCheckedChildren="No"
                                                  checked={handlePatientSwitchcheck(
                                                    "total_patients"
                                                  )}
                                                  className="blue-b"
                                                  onChange={(e) => {
                                                    handlePatientSwitchOnChange(
                                                      "total_patients",
                                                      e
                                                    );
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </Form.Group>
                                          <Form.Group
                                            className="col-md-4 mb-5"
                                            controlId="exampleForm.c_name"
                                          >
                                            <div className="d-flex align-items-center">
                                              <div class="checkbox-group">
                                                <div class="checkbox-item no-wrap d-flex fs-5 lh-1">
                                                  <input
                                                    type="checkbox"
                                                    class="custom-checkbox "
                                                    checked={handlePatientCheck(
                                                      "observation_patients"
                                                    )}
                                                    onChange={(e) => {
                                                      if (e.target.checked) {
                                                        handlePatientOnChange(
                                                          "observation_patients",
                                                          1
                                                        );
                                                      } else {
                                                        handlePatientOnChange(
                                                          "observation_patients",
                                                          0
                                                        );
                                                      }
                                                    }}
                                                  />
                                                  Observation Patients
                                                </div>
                                              </div>
                                              <div className="d-flex align-items-center">
                                                <span className="ms-4 me-2 grey-color">
                                                  Mandatory
                                                </span>
                                                <Switch
                                                  checkedChildren="Yes"
                                                  unCheckedChildren="No"
                                                  checked={handlePatientSwitchcheck(
                                                    "observation_patients"
                                                  )}
                                                  className="blue-b"
                                                  onChange={(e) => {
                                                    handlePatientSwitchOnChange(
                                                      "observation_patients",
                                                      e
                                                    );
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </Form.Group>
                                          <Form.Group
                                            className="col-md-4 mb-5"
                                            controlId="exampleForm.c_name"
                                          >
                                            <div className="d-flex align-items-center">
                                              <div class="checkbox-group">
                                                <div class="checkbox-item no-wrap d-flex fs-5 lh-1">
                                                  <input
                                                    type="checkbox"
                                                    class="custom-checkbox "
                                                    checked={handlePatientCheck(
                                                      "inpatient_boarders"
                                                    )}
                                                    onChange={(e) => {
                                                      if (e.target.checked) {
                                                        handlePatientOnChange(
                                                          "inpatient_boarders",
                                                          1
                                                        );
                                                      } else {
                                                        handlePatientOnChange(
                                                          "inpatient_boarders",
                                                          0
                                                        );
                                                      }
                                                    }}
                                                  />
                                                  Inpatient Boarders
                                                </div>
                                              </div>
                                              <div className="d-flex align-items-center">
                                                <span className="ms-4 me-2 grey-color">
                                                  Mandatory
                                                </span>
                                                <Switch
                                                  checkedChildren="Yes"
                                                  unCheckedChildren="No"
                                                  checked={handlePatientSwitchcheck(
                                                    "inpatient_boarders"
                                                  )}
                                                  className="blue-b"
                                                  onChange={(e) => {
                                                    handlePatientSwitchOnChange(
                                                      "inpatient_boarders",
                                                      e
                                                    );
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </Form.Group>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                {/* Staffing */}
                                <div className="row mt-2">
                                  <div class="col-12 grid-margin stretch-card">
                                    <div class="card">
                                      <div class="card-body py-4">
                                        <div className="d-flex justify-content-between">
                                          <div className="d-flex">
                                            <h4 class="card-title fs-5">
                                              Staffing
                                            </h4>
                                          </div>
                                        </div>

                                        <div class="row">
                                          {/* New Design */}
                                          {/* <div className="col-md-12">
                                            <div className="row">
                                              <div className="col-md-12">
                                                <div class="table-responsive">
                                                  <table class="table">
                                                    <thead>
                                                      <tr>
                                                        <th>Show/Hide</th>
                                                        <th>Label</th>
                                                        <th>Mandatory</th>
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                      <tr>
                                                        <td className="sm-transparent-input">
                                                          <input
                                                            type="text"
                                                            class="form-control"
                                                            value="0"
                                                          />
                                                        </td>
                                                        <td className="pe-5">
                                                          <div className="d-flex align-items-center">
                                                            <input
                                                              type="text"
                                                              class="form-control mx-w-340"
                                                              value="Acuity"
                                                            />
                                                            <span className="d-inline-block ms-3">
                                                              {" "}
                                                              <img
                                                                src={Ico1}
                                                                className="w-a cursor-pointer"
                                                              />
                                                            </span>
                                                          </div>
                                                        </td>
                                                        <td className="vertical-aling-middle">
                                                          <Switch
                                                            checkedChildren="Yes"
                                                            unCheckedChildren="No"
                                                            checked={handlePatientSwitchcheck(
                                                              "acuity"
                                                            )}
                                                            className="blue-b"
                                                            onChange={(e) => {
                                                              handlePatientSwitchOnChange(
                                                                "acuity",
                                                                e
                                                              );
                                                            }}
                                                          />
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td className="sm-transparent-input">
                                                          <input
                                                            type="text"
                                                            class="form-control"
                                                            value="0"
                                                          />
                                                        </td>
                                                        <td className="pe-5">
                                                          <div className="d-flex align-items-center">
                                                            <input
                                                              type="text"
                                                              class="form-control mx-w-340"
                                                              value="Acuity"
                                                            />
                                                            <span className="d-inline-block ms-3">
                                                              {" "}
                                                              <img
                                                                src={Ico1}
                                                                className="w-a cursor-pointer"
                                                              />
                                                            </span>
                                                          </div>
                                                        </td>
                                                        <td className="vertical-aling-middle">
                                                          <Switch
                                                            checkedChildren="Yes"
                                                            unCheckedChildren="No"
                                                            checked={handlePatientSwitchcheck(
                                                              "acuity"
                                                            )}
                                                            className="blue-b"
                                                            onChange={(e) => {
                                                              handlePatientSwitchOnChange(
                                                                "acuity",
                                                                e
                                                              );
                                                            }}
                                                          />
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td className="sm-transparent-input">
                                                          <input
                                                            type="text"
                                                            class="form-control"
                                                            value="0"
                                                          />
                                                        </td>
                                                        <td className="pe-5">
                                                          <div className="d-flex align-items-center">
                                                            <input
                                                              type="text"
                                                              class="form-control mx-w-340"
                                                              value="Actual Admits/ Transfers in the previous Shift
"
                                                            />
                                                            <span className="d-inline-block ms-3">
                                                              {" "}
                                                              <img
                                                                src={Ico1}
                                                                className="w-a cursor-pointer"
                                                              />
                                                            </span>
                                                          </div>
                                                        </td>
                                                        <td className="vertical-aling-middle">
                                                          <Switch
                                                            checkedChildren="Yes"
                                                            unCheckedChildren="No"
                                                            checked={handlePatientSwitchcheck(
                                                              "acuity"
                                                            )}
                                                            className="blue-b"
                                                            onChange={(e) => {
                                                              handlePatientSwitchOnChange(
                                                                "acuity",
                                                                e
                                                              );
                                                            }}
                                                          />
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td className="sm-transparent-input">
                                                          <input
                                                            type="text"
                                                            class="form-control"
                                                            value="0"
                                                          />
                                                        </td>
                                                        <td className="pe-5">
                                                          <div className="d-flex align-items-center">
                                                            <input
                                                              type="text"
                                                              class="form-control mx-w-340"
                                                              value="Acuity"
                                                            />
                                                            <span className="d-inline-block ms-3">
                                                              {" "}
                                                              <img
                                                                src={Ico1}
                                                                className="w-a cursor-pointer"
                                                              />
                                                            </span>
                                                          </div>
                                                        </td>
                                                        <td className="vertical-aling-middle">
                                                          <Switch
                                                            checkedChildren="Yes"
                                                            unCheckedChildren="No"
                                                            checked={handlePatientSwitchcheck(
                                                              "acuity"
                                                            )}
                                                            className="blue-b"
                                                            onChange={(e) => {
                                                              handlePatientSwitchOnChange(
                                                                "acuity",
                                                                e
                                                              );
                                                            }}
                                                          />
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                </div>
                                              </div>
                                            </div>
                                          </div> */}
                                          <Form.Group
                                            className="col-md-4 mb-5"
                                            controlId="exampleForm.c_name"
                                          >
                                            <div className="d-flex align-items-center">
                                              <div class="checkbox-group">
                                                <div class="checkbox-item no-wrap d-flex fs-5 lh-1">
                                                  <input
                                                    type="checkbox"
                                                    class="custom-checkbox "
                                                    checked={handleStaffingCheck(
                                                      "charge"
                                                    )}
                                                    onChange={(e) => {
                                                      if (e.target.checked) {
                                                        handleStaffingOnChange(
                                                          "charge",
                                                          1
                                                        );
                                                      } else {
                                                        handleStaffingOnChange(
                                                          "charge",
                                                          0
                                                        );
                                                      }
                                                    }}
                                                  />
                                                  Charge
                                                </div>
                                              </div>
                                              <div className="d-flex align-items-center">
                                                <span className="ms-4 me-2 grey-color">
                                                  Mandatory
                                                </span>
                                                <Switch
                                                  checkedChildren="Yes"
                                                  unCheckedChildren="No"
                                                  checked={handleStaffingSwitchcheck(
                                                    "charge"
                                                  )}
                                                  className="blue-b"
                                                  onChange={(e) => {
                                                    handleStaffingSwitchOnChange(
                                                      "charge",
                                                      e
                                                    );
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </Form.Group>
                                          <Form.Group
                                            className="col-md-4 mb-5"
                                            controlId="exampleForm.c_name"
                                          >
                                            <div className="d-flex align-items-center">
                                              <div class="checkbox-group">
                                                <div class="checkbox-item no-wrap d-flex fs-5 lh-1">
                                                  <input
                                                    type="checkbox"
                                                    class="custom-checkbox "
                                                    checked={handleStaffingCheck(
                                                      "cna"
                                                    )}
                                                    onChange={(e) => {
                                                      if (e.target.checked) {
                                                        handleStaffingOnChange(
                                                          "cna",
                                                          1
                                                        );
                                                      } else {
                                                        handleStaffingOnChange(
                                                          "cna",
                                                          0
                                                        );
                                                      }
                                                    }}
                                                  />
                                                  CNA
                                                </div>
                                              </div>
                                              <div className="d-flex align-items-center">
                                                <span className="ms-4 me-2 grey-color">
                                                  Mandatory
                                                </span>
                                                <Switch
                                                  checkedChildren="Yes"
                                                  unCheckedChildren="No"
                                                  checked={handleStaffingSwitchcheck(
                                                    "cna"
                                                  )}
                                                  className="blue-b"
                                                  onChange={(e) => {
                                                    handleStaffingSwitchOnChange(
                                                      "cna",
                                                      e
                                                    );
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </Form.Group>
                                          <Form.Group
                                            className="col-md-4 mb-5"
                                            controlId="exampleForm.c_name"
                                          >
                                            <div className="d-flex align-items-center">
                                              <div class="checkbox-group">
                                                <div class="checkbox-item no-wrap d-flex fs-5 lh-1">
                                                  <input
                                                    type="checkbox"
                                                    class="custom-checkbox "
                                                    checked={handleStaffingCheck(
                                                      "orientation"
                                                    )}
                                                    onChange={(e) => {
                                                      if (e.target.checked) {
                                                        handleStaffingOnChange(
                                                          "orientation",
                                                          1
                                                        );
                                                      } else {
                                                        handleStaffingOnChange(
                                                          "orientation",
                                                          0
                                                        );
                                                      }
                                                    }}
                                                  />
                                                  Orientation
                                                </div>
                                              </div>
                                              <div className="d-flex align-items-center">
                                                <span className="ms-4 me-2 grey-color">
                                                  Mandatory
                                                </span>
                                                <Switch
                                                  checkedChildren="Yes"
                                                  unCheckedChildren="No"
                                                  checked={handleStaffingSwitchcheck(
                                                    "orientation"
                                                  )}
                                                  className="blue-b"
                                                  onChange={(e) => {
                                                    handleStaffingSwitchOnChange(
                                                      "orientation",
                                                      e
                                                    );
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </Form.Group>
                                          <Form.Group
                                            className="col-md-4 mb-5"
                                            controlId="exampleForm.c_name"
                                          >
                                            <div className="d-flex align-items-center">
                                              <div class="checkbox-group">
                                                <div class="checkbox-item no-wrap d-flex fs-5 lh-1">
                                                  <input
                                                    type="checkbox"
                                                    class="custom-checkbox "
                                                    checked={handleStaffingCheck(
                                                      "nurses"
                                                    )}
                                                    onChange={(e) => {
                                                      if (e.target.checked) {
                                                        handleStaffingOnChange(
                                                          "nurses",
                                                          1
                                                        );
                                                      } else {
                                                        handleStaffingOnChange(
                                                          "nurses",
                                                          0
                                                        );
                                                      }
                                                    }}
                                                  />
                                                  RN
                                                </div>
                                              </div>
                                              <div className="d-flex align-items-center">
                                                <span className="ms-4 me-2 grey-color">
                                                  Mandatory
                                                </span>
                                                <Switch
                                                  checkedChildren="Yes"
                                                  unCheckedChildren="No"
                                                  checked={handleStaffingSwitchcheck(
                                                    "nurses"
                                                  )}
                                                  className="blue-b"
                                                  onChange={(e) => {
                                                    handleStaffingSwitchOnChange(
                                                      "nurses",
                                                      e
                                                    );
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </Form.Group>
                                          <Form.Group
                                            className="col-md-4 mb-5"
                                            controlId="exampleForm.c_name"
                                          >
                                            <div className="d-flex align-items-center">
                                              <div class="checkbox-group">
                                                <div class="checkbox-item no-wrap d-flex fs-5 lh-1">
                                                  <input
                                                    type="checkbox"
                                                    class="custom-checkbox "
                                                    checked={handleStaffingCheck(
                                                      "res_brk_rn"
                                                    )}
                                                    onChange={(e) => {
                                                      if (e.target.checked) {
                                                        handleStaffingOnChange(
                                                          "res_brk_rn",
                                                          1
                                                        );
                                                      } else {
                                                        handleStaffingOnChange(
                                                          "res_brk_rn",
                                                          0
                                                        );
                                                      }
                                                    }}
                                                  />
                                                  Res/Brk RN
                                                </div>
                                              </div>
                                              <div className="d-flex align-items-center">
                                                <span className="ms-4 me-2 grey-color">
                                                  Mandatory
                                                </span>
                                                <Switch
                                                  checkedChildren="Yes"
                                                  unCheckedChildren="No"
                                                  checked={handleStaffingSwitchcheck(
                                                    "res_brk_rn"
                                                  )}
                                                  className="blue-b"
                                                  onChange={(e) => {
                                                    handleStaffingSwitchOnChange(
                                                      "res_brk_rn",
                                                      e
                                                    );
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </Form.Group>
                                          <Form.Group
                                            className="col-md-4 mb-5"
                                            controlId="exampleForm.c_name"
                                          >
                                            <div className="d-flex align-items-center">
                                              <div class="checkbox-group">
                                                <div class="checkbox-item no-wrap d-flex fs-5 lh-1">
                                                  <input
                                                    type="checkbox"
                                                    class="custom-checkbox "
                                                    checked={handleStaffingCheck(
                                                      "unit_sec"
                                                    )}
                                                    onChange={(e) => {
                                                      if (e.target.checked) {
                                                        handleStaffingOnChange(
                                                          "unit_sec",
                                                          1
                                                        );
                                                      } else {
                                                        handleStaffingOnChange(
                                                          "unit_sec",
                                                          0
                                                        );
                                                      }
                                                    }}
                                                  />
                                                  Unit Sec
                                                </div>
                                              </div>
                                              <div className="d-flex align-items-center">
                                                <span className="ms-4 me-2 grey-color">
                                                  Mandatory
                                                </span>
                                                <Switch
                                                  checkedChildren="Yes"
                                                  unCheckedChildren="No"
                                                  checked={handleStaffingSwitchcheck(
                                                    "unit_sec"
                                                  )}
                                                  className="blue-b"
                                                  onChange={(e) => {
                                                    handleStaffingSwitchOnChange(
                                                      "unit_sec",
                                                      e
                                                    );
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </Form.Group>
                                          <Form.Group
                                            className="col-md-4 mb-5"
                                            controlId="exampleForm.c_name"
                                          >
                                            <div className="d-flex align-items-center">
                                              <div class="checkbox-group">
                                                <div class="checkbox-item no-wrap d-flex fs-5 lh-1">
                                                  <input
                                                    type="checkbox"
                                                    class="custom-checkbox "
                                                    checked={handleStaffingCheck(
                                                      "sitter"
                                                    )}
                                                    onChange={(e) => {
                                                      if (e.target.checked) {
                                                        handleStaffingOnChange(
                                                          "sitter",
                                                          1
                                                        );
                                                      } else {
                                                        handleStaffingOnChange(
                                                          "sitter",
                                                          0
                                                        );
                                                      }
                                                    }}
                                                  />
                                                  Sitter
                                                </div>
                                              </div>
                                              <div className="d-flex align-items-center">
                                                <span className="ms-4 me-2 grey-color">
                                                  Mandatory
                                                </span>
                                                <Switch
                                                  checkedChildren="Yes"
                                                  unCheckedChildren="No"
                                                  checked={handleStaffingSwitchcheck(
                                                    "sitter"
                                                  )}
                                                  className="blue-b"
                                                  onChange={(e) => {
                                                    handleStaffingSwitchOnChange(
                                                      "sitter",
                                                      e
                                                    );
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </Form.Group>
                                          <Form.Group
                                            className="col-md-4 mb-5"
                                            controlId="exampleForm.c_name"
                                          >
                                            <div className="d-flex align-items-center">
                                              <div class="checkbox-group">
                                                <div class="checkbox-item no-wrap d-flex fs-5 lh-1">
                                                  <input
                                                    type="checkbox"
                                                    class="custom-checkbox "
                                                    checked={handleStaffingCheck(
                                                      "techs_cna"
                                                    )}
                                                    onChange={(e) => {
                                                      if (e.target.checked) {
                                                        handleStaffingOnChange(
                                                          "techs_cna",
                                                          1
                                                        );
                                                      } else {
                                                        handleStaffingOnChange(
                                                          "techs_cna",
                                                          0
                                                        );
                                                      }
                                                    }}
                                                  />
                                                  Techs/Cna
                                                </div>
                                              </div>
                                              <div className="d-flex align-items-center">
                                                <span className="ms-4 me-2 grey-color">
                                                  Mandatory
                                                </span>
                                                <Switch
                                                  checkedChildren="Yes"
                                                  unCheckedChildren="No"
                                                  checked={handleStaffingSwitchcheck(
                                                    "techs_cna"
                                                  )}
                                                  className="blue-b"
                                                  onChange={(e) => {
                                                    handleStaffingSwitchOnChange(
                                                      "techs_cna",
                                                      e
                                                    );
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </Form.Group>
                                          <Form.Group
                                            className="col-md-4 mb-5"
                                            controlId="exampleForm.c_name"
                                          >
                                            <div className="d-flex align-items-center">
                                              <div class="checkbox-group">
                                                <div class="checkbox-item no-wrap d-flex fs-5 lh-1">
                                                  <input
                                                    type="checkbox"
                                                    class="custom-checkbox "
                                                    checked={handleStaffingCheck(
                                                      "psa_si"
                                                    )}
                                                    onChange={(e) => {
                                                      if (e.target.checked) {
                                                        handleStaffingOnChange(
                                                          "psa_si",
                                                          1
                                                        );
                                                      } else {
                                                        handleStaffingOnChange(
                                                          "psa_si",
                                                          0
                                                        );
                                                      }
                                                    }}
                                                  />
                                                  PSA - S.I.
                                                </div>
                                              </div>
                                              <div className="d-flex align-items-center">
                                                <span className="ms-4 me-2 grey-color">
                                                  Mandatory
                                                </span>
                                                <Switch
                                                  checkedChildren="Yes"
                                                  unCheckedChildren="No"
                                                  checked={handleStaffingSwitchcheck(
                                                    "psa_si"
                                                  )}
                                                  className="blue-b"
                                                  onChange={(e) => {
                                                    handleStaffingSwitchOnChange(
                                                      "psa_si",
                                                      e
                                                    );
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </Form.Group>
                                          <Form.Group
                                            className="col-md-4 mb-5"
                                            controlId="exampleForm.c_name"
                                          >
                                            <div className="d-flex align-items-center">
                                              <div class="checkbox-group">
                                                <div class="checkbox-item no-wrap d-flex fs-5 lh-1">
                                                  <input
                                                    type="checkbox"
                                                    class="custom-checkbox "
                                                    checked={handleStaffingCheck(
                                                      "psa_medical"
                                                    )}
                                                    onChange={(e) => {
                                                      if (e.target.checked) {
                                                        handleStaffingOnChange(
                                                          "psa_medical",
                                                          1
                                                        );
                                                      } else {
                                                        handleStaffingOnChange(
                                                          "psa_medical",
                                                          0
                                                        );
                                                      }
                                                    }}
                                                  />
                                                  PSA - Medical
                                                </div>
                                              </div>
                                              <div className="d-flex align-items-center">
                                                <span className="ms-4 me-2 grey-color">
                                                  Mandatory
                                                </span>
                                                <Switch
                                                  checkedChildren="Yes"
                                                  unCheckedChildren="No"
                                                  checked={handleStaffingSwitchcheck(
                                                    "psa_medical"
                                                  )}
                                                  className="blue-b"
                                                  onChange={(e) => {
                                                    handleStaffingSwitchOnChange(
                                                      "psa_medical",
                                                      e
                                                    );
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </Form.Group>
                                          <Form.Group
                                            className="col-md-4 mb-5"
                                            controlId="exampleForm.c_name"
                                          >
                                            <div className="d-flex align-items-center">
                                              <div class="checkbox-group">
                                                <div class="checkbox-item no-wrap d-flex fs-5 lh-1">
                                                  <input
                                                    type="checkbox"
                                                    class="custom-checkbox "
                                                    checked={handleStaffingCheck(
                                                      "blt"
                                                    )}
                                                    onChange={(e) => {
                                                      if (e.target.checked) {
                                                        handleStaffingOnChange(
                                                          "blt",
                                                          1
                                                        );
                                                      } else {
                                                        handleStaffingOnChange(
                                                          "blt",
                                                          0
                                                        );
                                                      }
                                                    }}
                                                  />
                                                  BLT
                                                </div>
                                              </div>
                                              <div className="d-flex align-items-center">
                                                <span className="ms-4 me-2 grey-color">
                                                  Mandatory
                                                </span>
                                                <Switch
                                                  checkedChildren="Yes"
                                                  unCheckedChildren="No"
                                                  checked={handleStaffingSwitchcheck(
                                                    "blt"
                                                  )}
                                                  className="blue-b"
                                                  onChange={(e) => {
                                                    handleStaffingSwitchOnChange(
                                                      "blt",
                                                      e
                                                    );
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </Form.Group>
                                          <Form.Group
                                            className="col-md-4 mb-5"
                                            controlId="exampleForm.c_name"
                                          >
                                            <div className="d-flex align-items-center">
                                              <div class="checkbox-group">
                                                <div class="checkbox-item no-wrap d-flex fs-5 lh-1">
                                                  <input
                                                    type="checkbox"
                                                    class="custom-checkbox "
                                                    checked={handleStaffingCheck(
                                                      "other"
                                                    )}
                                                    onChange={(e) => {
                                                      if (e.target.checked) {
                                                        handleStaffingOnChange(
                                                          "other",
                                                          1
                                                        );
                                                      } else {
                                                        handleStaffingOnChange(
                                                          "other",
                                                          0
                                                        );
                                                      }
                                                    }}
                                                  />
                                                  Other
                                                </div>
                                              </div>
                                              <div className="d-flex align-items-center">
                                                <span className="ms-4 me-2 grey-color">
                                                  Mandatory
                                                </span>
                                                <Switch
                                                  checkedChildren="Yes"
                                                  unCheckedChildren="No"
                                                  checked={handleStaffingSwitchcheck(
                                                    "other"
                                                  )}
                                                  className="blue-b"
                                                  onChange={(e) => {
                                                    handleStaffingSwitchOnChange(
                                                      "other",
                                                      e
                                                    );
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </Form.Group>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                {/* Additional Data Tracking */}
                                <div className="row mt-2">
                                  <div class="col-12 grid-margin stretch-card">
                                    <div class="card">
                                      <div class="card-body py-4">
                                        <div className="d-flex justify-content-between">
                                          <div className="d-flex">
                                            <h4 class="card-title fs-5">
                                              Additional Data Tracking
                                            </h4>
                                          </div>
                                        </div>
                                        <div class="row">
                                          {/*New Design */}
                                          {/* <div className="col-md-12">
                                            <div className="row">
                                              <div className="col-md-12">
                                                <div class="table-responsive">
                                                  <table class="table">
                                                    <thead>
                                                      <tr>
                                                        <th>Show/Hide</th>
                                                        <th>Label</th>
                                                        <th>Mandatory</th>
                                                        <th>Display Graph</th>
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                      <tr>
                                                        <td className="sm-transparent-input">
                                                          <input
                                                            type="text"
                                                            class="form-control"
                                                            value="0"
                                                          />
                                                        </td>
                                                        <td className="pe-5">
                                                          <div className="d-flex align-items-center">
                                                            <input
                                                              type="text"
                                                              class="form-control mx-w-340"
                                                              value="Acuity"
                                                            />
                                                            <span className="d-inline-block ms-3">
                                                              {" "}
                                                              <img
                                                                src={Ico1}
                                                                className="w-a cursor-pointer"
                                                              />
                                                            </span>
                                                          </div>
                                                        </td>
                                                        <td className="vertical-aling-middle">
                                                          <Switch
                                                            checkedChildren="Yes"
                                                            unCheckedChildren="No"
                                                            checked={handlePatientSwitchcheck(
                                                              "acuity"
                                                            )}
                                                            className="blue-b"
                                                            onChange={(e) => {
                                                              handlePatientSwitchOnChange(
                                                                "acuity",
                                                                e
                                                              );
                                                            }}
                                                          />
                                                        </td>
                                                        <td className="sm-transparent-input">
                                                          <input
                                                            type="text"
                                                            class="form-control"
                                                            value="0"
                                                          />
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td className="sm-transparent-input">
                                                          <input
                                                            type="text"
                                                            class="form-control"
                                                            value="0"
                                                          />
                                                        </td>
                                                        <td className="pe-5">
                                                          <div className="d-flex align-items-center">
                                                            <input
                                                              type="text"
                                                              class="form-control mx-w-340"
                                                              value="Actual Admits/ Transfers in the previous Shift
"
                                                            />
                                                            <span className="d-inline-block ms-3">
                                                              {" "}
                                                              <img
                                                                src={Ico1}
                                                                className="w-a cursor-pointer"
                                                              />
                                                            </span>
                                                          </div>
                                                        </td>
                                                        <td className="vertical-aling-middle">
                                                          <Switch
                                                            checkedChildren="Yes"
                                                            unCheckedChildren="No"
                                                            checked={handlePatientSwitchcheck(
                                                              "acuity"
                                                            )}
                                                            className="blue-b"
                                                            onChange={(e) => {
                                                              handlePatientSwitchOnChange(
                                                                "acuity",
                                                                e
                                                              );
                                                            }}
                                                          />
                                                        </td>
                                                        <td className="sm-transparent-input">
                                                          <input
                                                            type="text"
                                                            class="form-control"
                                                            value="0"
                                                          />
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                </div>
                                              </div>
                                            </div>
                                          </div> */}
                                          <Form.Group
                                            className="col-md-6 mb-5"
                                            controlId="exampleForm.c_name"
                                          >
                                            <div className="d-flex align-items-center">
                                              <div class="checkbox-group">
                                                <div class="checkbox-item no-wrap d-flex fs-5 lh-1">
                                                  <input
                                                    type="checkbox"
                                                    class="custom-checkbox "
                                                    checked={handleAddDataCheck(
                                                      "ant_admit"
                                                    )}
                                                    onChange={(e) => {
                                                      if (e.target.checked) {
                                                        handleAddDataOnChange(
                                                          "ant_admit",
                                                          1
                                                        );
                                                      } else {
                                                        handleAddDataOnChange(
                                                          "ant_admit",
                                                          0
                                                        );
                                                      }
                                                    }}
                                                  />
                                                  Anticipated Admits/ Transfers
                                                  in this Shift
                                                </div>
                                              </div>
                                              <div className="d-flex align-items-center">
                                                <span className="ms-4 me-2 grey-color">
                                                  Mandatory
                                                </span>
                                                <Switch
                                                  checkedChildren="Yes"
                                                  unCheckedChildren="No"
                                                  checked={handleAddDataSwitchcheck(
                                                    "ant_admit"
                                                  )}
                                                  className="blue-b"
                                                  onChange={(e) => {
                                                    handleAddDataSwitchOnChange(
                                                      "ant_admit",
                                                      e
                                                    );
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </Form.Group>
                                          <Form.Group
                                            className="col-md-6 mb-5"
                                            controlId="exampleForm.c_name"
                                          >
                                            <div className="d-flex align-items-center">
                                              <div class="checkbox-group">
                                                <div class="checkbox-item no-wrap d-flex fs-5 lh-1">
                                                  <input
                                                    type="checkbox"
                                                    class="custom-checkbox "
                                                    checked={handleAddDataCheck(
                                                      "actual_admit"
                                                    )}
                                                    onChange={(e) => {
                                                      if (e.target.checked) {
                                                        handleAddDataOnChange(
                                                          "actual_admit",
                                                          1
                                                        );
                                                      } else {
                                                        handleAddDataOnChange(
                                                          "actual_admit",
                                                          0
                                                        );
                                                      }
                                                    }}
                                                  />
                                                  Actual Admits/ Transfers in
                                                  the previous Shift
                                                </div>
                                              </div>
                                              <div className="d-flex align-items-center">
                                                <span className="ms-4 me-2 grey-color">
                                                  Mandatory
                                                </span>
                                                <Switch
                                                  checkedChildren="Yes"
                                                  unCheckedChildren="No"
                                                  checked={handleAddDataSwitchcheck(
                                                    "actual_admit"
                                                  )}
                                                  className="blue-b"
                                                  onChange={(e) => {
                                                    handleAddDataSwitchOnChange(
                                                      "actual_admit",
                                                      e
                                                    );
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </Form.Group>
                                          <Form.Group
                                            className="col-md-6 mb-5"
                                            controlId="exampleForm.c_name"
                                          >
                                            <div className="d-flex align-items-center">
                                              <div class="checkbox-group">
                                                <div class="checkbox-item no-wrap d-flex fs-5 lh-1">
                                                  <input
                                                    type="checkbox"
                                                    class="custom-checkbox "
                                                    checked={handleAddDataCheck(
                                                      "telemetry"
                                                    )}
                                                    onChange={(e) => {
                                                      if (e.target.checked) {
                                                        handleAddDataOnChange(
                                                          "telemetry",
                                                          1
                                                        );
                                                      } else {
                                                        handleAddDataOnChange(
                                                          "telemetry",
                                                          0
                                                        );
                                                      }
                                                    }}
                                                  />
                                                  Telemetry Patients
                                                </div>
                                              </div>
                                              <div className="d-flex align-items-center">
                                                <span className="ms-4 me-2 grey-color">
                                                  Mandatory
                                                </span>
                                                <Switch
                                                  checkedChildren="Yes"
                                                  unCheckedChildren="No"
                                                  checked={handleAddDataSwitchcheck(
                                                    "telemetry"
                                                  )}
                                                  className="blue-b"
                                                  onChange={(e) => {
                                                    handleAddDataSwitchOnChange(
                                                      "telemetry",
                                                      e
                                                    );
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </Form.Group>
                                          <Form.Group
                                            className="col-md-6 mb-5"
                                            controlId="exampleForm.c_name"
                                          >
                                            <div className="d-flex align-items-center">
                                              <div class="checkbox-group">
                                                <div class="checkbox-item no-wrap d-flex fs-5 lh-1">
                                                  <input
                                                    type="checkbox"
                                                    class="custom-checkbox "
                                                    checked={handleAddDataCheck(
                                                      "vaso_active_drips"
                                                    )}
                                                    onChange={(e) => {
                                                      if (e.target.checked) {
                                                        handleAddDataOnChange(
                                                          "vaso_active_drips",
                                                          1
                                                        );
                                                      } else {
                                                        handleAddDataOnChange(
                                                          "vaso_active_drips",
                                                          0
                                                        );
                                                      }
                                                    }}
                                                  />
                                                  Vaso-Active Drips
                                                </div>
                                              </div>
                                              <div className="d-flex align-items-center">
                                                <span className="ms-4 me-2 grey-color">
                                                  Mandatory
                                                </span>
                                                <Switch
                                                  checkedChildren="Yes"
                                                  unCheckedChildren="No"
                                                  checked={handleAddDataSwitchcheck(
                                                    "vaso_active_drips"
                                                  )}
                                                  className="blue-b"
                                                  onChange={(e) => {
                                                    handleAddDataSwitchOnChange(
                                                      "vaso_active_drips",
                                                      e
                                                    );
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </Form.Group>
                                          <Form.Group
                                            className="col-md-6 mb-5"
                                            controlId="exampleForm.c_name"
                                          >
                                            <div className="d-flex align-items-center">
                                              <div class="checkbox-group">
                                                <div class="checkbox-item no-wrap d-flex fs-5 lh-1">
                                                  <input
                                                    type="checkbox"
                                                    class="custom-checkbox "
                                                    checked={handleAddDataCheck(
                                                      "isolation_patients"
                                                    )}
                                                    onChange={(e) => {
                                                      if (e.target.checked) {
                                                        handleAddDataOnChange(
                                                          "isolation_patients",
                                                          1
                                                        );
                                                      } else {
                                                        handleAddDataOnChange(
                                                          "isolation_patients",
                                                          0
                                                        );
                                                      }
                                                    }}
                                                  />
                                                  Isolation Patients
                                                </div>
                                              </div>
                                              <div className="d-flex align-items-center">
                                                <span className="ms-4 me-2 grey-color">
                                                  Mandatory
                                                </span>
                                                <Switch
                                                  checkedChildren="Yes"
                                                  unCheckedChildren="No"
                                                  checked={handleAddDataSwitchcheck(
                                                    "isolation_patients"
                                                  )}
                                                  className="blue-b"
                                                  onChange={(e) => {
                                                    handleAddDataSwitchOnChange(
                                                      "isolation_patients",
                                                      e
                                                    );
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </Form.Group>
                                          <Form.Group
                                            className="col-md-6 mb-5"
                                            controlId="exampleForm.c_name"
                                          >
                                            <div className="d-flex align-items-center">
                                              <div class="checkbox-group">
                                                <div class="checkbox-item no-wrap d-flex fs-5 lh-1">
                                                  <input
                                                    type="checkbox"
                                                    class="custom-checkbox "
                                                    checked={handleAddDataCheck(
                                                      "comment"
                                                    )}
                                                    onChange={(e) => {
                                                      if (e.target.checked) {
                                                        handleAddDataOnChange(
                                                          "comment",
                                                          1
                                                        );
                                                      } else {
                                                        handleAddDataOnChange(
                                                          "comment",
                                                          0
                                                        );
                                                      }
                                                    }}
                                                  />
                                                  Comments / Barriers / Action
                                                  Plan
                                                </div>
                                              </div>
                                              <div className="d-flex align-items-center">
                                                <span className="ms-4 me-2 grey-color">
                                                  Mandatory
                                                </span>
                                                <Switch
                                                  checkedChildren="Yes"
                                                  unCheckedChildren="No"
                                                  checked={handleAddDataSwitchcheck(
                                                    "comment"
                                                  )}
                                                  className="blue-b"
                                                  onChange={(e) => {
                                                    handleAddDataSwitchOnChange(
                                                      "comment",
                                                      e
                                                    );
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </Form.Group>
                                          <Form.Group
                                            className="col-md-6 mb-5"
                                            controlId="exampleForm.c_name"
                                          >
                                            <div className="d-flex align-items-center">
                                              <div class="checkbox-group">
                                                <div class="checkbox-item no-wrap d-flex fs-5 lh-1">
                                                  <input
                                                    type="checkbox"
                                                    class="custom-checkbox "
                                                    checked={handleAddDataCheck(
                                                      "of_isolation"
                                                    )}
                                                    onChange={(e) => {
                                                      if (e.target.checked) {
                                                        handleAddDataOnChange(
                                                          "of_isolation",
                                                          1
                                                        );
                                                      } else {
                                                        handleAddDataOnChange(
                                                          "of_isolation",
                                                          0
                                                        );
                                                      }
                                                    }}
                                                  />
                                                  Of Isolation (Contact or
                                                  Contact+)
                                                </div>
                                              </div>
                                              <div className="d-flex align-items-center">
                                                <span className="ms-4 me-2 grey-color">
                                                  Mandatory
                                                </span>
                                                <Switch
                                                  checkedChildren="Yes"
                                                  unCheckedChildren="No"
                                                  checked={handleAddDataSwitchcheck(
                                                    "of_isolation"
                                                  )}
                                                  className="blue-b"
                                                  onChange={(e) => {
                                                    handleAddDataSwitchOnChange(
                                                      "of_isolation",
                                                      e
                                                    );
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </Form.Group>
                                          <Form.Group
                                            className="col-md-6 mb-5"
                                            controlId="exampleForm.c_name"
                                          >
                                            <div className="d-flex align-items-center">
                                              <div class="checkbox-group">
                                                <div class="checkbox-item no-wrap d-flex fs-5 lh-1">
                                                  <input
                                                    type="checkbox"
                                                    class="custom-checkbox "
                                                    checked={handleAddDataCheck(
                                                      "of_rrs_codes"
                                                    )}
                                                    onChange={(e) => {
                                                      if (e.target.checked) {
                                                        handleAddDataOnChange(
                                                          "of_rrs_codes",
                                                          1
                                                        );
                                                      } else {
                                                        handleAddDataOnChange(
                                                          "of_rrs_codes",
                                                          0
                                                        );
                                                      }
                                                    }}
                                                  />
                                                  Of RRs / Codes
                                                </div>
                                              </div>
                                              <div className="d-flex align-items-center">
                                                <span className="ms-4 me-2 grey-color">
                                                  Mandatory
                                                </span>
                                                <Switch
                                                  checkedChildren="Yes"
                                                  unCheckedChildren="No"
                                                  checked={handleAddDataSwitchcheck(
                                                    "of_rrs_codes"
                                                  )}
                                                  className="blue-b"
                                                  onChange={(e) => {
                                                    handleAddDataSwitchOnChange(
                                                      "of_rrs_codes",
                                                      e
                                                    );
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </Form.Group>
                                          <Form.Group
                                            className="col-md-6 mb-5"
                                            controlId="exampleForm.c_name"
                                          >
                                            <div className="d-flex align-items-center">
                                              <div class="checkbox-group">
                                                <div class="checkbox-item no-wrap d-flex fs-5 lh-1">
                                                  <input
                                                    type="checkbox"
                                                    class="custom-checkbox "
                                                    checked={handleAddDataCheck(
                                                      "of_video_monitors"
                                                    )}
                                                    onChange={(e) => {
                                                      if (e.target.checked) {
                                                        handleAddDataOnChange(
                                                          "of_video_monitors",
                                                          1
                                                        );
                                                      } else {
                                                        handleAddDataOnChange(
                                                          "of_video_monitors",
                                                          0
                                                        );
                                                      }
                                                    }}
                                                  />
                                                  Of Video Monitors
                                                </div>
                                              </div>
                                              <div className="d-flex align-items-center">
                                                <span className="ms-4 me-2 grey-color">
                                                  Mandatory
                                                </span>
                                                <Switch
                                                  checkedChildren="Yes"
                                                  unCheckedChildren="No"
                                                  checked={handleAddDataSwitchcheck(
                                                    "of_video_monitors"
                                                  )}
                                                  className="blue-b"
                                                  onChange={(e) => {
                                                    handleAddDataSwitchOnChange(
                                                      "of_video_monitors",
                                                      e
                                                    );
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </Form.Group>
                                          <Form.Group
                                            className="col-md-6 mb-5"
                                            controlId="exampleForm.c_name"
                                          >
                                            <div className="d-flex align-items-center">
                                              <div class="checkbox-group">
                                                <div class="checkbox-item no-wrap d-flex fs-5 lh-1">
                                                  <input
                                                    type="checkbox"
                                                    class="custom-checkbox "
                                                    checked={handleAddDataCheck(
                                                      "did_blt_cover_obs"
                                                    )}
                                                    onChange={(e) => {
                                                      if (e.target.checked) {
                                                        handleAddDataOnChange(
                                                          "did_blt_cover_obs",
                                                          1
                                                        );
                                                      } else {
                                                        handleAddDataOnChange(
                                                          "did_blt_cover_obs",
                                                          0
                                                        );
                                                      }
                                                    }}
                                                  />
                                                  Did BLT cover OBS
                                                </div>
                                              </div>
                                              <div className="d-flex align-items-center">
                                                <span className="ms-4 me-2 grey-color">
                                                  Mandatory
                                                </span>
                                                <Switch
                                                  checkedChildren="Yes"
                                                  unCheckedChildren="No"
                                                  checked={handleAddDataSwitchcheck(
                                                    "did_blt_cover_obs"
                                                  )}
                                                  className="blue-b"
                                                  onChange={(e) => {
                                                    handleAddDataSwitchOnChange(
                                                      "did_blt_cover_obs",
                                                      e
                                                    );
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </Form.Group>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                            <Form.Group className="col-md-12 mb-3 d-flex justify-content-end">
                              {/* <Button variant="primary" type="submit">
                              Reset
                            </Button> */}
                              <Button
                                className="ms-3"
                                variant="success"
                                type="submit"
                                disabled={isLoading}
                              >
                                {isLoading ? "Saving..." : "Save"}
                              </Button>
                            </Form.Group>
                          </div>
                        </div>
                      </FormikForm>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Formik>
      ) : (
        <div className="d-flex align-items-center justify-content-center w-100vw h-100vh">
          <span class="loader" style={{ width: "50px", height: "50px" }}></span>
        </div>
      )}
    </>
  );
}

export default EditCostCenterForm;
