import "./index.css";
import { useEffect, useContext, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import Button from "react-bootstrap/Button";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import { get } from "lodash";
import { API_CALL_URL_PRIVATE } from "../../constants";
import axios from "../../axios";
import { DOMAIN_URL } from "../../constants/compensate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactPaginate from "react-paginate";
import { setLoadingPage, setNavigate } from "../../store/actions";
import AscIcon from "../../assets/img/desc.png";
import DescIcon from "../../assets/img/asc.png";
import { AiOutlineDelete } from "react-icons/ai";
import { toast } from "react-toastify";
import MultiSelect from "../MultiSelectCustom/MultiSelect";
import { nanoid } from "nanoid";
import { ThemeContext } from "../../providers/ThemeProvider";
import MultiSelectTwo from "../MultiSelectCustom/MultiSelectTwo";

function AddNursingGridNewForm(props) {
  const [theme, setTheme] = useContext(ThemeContext);
  const parentRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = JSON.parse(localStorage.getItem("psAdmin"));
  const customer_code = useSelector((state) => state.compensate.customerCode);
  const loading_page = useSelector((state) => state.compensate.LoadingPage);
  console.log(customer_code, "customercode");

  const [isInitialData, setISInitialData] = useState(false);
  const [sortOrder, setSortOrder] = useState(1);
  const [searchTempKeyword, setSearchTempKeyword] = useState("");
  const boxdata = props.data;
  const isLoader = props.isLoader;
  const { issearch } = props;
  const [userDatas, setUserDatas] = useState(get(userData, "data", []));
  const [pageCounter, setPageCounter] = useState("");
  const [listLoader, setListLoader] = useState(false);
  const [shiftLoader, setShiftLoader] = useState(false);
  const [searchActive, setSearchActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentCustomerCode, setCurrentCustomerCode] = useState(null);
  const [CostCenterData, setCostCenterData] = useState(null);
  const [dropCostCenterMaster, setDropCostCenterMaster] = useState([]);
  const [costCenterMaster, setCostCenterMaster] = useState(null);
  const [costCenterNumber, setCostCenterNumber] = useState(null);
  const [dropShiftTime, setDropShiftTime] = useState([]);
  const [currentShiftTime, setCurrentShiftTime] = useState("");
  const [dropShiftYear, setDropShiftYear] = useState([]);
  const [currentShiftYear, setCurrentShiftYear] = useState("");
  const [headers, setHeaders] = useState({});

  console.log(CostCenterData, "data");

  function getDate(inputDate) {
    const timeZone = "America/Los_Angeles";
    const date = new Date(inputDate).toLocaleString("en-US", {
      timeZone,
      hour12: true,
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    });

    return date;
  }

  useEffect(() => {
    const getCostCenter = async () => {
      try {
        const resp = await axios.post(
          `${API_CALL_URL_PRIVATE}/department/departmentList`,
          {
            customer_code: parseInt(customer_code),
            limit: "100000",
          }
        );

        const allCostCenter = resp?.data?.data?.data?.docs?.filter((e) => {
          return e.realtime === 1;
        });
        setCostCenterMaster(
          allCostCenter?.map((e) => {
            return {
              cost_center_name: e.cost_center_name,
              cost_center_number: e.cost_center_number,
            };
          })
        );
        setISInitialData(true);
        console.log(resp);
        const DropCost = allCostCenter?.map((e) => {
          return {
            label: `${e.cost_center_number} - ${e.cost_center_name}`,
            value: e.cost_center_number,
          };
        });
        setDropCostCenterMaster((prev) => {
          return DropCost?.length > 0 ? [...DropCost] : [];
        });

        dispatch(setLoadingPage(false));
      } catch (error) {
        console.log(error);
      }
    };
    getCostCenter();
  }, [customer_code]);

  const getGridDefaultList = async () => {
    try {
      // setListLoader(true);
      const resp = await axios.post(
        `${API_CALL_URL_PRIVATE}/nursing-grid/costCenterDefaultData`,
        {
          customer_code: parseInt(customer_code),
          cost_center_number: parseInt(costCenterNumber),
          shift_time: currentShiftTime,
        }
      );
      console.log(resp, "resp");
      const Data = resp?.data?.data?.detailWithKeys;
      setHeaders(resp?.data?.data?.headers);
      const drop_shift_time = resp?.data?.data?.shiftTime?.map((e) => {
        return {
          label: e,
          value: e,
        };
      });
      setDropShiftTime([
        { value: "", label: "Select All" },
        ...drop_shift_time,
      ]);
      const drop_year = resp?.data?.data?.years?.map((e) => {
        return {
          label: e,
          value: e,
        };
      });
      setDropShiftYear(
        drop_year?.length > 0
          ? [{ value: "", label: "Select All" }, ...drop_year]
          : []
      );
      const updatedData = resp?.data?.data?.detailWithKeys?.map((item) => {
        const updatedItem = { ...item };
        const validShiftTime = item.shift_time.options;
        const validYears = item.year.options;

        if (!validShiftTime.includes(item.shift_time.value)) {
          updatedItem.shift_time.value = "";
        }

        if (!validYears.includes(item.year.value)) {
          updatedItem.year.value = "";
        }

        return updatedItem;
      });
      setCostCenterData(updatedData || []);

      dispatch(setLoadingPage(false));
    } catch (error) {
      console.log(error);
    } finally {
      setListLoader(false);
      setShiftLoader(false);
    }
  };
  useEffect(() => {
    if (costCenterNumber) {
      getGridDefaultList();
    }
  }, [costCenterNumber, currentShiftTime, currentShiftYear]);

  function convertDateStringToMMDDYYYY(dateString) {
    // Check if the input date string is valid
    if (!dateString) {
      return "";
    }

    const parts = dateString.split("-");
    return `${parts[1]}/${parts[0]}/${parts[2]}`;
  }
  const handleInputChange = (e, rowIndex, key, type, validation) => {
    if (type !== "checkbox") {
      const newValue = e.target.value;
      setCostCenterData((prevData) => {
        const newData = [...prevData];

        newData[rowIndex][key].value =
          type === "input"
            ? validation === "number"
              ? parseInt(newValue)
              : newValue
            : newValue;
        return newData;
      });
    } else {
      const newValue = e.target.checked;
      setCostCenterData((prevData) => {
        const newData = [...prevData];

        newData[rowIndex][key].value = newValue === true ? 1 : 0;
        return newData;
      });
    }
  };
  // const addRow = () => {
  //   if (CostCenterData?.length > 0) {
  //     const keys = Object.keys(CostCenterData[0]);
  //     const newRow = keys.reduce((acc, key) => {
  //       const item = CostCenterData[0][key];
  //       key === "_id"
  //         ? (acc[key] = {
  //             value: "",
  //             fieldType: "hidden",
  //             validation: "object",
  //           })
  //         : key === "delete_row"
  //         ? (acc[key] = {
  //             value: 0,
  //             fieldType: "checkbox",
  //             validation: "number",
  //           })
  //         : key === "cost_center_number"
  //         ? (acc[key] = {
  //             value: CostCenterData[0].cost_center_number.value,
  //             fieldType: "input",
  //             validation: "number",
  //           })
  //         : (acc[key] = {
  //             value:
  //               item.fieldType === "input"
  //                 ? 0
  //                 : key === "shift_time"
  //                 ? currentShiftTime || currentShiftTime !== ""
  //                   ? currentShiftTime
  //                   : item.options[0]
  //                 : key === "year"
  //                 ? currentShiftYear || currentShiftYear !== ""
  //                   ? currentShiftYear
  //                   : new Date().getFullYear().toString()
  //                 : item.options[0],
  //             fieldType: item.fieldType,
  //             validation: item.validation,
  //           });
  //       // : currentShiftTime && currentShiftTime !== ""
  //       //       ? currentShiftTime
  //       //       : item.options[0],
  //       if (item.fieldType !== "input") {
  //         acc[key].options = item.options || [];
  //       }
  //       return acc;
  //     }, {});

  //     setCostCenterData([...CostCenterData, newRow]);
  //   }
  // };
  const handleCheckboxDropChange = (selected, rowIndex, key) => {
    console.log(selected?.map((e) => e.value));
    setCostCenterData((prevData) => {
      const newData = [...prevData];

      newData[rowIndex][key].value = selected?.map((e) => e.value);
      return newData;
    });
  };
  const addRow = () => {
    if (CostCenterData?.length > 0) {
      const keys = Object.keys(CostCenterData[0]);
      const newRow = keys.reduce((acc, key) => {
        const item = CostCenterData[0][key];
        key === "_id"
          ? (acc[key] = {
              value: "",
              fieldType: "hidden",
              validation: "object",
            })
          : key === "delete_row"
          ? (acc[key] = {
              value: 0,
              fieldType: "checkbox",
              validation: "number",
            })
          : key === "cost_center_number"
          ? (acc[key] = {
              value: CostCenterData[0].cost_center_number.value,
              fieldType: "input",
              validation: "number",
            })
          : key === "day"
          ? (acc[key] = {
              value: CostCenterData[0].day.value,
              fieldType: "dropdown_ckeckbox",
              validation: "string",
            })
          : (acc[key] = {
              value:
                item.fieldType === "input"
                  ? item.validation === "number"
                    ? 0
                    : item.validation === "string"
                    ? key === "added_date"
                      ? CostCenterData[CostCenterData?.length - 1]?.added_date
                          ?.value
                      : ""
                    : ""
                  : key === "shift_time"
                  ? currentShiftTime || currentShiftTime !== ""
                    ? currentShiftTime
                    : item.options[0]
                  : key === "year"
                  ? currentShiftYear || currentShiftYear !== ""
                    ? currentShiftYear
                    : new Date().getFullYear().toString()
                  : item.options[0],
              fieldType: item.fieldType,
              validation: item.validation,
            });
        if (item.fieldType !== "input") {
          acc[key].options = item.options || [];
        }
        return acc;
      }, {});

      setCostCenterData([...CostCenterData, newRow]);
    }
  };
  const handleDelete = (row) => {
    const data = CostCenterData;
    const newData = data.filter((item, i) => i !== row);
    setCostCenterData(newData);
  };

  const handleSave = async () => {
    const obj = {
      customer_code: customer_code,
      data: CostCenterData.map((item) => {
        const transformedItem = {};
        for (const key in item) {
          if (item.hasOwnProperty(key)) {
            if (key === "_id") {
              transformedItem[key] = "";
            } else {
              transformedItem[key] = item[key].value;
            }
          }
        }
        return transformedItem;
      }),
    };
    console.log(obj);
    try {
      setIsLoading(true);
      const resp = await axios.post(
        `${API_CALL_URL_PRIVATE}/nursing-grid/bulkAddOrUpdate`,
        obj
      );
      console.log(resp);
      toast.success(resp?.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
      });
      navigate("/admin/staffing-grid");
    } catch (error) {
      toast.error(error.response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
      });
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isInitialData && !loading_page ? (
        <>
          <div className="main-panel">
            <div className="content-wrapper">
              <div className="page-header">
                <h3 className="page-title">
                  <a href="#" className="badge badge-dark"></a>
                </h3>
                <div className></div>
              </div>
            </div>
          </div>
          <div className="main-panel">
            <div className="content-wrapper">
              <div className="page-header">
                <h3 className="page-title">
                  <a href="#" className="badge badge-dark"></a>
                </h3>
                <div className></div>
              </div>

              <div className="row">
                <div className="col-lg-12 grid-margin stretch-card">
                  <div
                    className="card"
                    style={{
                      border: "0px",
                      borderBottomLeftRadius: "0px",
                      WebkitBorderBottomRightRadius: "0px",
                    }}
                  >
                    <div className="card-body">
                      <div className="row align-items-center justify-content-between">
                        <div className="col-1">
                          <Link
                            to={`/admin/staffing-grid`}
                            className="f-14 white-text-dark-mode"
                          >
                            <FontAwesomeIcon icon="fa-solid fa-arrow-left " />{" "}
                            Back
                          </Link>
                        </div>

                        <div
                          className={
                            !listLoader && CostCenterData
                              ? "col-md-3"
                              : "col-md-4"
                          }
                        >
                          <div
                            className="d-flex col"
                            style={{ maxWidth: "100%", margin: "0 auto" }}
                          >
                            <h5 class="m-0 fs-6 text-center mx-2">
                              Cost Center
                            </h5>
                            <div
                              className=""
                              style={{ width: "calc(100% - 110px)" }}
                              //   className="ms-4"
                            >
                              <Select
                                isSearchable="true"
                                options={dropCostCenterMaster}
                                className="custom-select mx-2"
                                onChange={(e) => {
                                  setCostCenterNumber(e.value);
                                  setListLoader(true);
                                }}
                              />
                            </div>
                          </div>
                        </div>

                        {!listLoader && CostCenterData ? (
                          <>
                            <div className="col-md-3">
                              <div
                                className="d-flex col"
                                style={{ maxWidth: "100%", margin: "0 auto" }}
                              >
                                <h5 class="m-0 fs-6 text-center mx-2">
                                  Intervals
                                </h5>
                                <div
                                  className=""
                                  style={{ width: "60%" }}
                                  //   className="ms-4"
                                >
                                  <Select
                                    isSearchable="true"
                                    options={dropShiftTime}
                                    className="custom-select mx-2"
                                    onChange={(e) => {
                                      setCurrentShiftTime(e.value);
                                      setShiftLoader(true);
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div
                                className="d-flex col"
                                style={{ maxWidth: "100%", margin: "0 auto" }}
                              >
                                <h5 class="m-0 fs-6 text-center mx-2">Year</h5>
                                <div
                                  className=""
                                  style={{ width: "60%" }}
                                  //   className="ms-4"
                                >
                                  <Select
                                    isSearchable="true"
                                    options={dropShiftYear}
                                    className="custom-select mx-2"
                                    onChange={(e) => {
                                      setCurrentShiftYear(e.value);
                                      setShiftLoader(true);
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                        <div className="col-2 text-end">
                          {!listLoader && CostCenterData && !shiftLoader ? (
                            <>
                              <button
                                type="button"
                                class="btn btn-success"
                                onClick={addRow}
                              >
                                Add Row
                              </button>
                              <Button
                                className="ms-3"
                                variant="success"
                                onClick={handleSave}
                                disabled={isLoading}
                              >
                                {isLoading ? "Saving..." : "Save"}
                              </Button>
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {costCenterNumber ? (
                <>
                  <div className="row">
                    <div className="col-lg-12 grid-margin stretch-card">
                      <div
                        className="card"
                        style={{
                          border: "0px",
                          borderTopLeftRadius: "0px",
                          WebkitBorderTopRightRadius: "0px",
                        }}
                      >
                        <div className="card-body">
                          {listLoader || shiftLoader ? (
                            <div
                              className="d-flex align-items-center justify-content-center"
                              style={{ height: "370px" }}
                            >
                              <span
                                class="loader"
                                style={{ width: "50px", height: "50px" }}
                              ></span>
                            </div>
                          ) : (
                            <>
                              <div className="table-wrapper">
                                <table className="border-bottom-table">
                                  <thead>
                                    <tr>
                                      <th style={{ cursor: "default" }}>No.</th>
                                      {CostCenterData?.length > 0 &&
                                        Object.keys(CostCenterData[0]).map(
                                          (item, i) => (
                                            <>
                                              {item !== "_id" ? (
                                                <th
                                                  key={i}
                                                  style={{
                                                    cursor: "default",
                                                  }}
                                                >
                                                  {headers[item]}
                                                </th>
                                              ) : (
                                                ""
                                              )}
                                            </>
                                          )
                                        )}
                                    </tr>
                                  </thead>

                                  <>
                                    <tbody className="hhh" ref={parentRef}>
                                      {CostCenterData?.map((row, rowIndex) => (
                                        <tr
                                          key={rowIndex}
                                          style={{
                                            backgroundColor:
                                              row["delete_row"].value === 1
                                                ? "rgba(127, 143, 166,0.5)"
                                                : "",
                                            opacity:
                                              row["delete_row"].value === 1
                                                ? "0.5"
                                                : "",
                                            cursor:
                                              row["delete_row"].value === 1
                                                ? "not-allowed"
                                                : "",
                                          }}
                                        >
                                          <td>
                                            <div className="my-1">
                                              {rowIndex + 1}
                                            </div>
                                          </td>
                                          {Object.keys(row)?.map(
                                            (key, colIndex) => (
                                              <>
                                                {row[key].fieldType !==
                                                "hidden" ? (
                                                  <td key={colIndex}>
                                                    {row[key].fieldType ===
                                                    "input" ? (
                                                      <Form.Group
                                                        className="col-md-3 mb-0"
                                                        controlId="exampleForm.c_code"
                                                        style={{
                                                          minWidth:
                                                            row[key]
                                                              .validation ===
                                                            "number"
                                                              ? "90px"
                                                              : "160px",
                                                        }}
                                                      >
                                                        <Form.Control
                                                          type={
                                                            row[key]
                                                              .validation ===
                                                            "number"
                                                              ? "number"
                                                              : "text"
                                                          }
                                                          placeholder={
                                                            row[key]
                                                              ?.placeholder
                                                          }
                                                          // name="census"
                                                          value={row[key].value}
                                                          readOnly={
                                                            key ===
                                                              "cost_center_number" ||
                                                            row["delete_row"]
                                                              .value === 1
                                                              ? true
                                                              : false
                                                          }
                                                          onChange={(e) =>
                                                            handleInputChange(
                                                              e,
                                                              rowIndex,
                                                              key,
                                                              row[key]
                                                                .fieldType,
                                                              row[key]
                                                                .validation
                                                            )
                                                          }
                                                        />
                                                      </Form.Group>
                                                    ) : row[key].fieldType ===
                                                      "dropdown" ? (
                                                      <Form.Group
                                                        className="col-md-3 mb-0"
                                                        controlId="exampleForm.c_email"
                                                        style={{
                                                          minWidth: "120px",
                                                        }}
                                                      >
                                                        <Form.Select
                                                          aria-label="Default select example"
                                                          className="white h-30"
                                                          value={`${row[key].value}`}
                                                          style={{
                                                            cursor:
                                                              row["delete_row"]
                                                                .value === 1
                                                                ? "not-allowed"
                                                                : "auto",
                                                          }}
                                                          onChange={(e) =>
                                                            !(
                                                              row["delete_row"]
                                                                .value === 1
                                                            ) &&
                                                            handleInputChange(
                                                              e,
                                                              rowIndex,
                                                              key,
                                                              row[key].fieldType
                                                            )
                                                          }
                                                        >
                                                          {row[key].options.map(
                                                            (
                                                              option,
                                                              optionIndex
                                                            ) => (
                                                              <option
                                                                key={
                                                                  optionIndex
                                                                }
                                                                value={option}
                                                              >
                                                                {option}
                                                              </option>
                                                            )
                                                          )}
                                                        </Form.Select>
                                                      </Form.Group>
                                                    ) : row[key].fieldType ===
                                                      "checkbox" ? (
                                                      <>
                                                        <td>
                                                          <div class="checkbox-item">
                                                            <input
                                                              type="checkbox"
                                                              class="custom-checkbox"
                                                              checked={
                                                                row[key]
                                                                  .value === 1
                                                              }
                                                              onChange={(e) => {
                                                                handleInputChange(
                                                                  e,
                                                                  rowIndex,
                                                                  key,
                                                                  row[key]
                                                                    .fieldType
                                                                );
                                                              }}
                                                            />
                                                          </div>
                                                        </td>
                                                      </>
                                                    ) : row[key].fieldType ===
                                                      "dropdown_ckeckbox" ? (
                                                      <>
                                                        <td>
                                                          <div
                                                            style={{
                                                              minWidth: "150px",
                                                            }}
                                                          >
                                                            <MultiSelectTwo
                                                              placeholder="Day"
                                                              parentRef={
                                                                parentRef
                                                              }
                                                              options={row[
                                                                key
                                                              ]?.options?.map(
                                                                (e) => {
                                                                  return {
                                                                    value: e,
                                                                    label: e,
                                                                  };
                                                                }
                                                              )}
                                                              onChange={(e) => {
                                                                handleCheckboxDropChange(
                                                                  e,
                                                                  rowIndex,
                                                                  key
                                                                );
                                                              }}
                                                              value={row[
                                                                key
                                                              ]?.value?.map(
                                                                (e) => {
                                                                  return {
                                                                    value: e,
                                                                    label: e,
                                                                  };
                                                                }
                                                              )}
                                                              isSelectAll={true}
                                                              menuPlacement={
                                                                "auto"
                                                              }
                                                              theme={
                                                                theme.theme ===
                                                                "LIGHT"
                                                              }
                                                              closeMenuOnSelect={
                                                                false
                                                              }
                                                            />
                                                          </div>
                                                        </td>
                                                      </>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </td>
                                                ) : (
                                                  ""
                                                )}
                                              </>
                                            )
                                          )}
                                        </tr>
                                      ))}
                                      <tr colSpan="40"><span style={{opacity: "0"}}>test</span></tr>
                                      <tr colSpan="40"><span style={{opacity: "0"}}>test</span></tr>
                                      <tr colSpan="40"><span style={{opacity: "0"}}>test</span></tr>
                                      <tr colSpan="40"><span style={{opacity: "0"}}>test</span></tr>
                                      <tr colSpan="40"><span style={{opacity: "0"}}>test</span></tr>
                                      <tr colSpan="40"><span style={{opacity: "0"}}>test</span></tr>
                                      <tr colSpan="40"><span style={{opacity: "0"}}>test</span></tr>
                                      <tr colSpan="40"><span style={{opacity: "0"}}>test</span></tr>
                                    </tbody>
                                  </>
                                </table>
                              </div>

                              <Form.Group className="col-md-12 my-3 d-flex justify-content-end">
                                <button
                                  type="button"
                                  class="btn btn-success"
                                  onClick={addRow}
                                >
                                  Add Row
                                </button>
                                <Button
                                  className="ms-3"
                                  variant="success"
                                  onClick={handleSave}
                                  disabled={isLoading}
                                >
                                  {isLoading ? "Saving..." : "Save"}
                                </Button>
                              </Form.Group>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </>
      ) : (
        <div className="d-flex align-items-center justify-content-center w-100vw h-100vh">
          <span class="loader" style={{ width: "50px", height: "50px" }}></span>
        </div>
      )}
    </>
  );
}

export default AddNursingGridNewForm;
