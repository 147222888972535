import "./index.css";
import { useEffect, useContext, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import DashboardCounter from "../../components/DashboardCounter/index";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link, useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import PageLayout from "../../layouts/PageLayout";
import Ico1 from "../../assets/img/edit.png";
import Ico2 from "../../assets/img/delete.png";
import Ico3 from "../../assets/img/s.png";
import Ico4 from "../../assets/img/d.png";
import Clap from "../../assets/img/clap.png";
import Diconetwo from "../../assets/img/real_time.svg";
import Diconethree from "../../assets/img/active_daily_mgmt.svg";
import Iconone from "../../assets/img/beds.png";
import Icontwo from "../../assets/img/compliance.png";
import Iconthree from "../../assets/img/variance.png";
import Iconfour from "../../assets/img/hpuoc.png";
import Ico22 from "../../assets/img/info.png";
import Ico33 from "../../assets/img/warning.png";
import Ico44 from "../../assets/img/critical.png";
import { get } from "lodash";
import { API_CALL_URL_PRIVATE } from "../../constants";
import axios from "../../axios";
import { DOMAIN_URL } from "../../constants/compensate";
import ReactPaginate from "react-paginate";
import { setLoadingPage } from "../../store/actions";
import { Switch } from "antd";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
// import { useSelector } from "react-redux";

function Applications(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = JSON.parse(localStorage.getItem("psAdmin"));
  const customer_code = useSelector((state) => state.compensate.customerCode);
  const loading_page = useSelector((state) => state.compensate.LoadingPage);
  console.log(loading_page, "loading");
  const [dashboardData, setDashboardData] = useState([]);
  const [customerData, setCustomerData] = useState(null);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [pageLimit, setPageLimit] = useState(50);
  const [totalPages, setTotalPages] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [hasPrev, setHasPrev] = useState(false);
  const [hasNext, setHasNext] = useState(false);
  const [sortKey, setSortKey] = useState("");
  const [isInitialData, setISInitialData] = useState(false);
  const [sortOrder, setSortOrder] = useState(1);
  const boxdata = props.data;
  const isLoader = props.isLoader;
  const { issearch } = props;
  const [userDatas, setUserDatas] = useState(get(userData, "data", []));
  const [pageCounter, setPageCounter] = useState("");
  const [searchTempKeyword, setSearchTempKeyword] = useState("");
  const [isStatusDrop, setIsStatusDrop] = useState(false);
  const [statusUpdate, setStatusUpdate] = useState(false);

  const handleSwitchChange = (status, id) => {
    document.body.style.overflow = "hidden";
    Swal.fire({
      title: "Confirmation",
      text: `Are you sure you want to ${
        status ? "Active" : "Inactive"
      } the status?`,
      icon: "info",
      showCancelButton: true,
      confirmButtonText: "OK",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        statusUpdateApi(status, id);
      }
    });
  };
  const statusUpdateApi = async (val, id) => {
    try {
      const resp = await axios.post(
        `${API_CALL_URL_PRIVATE}/adminUser/statusUpdate`,
        {
          status: parseInt(val),
          _id: id,
        }
      );
      console.log(resp);
      toast.success(resp?.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
      });
      setStatusUpdate(!statusUpdate);
    } catch (error) {
      toast.error("Something went wrong..! Try Again", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
      });

      console.log(error);
    }
  };

  const renderElements = () => {
    return Array.from({ length: totalPages }, (_, index) => (
      <span
        key={index + 1}
        className={
          index + 1 === currentPage
            ? "paginate_button current"
            : "paginate_button"
        }
        aria-controls="example"
        role="link"
        aria-current="page"
        data-dt-idx="0"
        tabindex="0"
        onClick={() => {
          setCurrentPage(index + 1);
        }}
      >
        {index + 1}
      </span>
    ));
  };

  useEffect(() => {
    const getCustomerDetailList = async () => {
      try {
        const resp = await axios.post(
          `${API_CALL_URL_PRIVATE}/adminUser/userList`,
          {
            searchKeyword: searchKeyword,
            limit: pageLimit,
            page: currentPage,
            sort_column: sortKey,
            sort_order: sortOrder,
          }
        );

        const Data = resp?.data?.data?.data;
        console.log(Data);
        setCustomerData(Data);
        setPageLimit(Data?.limit);
        setTotalPages(Data?.totalPages);
        setCurrentPage(Data?.page);
        setHasNext(Data?.hasNextPage);
        setHasPrev(Data?.hasPrevPage);
        setPageCounter(Data?.pagingCounter);
        setISInitialData(true);
        dispatch(setLoadingPage(false));
      } catch (error) {
        console.log(error);
      }
    };

    getCustomerDetailList();
  }, [
    searchKeyword,
    pageLimit,
    currentPage,
    sortOrder,
    statusUpdate,
    customer_code,
  ]);

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected + 1);
  };

  return (
    <>
      {isInitialData && !loading_page ? (
        <div className="main-panel">
          <div className="content-wrapper">
            <div className="page-header">
              <h3 className="page-title">
                <a href="#" className="badge badge-dark"></a>
              </h3>
              <div className></div>
            </div>
            <div className="row">
              <div className="col-lg-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex justify-content-between mb-4">
                      <h4 className="card-title">All Admin User</h4>

                      <Link
                        className="boxlink"
                        to="/admin/admin-user/add-admin"
                      >
                        {" "}
                        <button type="button" class="btn btn-success">
                          Add Admin User
                        </button>
                      </Link>
                    </div>
                    <div className="d-flex justify-content-between">
                      <div class="dataTables_length" id="example_length">
                        <label>
                          Show &nbsp;
                          <select
                            name="example_length"
                            aria-controls="example"
                            class=""
                            value={pageLimit}
                            onChange={(e) => {
                              setPageLimit(e.target.value);
                            }}
                          >
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>{" "}
                          &nbsp;entries
                        </label>
                      </div>
                      <form
                        onSubmit={(e) => {
                          e.preventDefault(); // Prevent the default form submission behavior

                          setSearchKeyword(searchTempKeyword);
                        }}
                      >
                        <div
                          id="example_filter"
                          class="dataTables_filter d-flex align-items-center justify-content-center "
                        >
                          <label>
                            Search: &nbsp;
                            <input
                              type="text"
                              class=""
                              placeholder=""
                              aria-controls="example"
                              onChange={(e) => {
                                setSearchTempKeyword(e.target.value);
                              }}
                              // onKeyDown={(e) => {
                              //   if (e.key === "Enter") {
                              //     setSearchKeyword(e.target.value);
                              //   }
                              // }}
                            />
                          </label>

                          <div
                            style={{
                              border: "1px solid rgba(255,255,255,0.5)",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              padding: "9px",
                              marginBottom: "7px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setSearchKeyword(searchTempKeyword);
                            }}
                          >
                            <i class="ph ph-magnifying-glass"></i>
                          </div>
                        </div>
                      </form>
                    </div>
                    <div className="table-responsive">
                      <table className="table filter-table">
                        <thead>
                          <tr>
                            <th>No.</th>
                            <th
                              onClick={() => {
                                setSortKey("employee_id");
                                setSortOrder(sortOrder === 1 ? -1 : 1);
                              }}
                            >
                              Employee Id
                            </th>
                            <th
                              onClick={() => {
                                setSortKey("employee_name");
                                setSortOrder(sortOrder === 1 ? -1 : 1);
                              }}
                            >
                              Name
                            </th>
                            <th
                              onClick={() => {
                                setSortKey("username");
                                setSortOrder(sortOrder === 1 ? -1 : 1);
                              }}
                            >
                              Username
                            </th>
                            {/* <th
                          onClick={() => {
                            setSortKey("employee_job_title");
                            setSortOrder(sortOrder === 1 ? -1 : 1);
                          }}
                        >
                          Job Title
                        </th> */}
                            {/* <th
                          onClick={() => {
                            setSortKey("employee_job_category");
                            setSortOrder(sortOrder === 1 ? -1 : 1);
                          }}
                        >
                          Job Category
                        </th> */}
                            <th
                              onClick={() => {
                                setSortKey("employee_email");
                                setSortOrder(sortOrder === 1 ? -1 : 1);
                              }}
                            >
                              Email
                            </th>
                            <th
                              onClick={() => {
                                setSortKey("sid");
                                setSortOrder(sortOrder === 1 ? -1 : 1);
                              }}
                            >
                              Sid
                            </th>
                            <th
                              onClick={() => {
                                setSortKey("sid email");
                                setSortOrder(sortOrder === 1 ? -1 : 1);
                              }}
                            >
                              Sid Email
                            </th>

                            <th
                              onClick={() => {
                                setSortKey("active_status");
                                setSortOrder(sortOrder === 1 ? -1 : 1);
                              }}
                            >
                              Active Status
                            </th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        {customerData?.docs.length > 0 ? (
                          <tbody>
                            {customerData?.docs?.map((Ele, i) => {
                              return (
                                <tr key={Ele._id}>
                                  <td>{i + pageCounter}</td>
                                  <td>{Ele?.employee_id}</td>
                                  <td>{Ele?.employee_name}</td>
                                  <td>{Ele?.username}</td>
                                  <td>{Ele?.employee_email}</td>
                                  {/* <td>{Ele?.employee_job_title}</td>
                            <td>{Ele?.employee_job_category}</td> */}
                                  <td>{Ele?.sid}</td>
                                  <td>{Ele?.sid_email}</td>

                                  {/* <td>
                                  <div className="statusdrop" style={isStatusDrop?{height: "40px"}:{height: "20px"}}>
                                    <div style={{ display: "flex",justifyContent:"space-between" }} >
                                      <div className="statusMenu">hii</div>
                                      <div className="statusIcon">
                                        <i className="ph ph-arrow-down" onClick={()=>{setIsStatusDrop(!isStatusDrop)}}></i>
                                      </div>
                                    </div>
                                    <div style={{ display: "flex" }}>
                                      <div className="statusMenu">hii</div>
                                      
                                    </div>
                                  </div>
                                </td> */}
                                  <td className="statusTD">
                                    <Switch
                                      checked={Ele?.active_status}
                                      onChange={() => {
                                        handleSwitchChange(
                                          Ele?.active_status === 1 ? 0 : 1,
                                          Ele?._id
                                        );
                                      }}
                                      checkedChildren="Active"
                                      unCheckedChildren="Inactive"
                                    />
                                  </td>
                                  {/* <td>
                                  <label
                                    className={
                                      Ele?.active_status === 1
                                        ? "text-success"
                                        : "text-danger"
                                    }
                                  >
                                    {Ele?.active_status === 1
                                      ? "Active"
                                      : "Inactive"}
                                  </label>
                                </td> */}

                                  <td>
                                    <label className="edit">
                                      <Link
                                        className="boxlink"
                                        to={`/admin/admin-user/edit-admin/${Ele?.employee_id}/${Ele?._id}`}
                                      >
                                        <img src={Ico1} className="w-a" />
                                      </Link>
                                    </label>
                                    {/* {" "}
                                  &nbsp;
                                  <label className="edit">
                                    <img src={Ico2} className="w-a" />
                                  </label> */}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        ) : (
                          <tbody>
                            <tr>
                              <td colSpan="10">
                                <div
                                  className="d-flex justify-content-center align-items-center"
                                  style={{ height: "200px" }}
                                >
                                  <p class="text-secondary display-6">
                                    Oops! Data Not found
                                  </p>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        )}
                      </table>
                    </div>
                    <div className="d-flex justify-content-between">
                      <div
                        class="dataTables_info"
                        id="example_info"
                        role="status"
                        aria-live="polite"
                      >
                        Showing {pageCounter} to{" "}
                        {pageLimit > customerData.totalDocs
                          ? customerData.totalDocs
                          : pageLimit + pageCounter - 1}{" "}
                        of total {customerData?.totalDocs} entries
                      </div>
                      <div
                        class="dataTables_paginate paging_simple_numbers"
                        id="example_paginate"
                      >
                        {/* {hasPrev && (
                      <span
                        className="paginate_button previous disabled"
                        aria-controls="example"
                        aria-disabled="true"
                        role="link"
                        data-dt-idx="previous"
                        tabindex="-1"
                        id="example_previous"
                        onClick={() => {
                          setCurrentPage(customerData?.prevPage);
                        }}
                      >
                        Previous
                      </span>
                    )}
                    <span>
                      {renderElements()}
                    </span>
                    {hasNext && (
                      <span
                        className="paginate_button next"
                        aria-controls="example"
                        role="link"
                        data-dt-idx="next"
                        tabindex="0"
                        id="example_next"
                        onClick={() => {
                          setCurrentPage(customerData?.nextPage);
                        }}
                      >
                        Next
                      </span>
                    )} */}
                        <span>
                          <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            breakLabel={"..."}
                            pageCount={customerData?.totalPages}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageClick}
                            containerClassName={"pagination"}
                            previousLinkClassName={"paginationLink"}
                            nextLinkClassName={"paginationLink"}
                            disabledClassName={"paginationDisabled"}
                            activeClassName={"paginationActive"}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="d-flex align-items-center justify-content-center w-100vw h-100vh">
          <span class="loader" style={{ width: "50px", height: "50px" }}></span>
        </div>
      )}
    </>
  );
}

export default Applications;
