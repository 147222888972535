import "./index.css";
import { useEffect, useContext, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import DashboardCounter from "../../components/DashboardCounter/index";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import PageLayout from "../../layouts/PageLayout";
import Ico1 from "../../assets/img/edit.png";
import Ico2 from "../../assets/img/delete.png";
import Ico3 from "../../assets/img/s.png";
import Ico4 from "../../assets/img/d.png";
import Clap from "../../assets/img/clap.png";
import Diconetwo from "../../assets/img/real_time.svg";
import Diconethree from "../../assets/img/active_daily_mgmt.svg";
import Iconone from "../../assets/img/beds.png";
import Icontwo from "../../assets/img/compliance.png";
import Iconthree from "../../assets/img/variance.png";
import Iconfour from "../../assets/img/hpuoc.png";
import Ico22 from "../../assets/img/info.png";
import Ico33 from "../../assets/img/warning.png";
import Ico44 from "../../assets/img/critical.png";
import { get } from "lodash";
import { API_CALL_URL_PRIVATE } from "../../constants";
import axios from "../../axios";
import { DOMAIN_URL } from "../../constants/compensate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactPaginate from "react-paginate";
import { setLoadingPage } from "../../store/actions";
import { flushSync } from "react-dom";
import AscIcon from "../../assets/img/desc.png";
import DescIcon from "../../assets/img/asc.png";
import { RiDeleteBin6Line } from "react-icons/ri";
import { toast } from "react-toastify";
import Swal from 'sweetalert2';

function Paycode(props) {
  const dispatch = useDispatch();
  const userData = JSON.parse(localStorage.getItem("psAdmin"));
  const customer_code = useSelector((state) => state.compensate.customerCode);
  const loading_page = useSelector((state) => state.compensate.LoadingPage);
  const [dashboardData, setDashboardData] = useState([]);
  const [customerData, setCustomerData] = useState(null);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [pageLimit, setPageLimit] = useState(50);
  const [totalPages, setTotalPages] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [hasPrev, setHasPrev] = useState(false);
  const [hasNext, setHasNext] = useState(false);
  const [sortKey, setSortKey] = useState("");
  const [isInitialData, setISInitialData] = useState(false);
  const [sortOrder, setSortOrder] = useState(1);
  const [searchTempKeyword, setSearchTempKeyword] = useState("");
  const boxdata = props.data;
  const isLoader = props.isLoader;
  const { issearch } = props;
  const [userDatas, setUserDatas] = useState(get(userData, "data", []));
  const [pageCounter, setPageCounter] = useState("");
  const [listLoader, setListLoader] = useState(false);
  const [searchActive, setSearchActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  function getDate(inputDate) {
    const timeZone = "America/Los_Angeles";
    const date = new Date(inputDate).toLocaleString("en-US", {
      timeZone,
      hour12: true,
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    });

    return date;
  }

  useEffect(() => {
    const getCustomerDetailList = async () => {
      try {
        const resp = await axios.post(
          `${API_CALL_URL_PRIVATE}/paycode/list`,
          {
            customer_code: parseInt(customer_code),
            searchKeyword: searchKeyword,
            limit: pageLimit,
            page: searchActive ? 1 : currentPage,
            sort_column: searchActive ? "" : sortKey,
            sort_order: searchActive ? 1 : sortOrder,
          }
        );

        const Data = resp?.data?.data?.data;
        setSearchActive(false);
        setCustomerData(Data);
        setPageLimit(Data?.limit);
        setTotalPages(Data?.totalPages);
        setCurrentPage(Data?.page);
        setHasNext(Data?.hasNextPage);
        setHasPrev(Data?.hasPrevPage);
        setPageCounter(Data?.pagingCounter);
        setListLoader(false);
        setISInitialData(true);
        dispatch(setLoadingPage(false));
      } catch (error) {
        console.log(error);
      }
    };
    getCustomerDetailList();
  }, [
    searchKeyword,
    pageLimit,
    currentPage,
    sortOrder,
    customer_code,
    sortKey,
  ]);
  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected + 1);
    setListLoader(true);
  };

  const renderSortIcon = (key) => {
    if (sortKey !== key) return <img src={DescIcon} alt="Descending" />;
    return sortOrder === -1 ? (
      <img src={DescIcon} alt="Descending" />
    ) : sortOrder === 1 ? (
      <img src={AscIcon} alt="Ascending" />
    ) : null;
  };
  const handleSort = (key) => {
    if (sortKey === key) {
      setSortOrder(sortOrder === -1 ? 1 : -1);
      setCurrentPage(1);
      setListLoader(true);
    } else {
      setSortKey(key);
      setSortOrder(1);
      setCurrentPage(1);
      setListLoader(true);
    }
  };

  const deletePaycode = async (_id) => {
    // Show SweetAlert2 confirmation dialog
    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const resp = await axios.post(
            `${API_CALL_URL_PRIVATE}/paycode/delete`,
            {
              _id: _id
            }
          );
          toast.success(resp?.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
          });
          // Perform the delete action here, e.g., make an API call to delete the item
          // Show success message
          // Swal.fire(
          //   'Deleted!',
          //   'Your item has been deleted.',
          //   'success'
          // ).then(
          //   window.location.reload()
          // );
          window.location.reload()
        } catch (error) {
          toast.error(error.response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
          });
          console.log(error);
        } finally {
          setIsLoading(false);
        }
      }
    });
  };

  return (
    <>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="page-header">
            <h3 className="page-title">
              <a href="#" className="badge badge-dark"></a>
            </h3>
            <div className></div>
          </div>
          <div className="row">
            <div className="col-lg-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between mb-4">
                    <h4 className="card-title">All Paycodes</h4>
                    <Link className="boxlink" to="/admin/paycode/add-paycode">
                      {" "}
                      <button type="button" class="btn btn-success">
                        Add Paycode
                      </button>
                    </Link>
                  </div>
                  <div className="d-flex justify-content-between mobile-wrap">
                    <div class="dataTables_length" id="example_length">
                      <label>
                        Show &nbsp;
                        <select
                          name="example_length"
                          aria-controls="example"
                          class=""
                          value={pageLimit}
                          onChange={(e) => {
                            setPageLimit(e.target.value);
                            setListLoader(true);
                          }}
                        >
                          <option value="5">5</option>
                          <option value="10">10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>{" "}
                        &nbsp;entries
                      </label>
                    </div>
                    <form
                      onSubmit={(e) => {
                        e.preventDefault(); // Prevent the default form submission behavior
                        if (searchKeyword !== searchTempKeyword) {
                          flushSync(() => {
                            setSearchActive(true);
                            setSortKey("");
                          });
                          setListLoader(true);
                          setSearchKeyword(searchTempKeyword);
                        }
                      }}
                    >
                      <div
                        id="example_filter"
                        class="dataTables_filter d-flex align-items-center justify-content-center "
                      >
                        <label>
                          Search: &nbsp;
                          <input
                            type="text"
                            class=""
                            placeholder=""
                            aria-controls="example"
                            onChange={(e) => {
                              setSearchTempKeyword(e.target.value);
                              if (e.target.value === "") {
                                setListLoader(true);
                                setSearchKeyword("");
                              }
                            }}
                            // onKeyDown={(e) => {
                            //   if (e.key === "Enter") {
                            //     setSearchKeyword(e.target.value);
                            //   }
                            // }}
                          />
                        </label>

                        <div
                          style={{
                            border: "1px solid #aaa",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: "9px",
                            marginBottom: "7px",
                            cursor: "pointer",
                            marginTop: "-1px",
                            marginLeft: "-1px",
                          }}
                          onClick={() => {
                            if (searchKeyword !== searchTempKeyword) {
                              flushSync(() => {
                                setSearchActive(true);
                                setSortKey("");
                              });
                              setListLoader(true);
                              setSearchKeyword(searchTempKeyword);
                            }
                          }}
                        >
                          <i class="ph ph-magnifying-glass"></i>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div>
                    <div className="table-responsive">
                      <table className="table filter-table">
                        <thead>
                          <tr>
                            <th>No.</th>
                            <th
                              onClick={() => {
                                handleSort("interface_code");
                              }}
                            >
                              Paycode
                              <span className="ms-1 bright">
                                {renderSortIcon("interface_code")}
                              </span>
                            </th>
                            <th
                              onClick={() => {
                                handleSort("description");
                              }}
                            >
                              Description
                              <span className="ms-1 bright">
                                {renderSortIcon("description")}
                              </span>
                            </th>
                            <th
                              onClick={() => {
                                handleSort("client_mapping");
                              }}
                            >
                              Client Mapping
                              <span className="ms-1 bright">
                                {renderSortIcon("client_mapping")}
                              </span>
                            </th>
                            <th
                              onClick={() => {
                                handleSort("ps_mapping");
                              }}
                            >
                              PS Mapping
                              <span className="ms-1 bright">
                                {renderSortIcon("ps_mapping")}
                              </span>
                            </th>
                            <th
                              onClick={() => {
                                handleSort("updatedAt");
                              }}
                            >
                              Updated At
                              <span className="ms-1 bright">
                                {renderSortIcon("updatedAt")}
                              </span>
                            </th>
                            <th
                              onClick={() => {
                                handleSort("createdAt");
                              }}
                            >
                              Created At
                              <span className="ms-1 bright">
                                {renderSortIcon("createdAt")}
                              </span>
                            </th>

                            {/* <th
                          onClick={() => {
                            setSortKey("status");
                            setSortOrder(sortOrder === 1 ? -1 : 1);
                          }}
                        >
                          Status
                        </th> */}
                            <th></th>
                            <th></th>
                          </tr>
                        </thead>
                        {isInitialData && !loading_page && !listLoader ? (
                          <>
                            {customerData?.docs?.length > 0 ? (
                              <tbody>
                                {customerData?.docs?.map((Ele, i) => {
                                  return (
                                    <tr key={Ele._id}>
                                      <td>{i + pageCounter}</td>
                                      <td>{Ele?.interface_code}</td>
                                      <td>{Ele?.description}</td>
                                      <td>{Ele?.client_mapping}</td>
                                      <td>{Ele?.ps_mapping}</td>
                                      <td>{getDate(Ele?.updatedAt)}</td>
                                      <td>{getDate(Ele?.createdAt)}</td>
                                      <td>
                                        <label className="edit">
                                          <Link
                                            className="boxlink"
                                            to={`/admin/paycode/edit-paycode/${Ele?._id}`}
                                          >
                                            <img
                                              src={Ico1}
                                              className="w-a"
                                              alt="icon"
                                            />
                                          </Link>
                                        </label>
                                      </td>
                                      <td>
                                        <label className="edit">
                                          <img
                                            src={Ico2}
                                            className="w-a"
                                            alt="icon"
                                            onClick={()=>deletePaycode(Ele?._id)}
                                          />
                                        </label>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            ) : (
                              <tbody>
                                <tr>
                                  <td colSpan="8">
                                    <div
                                      className="d-flex justify-content-center align-items-center"
                                      style={{ height: "200px" }}
                                    >
                                      <p class="text-secondary display-6">
                                        Oops! Data Not found
                                      </p>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            )}
                          </>
                        ) : (
                          <>
                            <tbody>
                              <tr>
                                <td colSpan="10">
                                  <div
                                    className="d-flex align-items-center justify-content-center w-100"
                                    style={{ height: "400px" }}
                                  >
                                    <span
                                      class="loader"
                                      style={{
                                        width: "50px",
                                        height: "50px",
                                      }}
                                    ></span>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </>
                        )}
                      </table>
                    </div>

                    {isInitialData && !loading_page && !listLoader ? (
                      <>
                        <div className="d-flex justify-content-between">
                          <div
                            class="dataTables_info"
                            id="example_info"
                            role="status"
                            aria-live="polite"
                          >
                            Showing {pageCounter} to{" "}
                            {Math.min(
                              pageCounter + pageLimit - 1,
                              customerData.totalDocs
                            )}{" "}
                            of total {customerData?.totalDocs} entries
                          </div>
                          <div
                            class="dataTables_paginate paging_simple_numbers"
                            id="example_paginate"
                          >
                            <span>
                              <ReactPaginate
                                previousLabel={"Previous"}
                                nextLabel={"Next"}
                                breakLabel={"..."}
                                pageCount={customerData?.totalPages}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={handlePageClick}
                                containerClassName={"pagination"}
                                previousLinkClassName={"paginationLink"}
                                nextLinkClassName={"paginationLink"}
                                disabledClassName={"paginationDisabled"}
                                activeClassName={"paginationActive"}
                                forcePage={currentPage - 1}
                              />
                            </span>
                          </div>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Paycode;
