import React, { useEffect, useState } from "react";
import "./index.css";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from "react-router-dom";
import { ErrorMessage, Formik, Form as FormikForm } from "formik";
import { toast } from "react-toastify";
import axios from "../../axios";
import { API_CALL_URL_PRIVATE } from "../../constants/compensate";
import Texterror from "../Texterror";
import { useSelector } from "react-redux";

function AddTargetTypeForm() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isInitialData, setIsInitialData] = useState(true);
  const customer_code = useSelector((state) => state.compensate.customerCode);

  const initialValues = {
    target_type_id: "",
    target_type: "",
  };

  const onSubmit = (values) => {
    console.log("Form Values", values);
    formSubmit(values);
  };

  const formSubmit = async (values) => {
    console.log("Entered Form Data");
    const obj = {
      target_type_id: parseInt(values.target_type_id),
      customer_code: customer_code,
      target_type: values.target_type,
    };

    console.log(">>Form Data", obj);
    try {
      setIsLoading(true);
      const resp = await axios.post(
        `${API_CALL_URL_PRIVATE}/targettype/targettypeAddUpdate`,
        obj
      );
      console.log(resp);
      toast.success(resp?.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
      });
      navigate("/admin/targettype");
    } catch (error) {
      toast.error(error.response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
      });

      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const validate = (values) => {
    const errors = {};
    if (!values.target_type_id) {
      errors.target_type_id = "Required";
    }
    if (!values.target_type) {
      errors.target_type = "Required";
    }
    return errors;
  };

  return (
    <>
      {isInitialData ? (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validate={validate}
          enableReinitialize
        >
          {(formik) => (
            <div className="main-panel">
              <div className="content-wrapper">
                <div className="page-header">
                  <h3 className="page-title">
                    <a href="#" className="badge badge-dark"></a>
                  </h3>
                  <div className></div>
                </div>
                <div className="row">
                  <div className="col-12 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body">
                        <div className="d-flex justify-content-between">
                          <h4 className="card-title">Add TargetType</h4>
                          <Link
                            to={`/admin/targettype/`}
                            className="f-14 white-text-dark-mode"
                          >
                            <FontAwesomeIcon icon="fa-solid fa-arrow-left " />{" "}
                            Back
                          </Link>
                        </div>

                        <FormikForm className="forms-sample">
                          <div className="row">
                            <Form.Group
                              className="col-md-4 mb-3"
                              controlId="exampleForm.c_code"
                            >
                              <Form.Label>Target Type Id</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=""
                                name="target_type_id"
                                {...formik.getFieldProps("target_type_id")}
                              />
                              <ErrorMessage
                                name="target_type_id"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-4 mb-3"
                              controlId="exampleForm.c_name"
                            >
                              <Form.Label>Target Type Name</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=""
                                name="target_type"
                                {...formik.getFieldProps("target_type")}
                              />
                              <ErrorMessage
                                name="target_type"
                                component={Texterror}
                              />
                            </Form.Group>

                            <Form.Group className="col-md-12 mb-3 d-flex justify-content-end">
                              <Button
                                className="ms-3"
                                variant="success"
                                type="submit"
                                disabled={isLoading}
                              >
                                {isLoading ? "Saving..." : "Save"}
                              </Button>
                            </Form.Group>
                          </div>
                        </FormikForm>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Formik>
      ) : (
        <div className="d-flex align-items-center justify-content-center w-100vw h-100vh">
          <span
            className="loader"
            style={{ width: "50px", height: "50px" }}
          ></span>
        </div>
      )}
    </>
  );
}

export default AddTargetTypeForm;
