import React, { useEffect, useState, useRef } from "react";
import "./index.css";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ErrorMessage, Formik, Form as FormikForm } from "formik";
import { toast } from "react-toastify";
import axios from "../../axios";
import { API_CALL_URL_PRIVATE } from "../../constants/compensate";
import { helpCenterValidation } from "../../utils/validator";
import Texterror from "../Texterror";
import { useDispatch, useSelector } from "react-redux";
import pdfIcon from "../../assets/img/pdfIcon.png";
import { FaRegFilePdf } from "react-icons/fa";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Overlay from "react-bootstrap/Overlay";
import NoImage from "../../assets/img/download-no-image.png";

function EditHelpCenterForm() {
  const attachmentRef = useRef(null);
  const { ID } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const customer_code = useSelector((state) => state.compensate.customerCode);
  const [isLoading, setIsLoading] = useState(false);
  const [initialValues, setInitialValues] = useState({
    application_id: "",
    application: "",
    title: "",
    publish_status: "1",
    description: "",
    attachment: "",
    sort: "",
  });
  const [selectedImage, setSelectedImage] = useState(null);
  const [applicationMaster, setApplicationMaster] = useState(null);
  const [initialImageUrl, setInitialImageUrl] = useState(null);
  const [isInitialData, setISInitialData] = useState(false);
  const [Initialfilename, setInitialfilename] = useState("");
  const [imageLoader, setImageLoader] = useState(false);

  const [show, setShow] = useState(false);
  const target = useRef(null);

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {Initialfilename}
    </Tooltip>
  );
  const renderTooltipSelect = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {Initialfilename}
    </Tooltip>
  );

  useEffect(() => {
    const getInitalData = async () => {
      const resp = await axios.post(
        `${API_CALL_URL_PRIVATE}/helpCenter/helpCenterDetail`,
        { _id: ID }
      );
      console.log(resp);
      const Detail = resp?.data?.data?.data;
      console.log(Detail);
      setInitialValues((prev) => {
        return {
          ...prev,
          application_id: Detail?.application_id,
          title: Detail?.title,
          publish_status: Detail?.publish_status,
          description: Detail?.description,
          attachment: Detail?.attachment,
          sort: Detail?.sort,
        };
      });
      setInitialImageUrl(`${resp?.data?.data?.display_path}`);
      setInitialfilename(`${resp?.data?.data?.fileName}`);
      setISInitialData(true);
    };
    getInitalData();
  }, [ID]);
  const onSubmit = (values, onSubmittingProps) => {
    console.log("form Values", values);
    formSubmit(values);
    // setTimeout(() => {
    //   onSubmittingProps.setSubmitting(false);
    // }, [2000]);
  };
  const formSubmit = async (values) => {
    var formData = {
      _id: ID,
      customer_code: parseInt(customer_code),
      title: values.title,
      description: values.description,
      attachment: values.attachment,
      application_id: parseInt(values.application_id),
      publish_status: parseInt(values.publish_status),
      sort: parseInt(values.sort),
    };
    try {
      setIsLoading(true);
      const resp = await axios.post(
        `${API_CALL_URL_PRIVATE}/helpCenter/create`,
        formData
      );
      console.log(resp);
      toast.success(resp?.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
      });
      //   navigate("/admin/application");
    } catch (error) {
      toast.error(error.response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
      });

      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  const getApplicationMaster = async () => {
    const resp = await axios.post(
      `${API_CALL_URL_PRIVATE}/application/applicationList`,
      {
        limit: "100000",
      }
    );
    console.log(resp);
    setApplicationMaster(
      resp?.data?.data?.data?.docs?.filter((e) => e.application_id !== 199)
    );
    console.log(resp);
  };
  useEffect(() => {
    getApplicationMaster();
  }, []);
  function getFileType(link) {
    if (link) {
      return false;
    }
    const pathWithoutQuery = new URL(link).pathname;

    const fileName = pathWithoutQuery.substring(
      pathWithoutQuery.lastIndexOf("/") + 1
    );

    // Extract the file extension from the file name
    const extension = fileName
      .substring(fileName.lastIndexOf(".") + 1)
      .toLowerCase();

    console.log(extension);
    if (extension === "pdf") {
      return true;
    } else {
      return false;
    }
  }

  const handleAttachmentChange = async (e, formik) => {
    formik.setFieldTouched("attachment", true, true);

    const file = e.target.files[0];
    console.log("file", file);

    if (file) {
      const formData = new FormData();
      formData.append("attachment", file);

      try {
        const response = await axios.post(
          `${API_CALL_URL_PRIVATE}/helpCenter/helpCenterAddImage`,
          formData
        );

        if (response.status === 200) {
          console.log("Image uploaded successfully. Path:", response);
          setSelectedImage(response.data.data.displayPath);
          setInitialfilename("");
          formik.setFieldValue("attachment", response.data.data.attachmentPath);
          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
          });
          return true;
        } else {
          toast.error("Failed to Upload Image..! Try Again", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
          });
          return false;
        }
      } catch (error) {
        const errorMessage =
          error.response?.data?.message || error.message || "An error occurred";
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
        });
        if (attachmentRef.current) {
          attachmentRef.current.value = "";
        }
        return false;
      }
    }
  };

  return (
    <>
      {isInitialData ? (
        <Formik
          initialValues={initialValues}
          validate={helpCenterValidation}
          onSubmit={onSubmit}
          enableReinitialize
          validateOnChange
        >
          {(formik) => (
            <div className="main-panel">
              {console.log(formik)}
              <div className="content-wrapper">
                <div className="page-header">
                  <h3 className="page-title">
                    <a href="#" className="badge badge-dark"></a>
                  </h3>
                  <div className></div>
                </div>
                <div className="row">
                  <div class="col-12 grid-margin stretch-card">
                    <div class="card">
                      <FormikForm className="forms-sample">
                        <div class="card-body">
                          <div className="row align-items-center">
                            <div className="col-3">
                              <Link
                                to={`/admin/help-center`}
                                className="f-14 white-text-dark-mode"
                              >
                                <FontAwesomeIcon icon="fa-solid fa-arrow-left " />{" "}
                                Back
                              </Link>
                            </div>
                            <div className="col-6">
                              <h4 class="card-title text-center">
                                Edit Help Center
                              </h4>
                            </div>
                            <div className="col-3 text-end">
                              <Button
                                className="ms-3"
                                variant="success"
                                type="submit"
                                disabled={isLoading}
                              >
                                {isLoading ? "Saving..." : "Save"}
                              </Button>
                            </div>
                          </div>

                          <div class="row">
                            <Form.Group
                              className="col-md-6 mb-3"
                              controlId="exampleForm.c_name"
                            >
                              <Form.Label>Title</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=""
                                name="title"
                                {...formik.getFieldProps("title")}
                              />
                              <ErrorMessage
                                name="title"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-6 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>Applications</Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                className="white"
                                name="application_id"
                                value={formik.values.application_id}
                                onChange={(e) => {
                                  formik.setFieldTouched(
                                    "application_id",
                                    true,
                                    true
                                  );
                                  if (e.target.value !== "") {
                                    formik.setFieldValue(
                                      "application_id",
                                      e.target.value
                                    );
                                  }
                                  // formik.setFieldValue(
                                  //   "customer_status",
                                  //   e.target.value
                                  // );
                                  // console.log(e.target.value);
                                }}
                                onBlur={() => {
                                  formik.setFieldTouched(
                                    "application_id",
                                    true,
                                    true
                                  );
                                }}
                                // onChange={formik.handleChange}
                              >
                                <option value="">select</option>
                                {applicationMaster?.map((e) => {
                                  return (
                                    <option value={e?.application_id}>
                                      {e?.title}
                                    </option>
                                  );
                                })}
                              </Form.Select>
                              <ErrorMessage
                                name="application"
                                component={Texterror}
                              />
                            </Form.Group>

                            <Form.Group
                              controlId="formFile"
                              className="mb-0 col-md-6"
                            >
                              <Form.Label className="w-100 ">
                                <div className="d-flex align-items-center  w-100">
                                  Attachment
                                  {imageLoader && (
                                    <div className="d-flex ms-3">
                                      <span
                                        className="loader"
                                        style={{
                                          width: "15px",
                                          height: "15px",
                                        }}
                                      ></span>
                                    </div>
                                  )}
                                </div>
                              </Form.Label>
                              <Form.Control
                                type="file"
                                name="attachment"
                                ref={attachmentRef}
                                accept="application/pdf"
                                onChange={async (e) => {
                                  setImageLoader(true);
                                  await handleAttachmentChange(e, formik);
                                  setImageLoader(false);
                                }}
                                onBlur={() => {
                                  formik.setFieldTouched(
                                    "attachment",
                                    true,
                                    true
                                  );
                                }}
                              />
                              <ErrorMessage
                                name="attachment"
                                component={Texterror}
                              />
                              {/* {getFileType(
                                selectedImage !== null
                                  ? selectedImage
                                  : initialImageUrl
                              ) ? (
                                <div>
                                  <OverlayTrigger
                                    placement="right"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={renderTooltip}
                                  >
                                    <a
                                      href={
                                        selectedImage !== null
                                          ? selectedImage
                                          : initialImageUrl
                                      }
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <FaRegFilePdf
                                        className="fs-4 my-1"
                                        size={35}
                                      />
                                    </a>
                                  </OverlayTrigger>

                                </div>
                              ) : (
                                <>
                                  {selectedImage === null &&
                                    initialImageUrl && (
                                      <div
                                        style={{
                                          height: "100px",
                                          width: "100px",
                                          marginTop: "10px",
                                        }}
                                      >
                                        <img
                                          src={initialImageUrl}
                                          alt="Upload Preview"
                                          style={{
                                            maxWidth: "100%",
                                            height: "100%",
                                            objectFit: "contain",
                                          }}
                                        />
                                      </div>
                                    )}
                                  {selectedImage && (
                                    <div
                                      style={{
                                        height: "100px",
                                        width: "100px",
                                        marginTop: "10px",
                                      }}
                                    >
                                      <img
                                        src={selectedImage}
                                        alt="Uploaded preview"
                                        style={{
                                          maxWidth: "100%",
                                          height: "100%",
                                          objectFit: "contain",
                                        }}
                                      />
                                    </div>
                                  )}
                                </>
                              )} */}
                              {selectedImage === null && initialImageUrl && (
                                <div>
                                  <OverlayTrigger
                                    placement="right"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={renderTooltip}
                                  >
                                    <a
                                      href={initialImageUrl}
                                      // href={`https://docs.google.com/viewer?url=${encodeURIComponent(
                                      //   initialImageUrl
                                      // )}&embedded=true`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <FaRegFilePdf
                                        className="fs-4 my-2"
                                        size={25}
                                      />
                                    </a>
                                    {/* <iframe title="Pdf Viewer" src={initialImageUrl} height="600" width="800"></iframe>  */}
                                  </OverlayTrigger>
                                </div>
                              )}
                              {selectedImage && (
                                <div>
                                  <a
                                    href={selectedImage}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <FaRegFilePdf
                                      className="fs-4 my-2"
                                      size={25}
                                    />
                                  </a>
                                </div>
                              )}
                            </Form.Group>
                            <Form.Group
                              className="col-md-6 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>Status</Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                className="white"
                                name="publish_status"
                                value={formik.values.publish_status}
                                onChange={(e) => {
                                  formik.setFieldTouched(
                                    "publish_status",
                                    true,
                                    true
                                  );
                                  if (e.target.value !== "") {
                                    formik.setFieldValue(
                                      "publish_status",
                                      e.target.value
                                    );
                                  }
                                  // formik.setFieldValue(
                                  //   "customer_status",
                                  //   e.target.value
                                  // );
                                  // console.log(e.target.value);
                                }}
                                onBlur={() => {
                                  formik.setFieldTouched(
                                    "publish_status",
                                    true,
                                    true
                                  );
                                }}
                                // onChange={formik.handleChange}
                              >
                                <option value="1">Active</option>
                                <option value="0">Inactive</option>
                              </Form.Select>
                              <ErrorMessage
                                name="publish_status"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-6 mb-3"
                              controlId="exampleForm.c_number"
                            >
                              <Form.Label>Sort</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=""
                                name="sort"
                                {...formik.getFieldProps("sort")}
                              />
                              <ErrorMessage name="sort" component={Texterror} />
                            </Form.Group>
                            <Form.Group
                              className="col-md-12 mb-3"
                              controlId="exampleForm.c_name"
                            >
                              <Form.Label>Description</Form.Label>
                              <Form.Control
                                as="textarea"
                                rows={3}
                                placeholder=""
                                name="description"
                                {...formik.getFieldProps("description")}
                              />
                              <ErrorMessage
                                name="description"
                                component={Texterror}
                              />
                            </Form.Group>

                            {/* <Form.Group
                            className="col-md-12 mb-3"
                            controlId="exampleForm.c_email"
                          >
                            <div class="checkbox-item">
                              <input
                                type="checkbox"
                                class="custom-checkbox"
                                onChange={(p) => {
                                  if (p.target.checked) {
                                    formik.setFieldValue(
                                      "show_ps_logo",
                                      parseInt("1")
                                    );
                                  } else {
                                    formik.setFieldValue(
                                      "show_ps_logo",
                                      parseInt("0")
                                    );
                                  }
                                }}
                              />
                              Show PS Logo
                            </div>
                            <ErrorMessage
                              name="customer_address"
                              component={Texterror}
                            />
                          </Form.Group> */}

                            <Form.Group className="col-md-12 mb-3 d-flex justify-content-end">
                              {/* <Button
                              variant="primary"
                              type="button"
                              onClick={() => {
                                formik.resetForm();
                                setSelectedImage(null);
                                // const fileInput =
                                //   document.getElementById("logo");
                                // if (fileInput) {
                                //   fileInput.value = "";
                                // }
                              }}
                            >
                              Reset
                            </Button> */}
                              <Button
                                className="ms-3"
                                variant="success"
                                type="submit"
                                disabled={isLoading}
                              >
                                {isLoading ? "Saving..." : "Save"}
                              </Button>
                            </Form.Group>
                          </div>
                        </div>
                      </FormikForm>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Formik>
      ) : (
        <div className="d-flex align-items-center justify-content-center w-100vw h-100vh">
          <span class="loader" style={{ width: "50px", height: "50px" }}></span>
        </div>
      )}
    </>
  );
}

export default EditHelpCenterForm;
