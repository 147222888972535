import { LogLevel } from "@azure/msal-browser";
export const msalConfig = {
  auth: {
    tenantId: "2d4324d6-05ce-4a68-80dd-50c701974fd6", // Your tenant ID goes here
    clientId: "de57d49d-db51-4c64-9cf3-342fc2e613d6", // Your client (application) ID goes here
    authority:
      "https://login.microsoftonline.com/2d4324d6-05ce-4a68-80dd-50c701974fd6", // Replace the last part with your tenant ID
    navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
  },
  cache: {
    cacheLocation: "localStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            // console.error(message);
            return;
          case LogLevel.Info:
            // console.info(message);
            return;
          case LogLevel.Verbose:
            // console.debug(message);
            return;
          case LogLevel.Warning:
            // console.warn(message);
            return;
        }
      },
    },
  },
};
export const protectedResources = {
  quizApi: {
    path: "/admin/",
    scopes: ["api://c0000def-ab0c-0000-b00d-000f000000b0/CTF.Quiz.Manage"],
  },
};
export const appRoles = {
  Admin: "Admin",
};
