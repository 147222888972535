const Texterror = (prop) => {
  return (
    <>
      <div style={{ color: "red", fontSize: "0.8rem", marginTop: "0.5rem" }}>
        {prop.children}
      </div>
    </>
  );
};

export default Texterror;
