import { THEME_TYPE } from "../constants/theme";

const Theme = {
    [THEME_TYPE.LIGHT]: {
        backgroundBody: "#eee",
        bodyColor: "#404041",
        backgroundTopBar: "#FFF !important",
        backgroundWhite: "#FFF",
        headingColor: "#4B4D4D",
        text: "#rgba(0, 0, 0, 0.87)",
        greyText: "#404041",
        textWhite: "#000",
        divider: "rgba(0, 0, 0, 0.12)",
        shadow: "0 2px 4px 0 hsla(0,0%,87%,.5)",
        colShadow: "0px 2px 4px 0px rgba(223, 223, 223, 0.50)",
        shadowBox: "0px 2px 4px 0px rgba(223, 223, 223, 0.50)",
        stickyShadow: "0px 2px 4px 0px rgb(69 69 69 / 50%)",
        borderColortheme: "0.25rem solid #eeeeee",
        greenb: "#28A745",
        t: "rgba(0, 0, 0, 0.87)",
        circleColor: "#63BC62",
        circleBG: "#FFF",
        fw: "#458FFF",
        // dlcolor:"#7c7c7c",
        dlcolor: "#000",
        arr: "#6f6f6f",
        bgdiabled: "#e7e7e7",
        bgActiveTab: "#e2f3eb",
        colorActiveTab: "#1b711e",
        modalBg: "#eee",
        gr: "#eee",
        border: "#D9D9D9",
        lightBorder: "#dee2e6",
        // filteri: "invert(1)",
        opecity: "5%",
        bri:"rgba(0, 0, 0, 0.2)",
        borderInput: "#d4d4d4",
        bgColorInput: "#eeeeee",
        colorInput: "#000000",
        BgLi: "#d0d0d0",
        IconBg: "rgb(0 0 0 / 44%);",
        dropdownDivider: "#CCC",
        AccordianBg: "#eeeeee",
        AccordianCOlor : "#404041",
        multiselctBg: "#eeeeee",
        multiselctBorder: "#d4d4d4",
        multiselctColor: "#000000",
        multiselctHighlight: "#FFF"


            },
    [THEME_TYPE.DARK]: {
        backgroundBody: "#000",
        bodyColor: "#FFF",
        backgroundTopBar: "#191C24 !important",
        backgroundWhite: "#FFF",
        headingColor: "#FFF",
        text: "#rgba(0, 0, 0, 0.87)",
        greyText: "#FFF !important",
        textWhite: "#FFF",
        divider: "rgba(0, 0, 0, 0.12)",
        shadow: "0px 2px 4px 0px rgb(28 28 28 / 50%)",
        whiteClor: "#FFF",
        borderColortheme: "0.25rem solid #000",
        filter: "brightness(10)",
        colShadow: "0px 2px 4px 0px rgb(0 0 0 / 50%)",
        shadowBox: "0px 2px 4px 0px rgb(0 0 0 / 50%)",
        stickyShadow: "0px 2px 4px 3px #191C24",
        greenb: "#000",
        t: "#FFF",
        circleColor: "#FFF",
        circleBG: "#191c23",
        dlcolor: "#FFF",
        // fw:"#494949",
        arr: "#FFF",
        bgdiabled: "#303030",
        bgActiveTab: "#013800",
        colorActiveTab: "#FFF",
        modalBg: "#272727",
        bri:  "rgba(255, 255, 255, 0.1)",

        // ----------------Only in Dark Mode-------------------
        trackBg: "#3B3B3B",
        borderColor: "#7f7f7f",
        bgdark: "#1f1f1f !important",
        filteri: "invert(1)",
        border: "#7f7f7f",
        bd2: "#000000",
        borderTop: "1px solid rgb(221 195 195 / 18%)",
        disabled: "#494949",
        btnClose: "#000 !important",
        datepickerbg: "transparent",
        fw: "400",
        lightBorder: "#7f7f7f",
        opecity: "48%",
        borderInput: "#2c2e33",
        bgColorInput: "#2a3038",
        colorInput: "#FFF",
        dropdownDivider: "#333",
        AccordianBg: "#2a3038",
        AccordianCOlor : "#FFF",
        AccordianArrow: "brightness(12.5)",
        AccodianBorder : "#353535",
        multiselctBg: "#2a3038",
        multiselctBorder: "#4b4b4b",
        multiselctColor: "#FFF",
        multiselctHighlight: "#000"
    },
};

const ThemeStyle = (theme) => `
 .main-sect {background-color: ${Theme[theme].backgroundBody} !important;}
 .accordion-button {background-color: ${Theme[theme].AccordianBg} !important; color: ${Theme[theme].AccordianCOlor} !important; }

.searchWrapper { border: 1px solid ${Theme[theme].multiselctBorder} !important; background-color: ${Theme[theme].multiselctBg} !important;}
.optionListContainer {border: 1px solid ${Theme[theme].multiselctBorder} !important;background-color: ${Theme[theme].multiselctBg} !important; color: ${Theme[theme].multiselctColor} !important;}
.highlightOption, .multiSelectContainer ul li:hover { background: ${Theme[theme].multiselctHighlight} !important; color: ${Theme[theme].multiselctColor};}
.multiSelectContainer input::placeholder{color: ${Theme[theme].multiselctColor};}


  th:nth-child(1), td:nth-child(1) {  background-color:${Theme[theme].backgroundTopBar};}
  th:nth-child(2), td:nth-child(2) { background-color:${Theme[theme].backgroundTopBar};}
  th:nth-child(3), td:nth-child(3) { background-color:${Theme[theme].backgroundTopBar};}
  th:nth-child(4), td:nth-child(4) { background-color:${Theme[theme].backgroundTopBar};}
  th:nth-child(5), td:nth-child(5) { background-color:${Theme[theme].backgroundTopBar};}
  th:nth-child(6), td:nth-child(6) { background-color:${Theme[theme].backgroundTopBar};}
  th:nth-child(7), td:nth-child(7) { background-color:${Theme[theme].backgroundTopBar};}
  th:nth-child(8), td:nth-child(8) { background-color:${Theme[theme].backgroundTopBar};} 
  .border-bottom-table th{ background-color:${Theme[theme].backgroundTopBar};} 

 .accordion-button::after{filter:  ${Theme[theme].AccordianArrow} !important;}
 .accordion-item { border: 1px solid ${Theme[theme].AccodianBorder} !important;}
 .accordion-body{color: ${Theme[theme].AccordianCOlor} !important;}
body, .gmoaZX , .jKSUUS, .drdxRs, .kgetWD, .kgetWD, .ihuXMY, .gzLRTF , .card , .table th, .table td , .anticon, .daMSOQ, .edNaST, .bgOhO, .fxdMBY, .eLuPUv, .eLuPUv, .dhyzLV    {color: ${Theme[theme].bodyColor} !important;}
.dpdown .dropdown-item.active, .dpdown .dropdown-item:active {color: ${Theme[theme].bodyColor} !important;}
.kgetWD span,  .eLuPUv span, .eLuPUv span {color: rgb(99, 108, 145) !important;}
.dropdown-header{color: ${Theme[theme].bodyColor};}

.bg-white, .jKnUfW, .ftpquH, .ihuXMY, .gzLRTF, .card, .fJAsTa, .jFmQkz, .daMSOQ, .dhyzLV {background-color: ${Theme[theme].backgroundTopBar};}
.text-color {color: ${Theme[theme].text};}
.box {border: 1px solid ${Theme[theme].divider}}
button.dropdown-toggle {color: ${Theme[theme].bodyColor} !important;}
header.main-sect {background-color: ${Theme[theme].backgroundTopBar};}
header.main-sect {box-shadow: ${Theme[theme].shadow}}
.sidebar {background-color: ${Theme[theme].backgroundTopBar}}
.h-half{background-color: ${Theme[theme].backgroundTopBar}}
.sidebar ul li a {color: ${Theme[theme].greyText}}
.sidebar {box-shadow: ${Theme[theme].shadow}}
.color-black {color: ${Theme[theme].textWhite}}
.widget-tabs h3{color: ${Theme[theme].textWhite}}
.widget-tabs p{color: ${Theme[theme].greyText}}
.sp1{color: ${Theme[theme].textWhite}}
.sp2{color: ${Theme[theme].greyText}}
table{color: ${Theme[theme].greyText}}
.box-heading{color: ${Theme[theme].headingColor}}
.ind-table th{color: ${Theme[theme].headingColor}}
.dropdown-toggle:empty::after{color: ${Theme[theme].whiteClor}}
.white-bg{background-color: ${Theme[theme].backgroundTopBar}}
.white-bg{color: ${Theme[theme].greyText}}
.border-bottom-5{border-bottom: ${Theme[theme].borderColortheme}}
.border-top-5{border-top: ${Theme[theme].borderColortheme}}
table thead tr th .ms-2 img {filter: ${Theme[theme].filter}}
.bright img {filter: ${Theme[theme].filter}}
.table-hover>tbody>tr:hover>* {color:${Theme[theme].whiteClor}}
.scroll-table tbody::-webkit-scrollbar-thumb { background: #848689;}
.scroll-table tbody::-webkit-scrollbar-track {background-color: ${Theme[theme].trackBg}}

.table-wrapper::-webkit-scrollbar-thumb { background: #848689;}
.table-wrapper::-webkit-scrollbar-track {background-color: ${Theme[theme].trackBg}}


.width-in::-webkit-scrollbar-thumb { background: #848689;}
.width-in::-webkit-scrollbar-track {background-color: ${Theme[theme].trackBg}}
.overlfow-auto::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
.overlfow-auto::-webkit-scrollbar-thumb { background: #848689; border-radius: 30px;}
.overlfow-auto::-webkit-scrollbar-track { border-radius: 30px;background-color: ${Theme[theme].trackBg}}

.table-responsive::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  .table-responsive::-webkit-scrollbar-track {
    background: #DEEBFD;
    border-radius: 30px;
}
.table-responsive::-webkit-scrollbar-thumb { background: #848689; border-radius: 30px;}
.table-responsive::-webkit-scrollbar-track { border-radius: 30px;background-color: ${Theme[theme].trackBg}}

.table-responsive::-webkit-scrollbar-thumb { background: #848689;}
.table-responsive::-webkit-scrollbar-track {background-color: ${Theme[theme].trackBg}}

tbody, td, tfoot, th, thead, tr {border-color:${Theme[theme].borderColor}}
.tb2 tbody tr.bt {border-top: 1px solid ${Theme[theme].borderColor} !important}
.form-select, .col .form-control{color: ${Theme[theme].bodyColor} !important;}
.css-1dimb5e-singleValue{color: ${Theme[theme].bodyColor} !important;}
.modal-content{background-color: ${Theme[theme].backgroundTopBar};}
.btn-close {filter: ${Theme[theme].filteri}}
.form-select:disabled { background-color: ${Theme[theme].backgroundTopBar} ;  border: 1px solid ${Theme[theme].border} !important;}
.searchlistwrap {   border: 1px solid ${Theme[theme].backgroundBody};  background: ${Theme[theme].backgroundTopBar};}
.searchlistwrap ul li:hover {background-color: ${Theme[theme].backgroundBody};}
.css-1nmdiq5-menu{background-color: ${Theme[theme].backgroundTopBar};}
.css-1nmdiq5-menu li:hover{background-color: ${Theme[theme].backgroundTopBar};}
.col-bg{background-color: ${Theme[theme].backgroundTopBar};
box-shadow:${Theme[theme].colShadow}}
.search-box{box-shadow:${Theme[theme].colShadow}}
.shadow-box{box-shadow: ${Theme[theme].shadowBox};}
.bd-2{border-top:7px solid  ${Theme[theme].bd2};}
.green-b { color:${Theme[theme].bodyColor}; border-color: ${Theme[theme].bodyColor}; background: ${Theme[theme].bd2};}
.dropdown-menu.show{background-color: ${Theme[theme].backgroundTopBar} ;}
:where(.css-dev-only-do-not-override-1adbn6x).ant-layout .ant-layout-sider,
:where(.css-dev-only-do-not-override-1adbn6x).ant-menu-dark,
:where(.css-dev-only-do-not-override-1adbn6x).ant-menu-dark > .ant-menu,
:where(.css-1adbn6x).ant-layout .ant-layout-sider,
:where(.css-1adbn6x).ant-menu-dark,
:where(.css-1adbn6x).ant-menu-dark > .ant-menu, .logo, .iNaApk, .fnRXTs

{background-color: ${Theme[theme].backgroundTopBar} ;}
.dropdown-item {color: ${Theme[theme].bodyColor} !important;}
.dropdown-divider{border-top:${Theme[theme].borderTop}}
.t {color: ${Theme[theme].t};}
th.coll{color: ${Theme[theme].headingColor}}
.transparent-btn{color: ${Theme[theme].t};}
.button-disable:disabled{ background-color: ${Theme[theme].bgdark} ; border: 1px solid ${Theme[theme].border} !important;}
.transparent-btn{    border: 1px solid ${Theme[theme].border};}
.spacing-table td {border-color: ${Theme[theme].border};}
.button-shadow:hover{border-color: ${Theme[theme].border};}
span.circle-i{ color: ${Theme[theme].circleColor}; border: 1px solid ${Theme[theme].circleColor}; background: ${Theme[theme].circleBG};}
.form-control, .hHqQSd, .iQGjIR{background: ${Theme[theme].circleBG}; color:  ${Theme[theme].textWhite} !important;}
.login-screen .form-control{     background: #FFF !important; color: #000 !important;}
.hHqQSd, .iQGjIR{    border: 1px solid  ${Theme[theme].bri}}
.form-control[disabled]{background: ${Theme[theme].disabled}}
.fw{background:${Theme[theme].fw}}
.btn-close {color: ${Theme[theme].btnClose};}
.form-control:focus {  background-color: inherit !important;}
.react-datepicker-wrapper input{background: ${Theme[theme].datepickerbg};color: ${Theme[theme].bodyColor};}
.table th{color: ${Theme[theme].headingColor}}
.ft-table-v.table td{color: ${Theme[theme].dlcolor} !important;}
.white-text-dark-mode, .modal-title, .form-label{color: ${Theme[theme].headingColor} !important;}
#mySidenav{background-color: ${Theme[theme].backgroundBody};box-shadow:${Theme[theme].stickyShadow};}
.notify-tabs{background-color: ${Theme[theme].backgroundTopBar};box-shadow:${Theme[theme].colShadow};}
.pdf{filter: ${Theme[theme].filteri};}
.apexcharts-tooltip { color: #000 !important;}
.ww1{color: ${Theme[theme].textWhite}; font-family: var(--cf-fp);}
.searchlistwrap .searchfield{background-color: ${Theme[theme].backgroundBody};}
.searchlistwrap .searchfield .searchicon img{filter: contrast(0.1);}
.css-1xc3v61-indicatorContainer svg path {fill: ${Theme[theme].arr};}
.dropdown-item.active, .dropdown-item:active { background: ${Theme[theme].backgroundBody};; color:  ${Theme[theme].bodyColor};}
.nav-pills .v-tab{ color:  ${Theme[theme].bodyColor};}
.css-166bipr-Input, .css-1cfo1cf{color: ${Theme[theme].bodyColor} !important;}
.dropdown-item:focus, .dropdown-item:hover {color:   ${Theme[theme].bodyColor};background-color: ${Theme[theme].backgroundBody};}
.profile-form .form-control[disabled] { cursor: not-allowed !important;background-color: ${Theme[theme].bgdiabled} !important;}
.nav-pills .v-tab.active, .nav-pills .show>.v-tab { color:  ${Theme[theme].colorActiveTab}; background-color: ${Theme[theme].bgActiveTab};}
.dropdownwithsearch .input-group .form-control::placeholder{color:  ${Theme[theme].bodyColor} !important;}
.missed-meal-break .modal-content { background-color: ${Theme[theme].modalBg} !important;}
.border-bottom-5.gr{ border-color: ${Theme[theme].gr} !important;}
.border-top-5.gr{ border-color: ${Theme[theme].gr} !important;}
.ft-table-v .center-border {
    border-left: 1px solid ${Theme[theme].lightBorder} !important;
    border-right: 1px solid ${Theme[theme].lightBorder} !important;
}
.table.ft-table-v th{color: ${Theme[theme].dlcolor}}
.ft-title{color: ${Theme[theme].dlcolor}}
.table {--bs-table-hover-bg: rgb(0 0 0 / ${Theme[theme].opecity});}
.border-less #react-select-3-placeholder {color: ${Theme[theme].dlcolor}}
.css-1dimb5e-singleValue { color: ${Theme[theme].dlcolor} !important;}
.custom-select-v button, .custom-select-v button{background-color: transparent !important; color:  ${Theme[theme].dlcolor} !important;}
.custom-select-v button + div {   border: 1px solid ${Theme[theme].backgroundBody}  !important;  background: ${Theme[theme].backgroundTopBar} ;margin: 0 !important;}
.custom-select-v button:hover { background: transparent !important; color:  ${Theme[theme].dlcolor} !important;}

// .custom-select-v .css-1n6sfyn-MenuList>div, 
// .custom-select-v .css-qr46ko>div{background-color: transparent; font-size: 16px;    color: inherit;font-size:14px}
.custom-select-v .css-1n6sfyn-MenuList>div, 
.custom-select-v .css-qr46ko>div{font-size:14px}
.custom-select-v .css-1n6sfyn-MenuList>div:active,
 .custom-select-v .css-1n6sfyn-MenuList>div:hover,
 .custom-select-v .css-qr46ko>div:active,
 .custom-select-v .css-qr46ko>div:hover
 
 { background-color:  ${Theme[theme].backgroundBody} !important; color:  ${Theme[theme].dlcolor} !important;}
 .css-d7l1ni-option, .css-d7l1ni-option{background-color:transparent;}
 .css-d7l1ni-option{background-color: ${Theme[theme].backgroundBody} !important;}
.custom-select-v .css-2o2t8s-control, .css-3f3cyk-control{background-color: ${Theme[theme].backgroundBody} !important;}
.custom-select-v .css-2o2t8s-control inpur, .css-3f3cyk-control input{color: ${Theme[theme].bodyColor} !important;
    font-size: 16px !important;
    font-weight: 400 !important;}
    .custom-select-v .css-1n6sfyn-MenuList>div, .custom-select-v .css-qr46ko>div {
        font-size: 16px;
    }
.rt-v .next{background-color: ${Theme[theme].backgroundTopBar}; box-shadow: ${Theme[theme].shadowBox};}
.rt-v .prev{background-color: ${Theme[theme].backgroundTopBar}; box-shadow: ${Theme[theme].shadowBox};}

element.style {
}
.card .card-body .form-control, .react-datepicker-wrapper input, .card .card-body .form-select, .asColorPicker-input, .dataTables_wrapper select, .jsgrid .jsgrid-table .jsgrid-filter-row input[type="text"], .jsgrid .jsgrid-table .jsgrid-filter-row select, .jsgrid .jsgrid-table .jsgrid-filter-row input[type="number"], .select2-container--default .select2-selection--single, .select2-container--default .select2-selection--single .select2-search__field, .typeahead, .tt-query, .tt-hint ,
.custom-normal-design .css-13cymwt-control, .custom-normal-design .css-t3ipsp-control
{
    border: 1px solid ${Theme[theme].borderInput} !important;
    background-color: ${Theme[theme].bgColorInput} !important;
    color:  ${Theme[theme].colorInput} !important;

}
:where(.css-dev-only-do-not-override-1adbn6x).ant-menu-inline.ant-menu-root .ant-menu-item > .ant-menu-title-content, :where(.css-dev-only-do-not-override-1adbn6x).ant-menu-inline.ant-menu-root .ant-menu-submenu-title > .ant-menu-title-content 
,
:where(.css-1adbn6x).ant-menu-dark, :where(.css-1adbn6x).ant-menu-dark>.ant-menu 
,
:where(.css-1adbn6x).ant-menu .ant-menu-submenu-inline:hover, :where(.css-1adbn6x).ant-menu .ant-menu-submenu:hover, :where(.css-1adbn6x).ant-menu-dark .ant-menu-item-selected, :where(.css-1adbn6x).ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item:not(.ant-menu-item-selected):hover, :where(.css-1adbn6x).ant-menu-dark>.ant-menu .ant-menu-item-selected, :where(.css-1adbn6x).ant-menu-dark>.ant-menu:not(.ant-menu-horizontal) .ant-menu-item:not(.ant-menu-item-selected):hover, :where(.css-dev-only-do-not-override-1adbn6x).ant-menu .ant-menu-submenu-inline:hover, :where(.css-dev-only-do-not-override-1adbn6x).ant-menu .ant-menu-submenu:hover, :where(.css-dev-only-do-not-override-1adbn6x).ant-menu-dark .ant-menu-item-selected, :where(.css-dev-only-do-not-override-1adbn6x).ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item:not(.ant-menu-item-selected):hover, :where(.css-dev-only-do-not-override-1adbn6x).ant-menu-dark>.ant-menu .ant-menu-item-selected, :where(.css-dev-only-do-not-override-1adbn6x).ant-menu-dark>.ant-menu:not(.ant-menu-horizontal) .ant-menu-item:not(.ant-menu-item-selected):hover
{
    color: ${Theme[theme].bodyColor};
}
:where(.css-dev-only-do-not-override-1adbn6x).ant-menu-dark:not( .ant-menu-horizontal ) .ant-menu-item:not(.ant-menu-item-selected):hover, :where(.css-dev-only-do-not-override-1adbn6x).ant-menu-dark .ant-menu-item-selected
:where(.css-1adbn6x).ant-menu .ant-menu-submenu-inline:hover, :where(.css-1adbn6x).ant-menu .ant-menu-submenu:hover, :where(.css-1adbn6x).ant-menu-dark .ant-menu-item-selected, :where(.css-1adbn6x).ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item:not(.ant-menu-item-selected):hover, :where(.css-1adbn6x).ant-menu-dark>.ant-menu .ant-menu-item-selected, :where(.css-1adbn6x).ant-menu-dark>.ant-menu:not(.ant-menu-horizontal) .ant-menu-item:not(.ant-menu-item-selected):hover, :where(.css-dev-only-do-not-override-1adbn6x).ant-menu .ant-menu-submenu-inline:hover, :where(.css-dev-only-do-not-override-1adbn6x).ant-menu .ant-menu-submenu:hover, :where(.css-dev-only-do-not-override-1adbn6x).ant-menu-dark .ant-menu-item-selected, :where(.css-dev-only-do-not-override-1adbn6x).ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item:not(.ant-menu-item-selected):hover, :where(.css-dev-only-do-not-override-1adbn6x).ant-menu-dark>.ant-menu .ant-menu-item-selected, :where(.css-dev-only-do-not-override-1adbn6x).ant-menu-dark>.ant-menu:not(.ant-menu-horizontal) .ant-menu-item:not(.ant-menu-item-selected):hover
{
    background-color: ${Theme[theme].BgLi}
}
:where(.css-dev-only-do-not-override-1adbn6x).ant-menu .ant-menu-item .ant-menu-item-icon, :where(.css-dev-only-do-not-override-1adbn6x).ant-menu .ant-menu-submenu-title .ant-menu-item-icon
,
:where(.css-1adbn6x).ant-menu .ant-menu-item .ant-menu-item-icon, :where(.css-1adbn6x).ant-menu .ant-menu-submenu-title .ant-menu-item-icon
{
    background-color: ${Theme[theme].IconBg} 
}
.react-datepicker-wrapper input {
    font-size: 0.875rem;
    padding: 0.625rem 0.6875rem;
    height: 43px;    border-radius: 2px !important;
}
button.add.btn.btn-primary.todo-list-add-btn {background-color: ${Theme[theme].bodyColor} !important; color:  ${Theme[theme].backgroundBody} !important;}
.dropdown-divider{border-top: 1px solid ${Theme[theme].dropdownDivider}}

main::-webkit-scrollbar-thumb { background: #848689;}
main::-webkit-scrollbar-track {background-color: ${Theme[theme].trackBg}}
.ant-layout-sider-children ul.ant-menu::-webkit-scrollbar-thumb { background: #848689;}
.ant-layout-sider-children ul.ant-menu::-webkit-scrollbar-track {background-color: ${Theme[theme].trackBg}}
.filter-table th:after, .filter-table th:before { color: ${Theme[theme].textWhite};}
.loader {border: 3px solid  ${Theme[theme].textWhite} ;}
.form-control[type="file"]:not(:disabled):not([readonly]) {
    color: ${Theme[theme].textWhite} !important;
}
`;

export default ThemeStyle;
