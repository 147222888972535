import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ErrorMessage, Formik, Form as FormikForm } from "formik";
import { toast } from "react-toastify";
import axios from "../../axios";
import { API_CALL_URL_PRIVATE } from "../../constants/compensate";
import Texterror from "../Texterror";
import { useSelector } from "react-redux";
//  import { TargetTypeValidation } from "../../utils/validator"; // Assuming this file exists

function EditTargetType() {
  const IsNavigate = useSelector((state) => state.compensate.IsNavigate);
  const { id } = useParams();
  const navigate = useNavigate();
  const customer_code = useSelector((state) => state.compensate.customerCode);
  const [isLoading, setIsLoading] = useState(false);
  const [isInitialData, setISInitialData] = useState(false);
  const [initialValues, setInitialValues] = useState({
    target_type_id: "",
    target_type: "",
    customer_code: "",
  });

  // useEffect(() => {
  //   if (IsNavigate) {
  //     navigate("/admin/targettype");
  //   }
  // }, [customer_code]);
  console.log(">> id going to api", id);
  useEffect(() => {
    const getInitalData = async () => {
      const resp = await axios.post(
        `${API_CALL_URL_PRIVATE}/targettype/targettypeDetail`,

        { _id: id, customer_code: parseInt(customer_code) }
      );
      console.log(">> response for edit form", resp);
      const Detail = resp?.data?.data?.target_type_details;
      setInitialValues((prev) => {
        return {
          ...prev,
          customer_code: Detail?.customer_code,
          target_type_id: Detail?.target_type_id,
          target_type: Detail?.target_type,
        };
      });
      setISInitialData(true);
    };

    getInitalData();
  }, [id]);
  const onSubmit = (values, onSubmittingProps) => {
    formSubmit(values);
  };

  const formSubmit = async (values) => {
    const formData = {
      _id: id,
      customer_code: parseInt(customer_code),
      target_type_id: values.target_type_id,
      target_type: values.target_type,
    };
    try {
      setIsLoading(true);
      const resp = await axios.post(
        `${API_CALL_URL_PRIVATE}/targettype/targettypeAddUpdate`,

        formData
      );
      toast.success(resp?.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
      });
      navigate("/admin/targettype");
    } catch (error) {
      toast.error(error.response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
      });
    } finally {
      setIsLoading(false);
    }
  };
  const validate = (values) => {
    const errors = {};
    if (!values.target_type_id) {
      errors.target_type_id = "Required";
    }
    if (!values.target_type) {
      errors.target_type = "Required";
    }
    return errors;
  };
  console.log(">>>I", isInitialData);
  return (
    <>
      {isInitialData ? (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validate={validate}
          enableReinitialize
          validateOnChange
        >
          {(formik) => (
            <div className="main-panel">
              <div className="content-wrapper">
                <div className="page-header">
                  <h3 className="page-title">
                    <a href="#" className="badge badge-dark"></a>
                  </h3>
                  <div className></div>
                </div>
                <div className="row">
                  <div className="col-12 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body">
                        <div className="d-flex justify-content-between">
                          <h4 className="card-title">Edit Target Type</h4>
                          <Link
                            to={`/admin/targettype`}
                            className="f-14 white-text-dark-mode"
                          >
                            <FontAwesomeIcon icon="fa-solid fa-arrow-left " />{" "}
                            Back
                          </Link>
                        </div>

                        <FormikForm className="forms-sample">
                          <div className="row">
                            <Form.Group
                              className="col-md-6 mb-3"
                              controlId="exampleForm.t_code"
                            >
                              <Form.Label>Target Type Id</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=""
                                name="target_type_id"
                                {...formik.getFieldProps("target_type_id")}
                                readOnly
                              />
                              <ErrorMessage
                                name="target_type_id"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-6 mb-3"
                              controlId="exampleForm.t_name"
                            >
                              <Form.Label>Target Type Name</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=""
                                name="target_type"
                                {...formik.getFieldProps("target_type")}
                              />
                              <ErrorMessage
                                name="target_type"
                                component={Texterror}
                              />
                            </Form.Group>

                            <Form.Group className="col-md-12 mb-3 d-flex justify-content-end">
                              <Button
                                className="ms-3"
                                variant="success"
                                type="submit"
                                disabled={isLoading}
                              >
                                {isLoading ? "Saving..." : "Save"}
                              </Button>
                            </Form.Group>
                          </div>
                        </FormikForm>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Formik>
      ) : (
        <div className="d-flex align-items-center justify-content-center w-100vw h-100vh">
          <span
            className="loader"
            style={{ width: "50px", height: "50px" }}
          ></span>
        </div>
      )}
    </>
  );
}

export default EditTargetType;
