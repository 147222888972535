import React, { useState, useRef } from "react";
import ReactSelect, { components } from "react-select";
import styled from "styled-components";
import useMenuPlacement from "../Hooks/useMenuPlacement";

const ListRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const DropdownIndicator = styled(components.DropdownIndicator)`
  padding: 4px;
`;

const InputBorder = styled.div`
  border: 1px solid rgb(87, 87, 91);
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: auto;
  height: auto;
  width: 100%;
  display: flex;

  img {
    height: 18px;
    width: 18px;
  }

  input {
    font: 1.1rem;
  }
  input::placeholder {
    font-size: 0.8rem !important;
    color: ${(props) => (props.themeColor ? "#94969C" : "#8A94A6")};
  }
`;

export const InputBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 0.7rem;
`;

export const InputLable = styled.label`
  font-size: 0.8rem;
  font-weight: 500;
  margin-bottom: 0;
  color: ${(props) => (props.themeColor ? "#CECFD2" : "#363a44")};
  margin-bottom: 0;
  text-transform: capitalize;

  @media (max-width: 351px) {
    font-size: 0.8rem;
  }
`;

export const InBox = styled.div`
  display: flex;
  color: ${(props) => (props.themeColor ? "#94969C" : "#8A94A6")};
  font-size: 0.8rem;
  align-items: center;
  width: 10%;

  img {
    height: 9px;
    width: 9px;
    object-fit: contain;
    margin-left: 4px;
  }
`;

const MultiSelect = ({
  options,
  value,
  onChange,
  placeholder,
  isSelectAll,
  error,
  type,
  icon,
  noBorderBottom,
  label,
  star,
  optional,
  theme,
  parentRef, // Parent reference passed as a prop
  ...props
}) => {
  const [selectInput, setSelectInput] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef(null);

  const menuPlacement = useMenuPlacement(selectRef, parentRef); // Use the custom hook

  const filterOptions = (options, input) =>
    options.filter((option) =>
      option.label.toLowerCase().includes(input.toLowerCase())
    );

  const Option = (props) => {
    const { getValue, setValue, data, options } = props;

    const toggleSelection = (e) => {
      e.preventDefault();
      e.stopPropagation();

      const allSelectedOptions = getValue();
      const isSelected = allSelectedOptions.some(
        (option) => option.value === data.value
      );

      if (data.value === "") {
        const isAllSelected = options.every((option) =>
          allSelectedOptions.some((selected) => selected.value === option.value)
        );

        if (isAllSelected) {
          setValue([]);
        } else {
          setValue(options);
        }
      } else {
        if (isSelected) {
          setValue(
            allSelectedOptions.filter((option) => option.value !== data.value)
          );
        } else {
          setValue([...allSelectedOptions, data]);
        }
      }
    };

    return (
      <components.Option {...props}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div>
            <input
              type="checkbox"
              className="custom-checkbox"
              checked={props.isSelected}
              onChange={toggleSelection}
              onClick={(e) => e.stopPropagation()}
              style={{ marginRight: "10px" }}
            />
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <label
              onClick={toggleSelection}
              style={{
                cursor: "pointer",
                userSelect: "none",
                margin: "0",
                padding: "0",
              }}
            >
              {props.label}
            </label>
          </div>
        </div>
      </components.Option>
    );
  };

  const Input = (props) => (
    <components.Input {...props} autoFocus={props.selectProps.menuIsOpen}>
      {props.children}
    </components.Input>
  );

  const customFilterOption = (option, inputValue) => {
    return option.label.toLowerCase().includes(inputValue.toLowerCase());
  };

  const onInputChange = (inputValue, actionMeta) => {
    if (actionMeta.action === "input-change") setSelectInput(inputValue);
    else if (actionMeta.action === "menu-close" && selectInput !== "")
      setSelectInput("");
  };

  const onKeyDown = (e) => {
    if ((e.key === " " || e.key === "Enter") && !selectInput)
      e.preventDefault();
  };

  const handleChange = (selected) => {
    onChange(selected);
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      outline: state.isFocused ? "none" : null,
      boxShadow: state.isFocused ? "none" : null,
      backgroundColor: "transparent",
      textTransform: "capitalize",
      border: "none",
      borderRadius: "8px",
      fontWeight: "600",
      "&:hover": {
        outline: "none",
        boxShadow: "none",
      },
    }),
    dropdownIndicator: (styles) => ({
      ...styles,
      padding: 4,
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: "0.8rem",
      textTransform: "capitalize",
      fontWeight: "500",
      backgroundColor: state.isSelected
        ? theme
          ? "transparent"
          : "transparent"
        : state.isFocused
        ? theme
          ? "transparent"
          : "transparent"
        : theme
        ? "transparent"
        : "transparent",
      color: state.isSelected
        ? theme
          ? "#000000"
          : "#ffffff"
        : theme
        ? "#000000"
        : "#ffffff",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      fontSize: "0.8rem",
    }),
    placeholder: (provided, state) => ({
      ...provided,
      color: "#000",
      fontSize: "0.8rem",
      fontWeight: "400",
    }),
    menu: (provided, state) => ({
      ...provided,
      zIndex: 9999,
      backgroundColor: theme ? "#ffffff" : "rgb(42,48,56)",
      border: "none",
      borderRadius: "8px",
      boxShadow: "0px 2px 4px -1px #0000000F, 0px 4px 6px -1px #0000001A",
      position: "absolute",
    }),
    MenuList: (provided, state) => ({
      ...provided,
      padding: "0px !important",
      backgroundColor: `${theme ? "#0C111D" : "#ffffff"} !important`,
    }),
    multiValue: (provided) => ({
      ...provided,
      borderRadius: "32px",
      backgroundColor: "rgb(246,89,28)",
      padding: "3px 9px",
      color: "white",
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      fontSize: "0.8rem",
      color: "white",
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      cursor: "pointer",
      borderRadius: "4px",
      backgroundColor: "transparent",
    }),
  };

  const HideMultiValue = () => null;

  const StyledPlaceholder = styled.div`
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: ${({ Theme }) => (Theme ? "#000" : "#fff")};
    pointer-events: none;
    font-size: 12px;
  `;

  const CustomControl = (props) => {
    return (
      <components.Control {...props}>
        {props.children}
        {<StyledPlaceholder Theme={theme}>{placeholder}</StyledPlaceholder>}
      </components.Control>
    );
  };

  const ClearButton = () => (
    <button
      type="button"
      onClick={() => handleChange([])}
      style={{
        margin: "5px 10px 10px 10px",
        cursor: "pointer",
        background: "rgb(3,144,231)",
        borderRadius: "5px",
        padding: "5px 10px",
        border: "none",
        fontSize: "12px",
        fontWeight: "550",
      }}
    >
      Clear All
    </button>
  );

  const Menu = (props) => (
    <components.Menu {...props}>
      {props.children}
    </components.Menu>
  );

  return (
    <InputBox>
      <InputLable themeColor={theme}>
        {label}
        {star ? <span style={{ color: "#F6591C" }}>*</span> : ""}
        {optional ? <span style={{ color: "#667085" }}> (Optional)</span> : ""}
      </InputLable>
      <InputBorder error={error} themeColor={theme}>
        <div ref={selectRef} style={{ width: "100%" }}>
          <ReactSelect
            {...props}
            placeholder=""
            inputValue={selectInput}
            onInputChange={onInputChange}
            onKeyDown={onKeyDown}
            options={isSelectAll ? [...options] : options}
            onChange={handleChange}
            components={{
              Option: Option,
              Input,
              MultiValue: HideMultiValue,
              Control: CustomControl,
              Menu,
            }}
            filterOption={customFilterOption}
            menuPlacement={menuPlacement}
            styles={customStyles}
            isMulti
            value={value}
            onMenuOpen={() => setIsOpen(true)}
            onMenuClose={() => setIsOpen(false)}
            closeMenuOnSelect={false}
            tabSelectsValue={false}
            hideSelectedOptions={false}
            isSearchable={true}
            isClearable={false}
            backspaceRemovesValue={false}
            blurInputOnSelect={false}

            // menuPortalTarget={document.body}
            // menuPosition="fixed"
          />
        </div>
      </InputBorder>
    </InputBox>
  );
};

export default MultiSelect;
