import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import styled from "styled-components";
import downArrowIcon from "../../assets/img/down-arrow.png";

// Styled container for the editor
const EditorContainer = styled.div`
  width: 100%;
  /* background: ${({ theme }) =>
    theme ? "white" : "rgb(42,48,56)"} !important; */
  margin-top: 1rem;

  .ql-container {
    min-height: 200px !important;
  }
  .ql-editor {
    min-height: 200px !important;
    background: ${({ themeColor }) => (themeColor ? "#fff" : "rgb(42,48,56)")};
  }

  .ql-toolbar.ql-snow {
    border: ${({ themeColor }) =>
      themeColor ? "2px solid #ced1d1" : "2px solid rgb(81, 86, 91)"};
    background: ${({ themeColor }) => (themeColor ? "white" : "rgb(42,48,56)")};
  }
  .ql-container.ql-snow {
    border: ${({ themeColor }) =>
      themeColor ? "2px solid #ced1d1" : "2px solid rgb(81, 86, 91)"};
  }
  .ql-snow .ql-stroke {
    stroke: ${({ themeColor }) => (themeColor ? "#000" : "#fff")};
  }
  .ql-snow .ql-fill,
  .ql-snow .ql-stroke.ql-fill {
    fill: ${({ themeColor }) => (themeColor ? "#000" : "#fff")};
  }
  .ql-snow .ql-picker {
    color: ${({ themeColor }) => (themeColor ? "#000" : "#fff")};
  }
  .ql-snow .ql-picker-options {
    background: ${({ themeColor }) => (themeColor ? "white" : "rgb(42,48,56)")};
  }
`;

const TextEditor = ({ value, onChange, themeProp }) => {
  return (
    <EditorContainer themeColor={themeProp}>
      <ReactQuill
        value={value}
        onChange={onChange}
        modules={modules}
        formats={formats}
      />
    </EditorContainer>
  );
};
// const modules = {
//     toolbar: [
//       ["bold", "italic"], // Toggled buttons
//       [{ header: 1 }], // Custom button values
//       [{ header: 2 }], // Custom button values
//       ["blockquote"],
//       ["link"], // Adding link and image support
//       [{ list: "bullet" }, { list: "ordered" }], // List options
//       [{ size: ["small", false, "large", "huge"] }], // Custom dropdown for text size
//     ],
//   };

const modules = {
  toolbar: [
    ["bold", "italic", "underline", "strike"], // toggled buttons
    [{ header: 1 }, { header: 2 }], // custom button values
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }], // superscript/subscript
    [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
    [{ direction: "rtl" }], // text direction
    [{ size: ["small", false, "large", "huge"] }], // custom dropdown
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ color: [] }, { background: [] }], // dropdown with defaults
    [{ font: [] }],
    [{ align: [] }],
    ["clean"], // remove formatting button
  ],
};

const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "list",
  "bullet",
  "script",
  "indent",
];

export default TextEditor;
