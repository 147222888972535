import {
  GET_USERS,
  HEADCOUNTE,
  FOOTERHOME,
  MAINSIDEBAR,
  EHREXCEPTIONEVENT,
  DEPARTMENTDETAIL,
  PREVIOUSSHIFTDATA,
  GETPREVIOUSANDCURRENTSHIFT,
  USERLOGIN,
  ACTIVEDATEFILTER,
  SELECTEDSHIFT,
  PREVIOUSSHIFTDATAMODAL,
  GRIDSHIFTDATAMODAL,
  GETPREVIOUSSHIFTDATA,
  USER_DATA,
  UPDATED_DATE,
  UPDATED_INDEX,
  STACKED_DATE,
  CUSTOMER_CODE,
  LOADING_PAGE,
  IS_NAVIGATE
} from "../constants";

export const setAirlines = (data) => {
  return {
    type: GET_USERS,
    data: data,
  };
};

export const setHeadCount = (data) => {
  return {
    type: HEADCOUNTE,
    data,
  };
};

export const setFooterHome = (data) => {
  return {
    type: FOOTERHOME,
    data,
  };
};

export const setMainSideBar = (data) => {
  return {
    type: MAINSIDEBAR,
    data,
  };
};

export const setEhrEvent = (data) => {
  return {
    type: EHREXCEPTIONEVENT,
    data,
  };
};

export const setDepartmentDetail = (data) => {
  return {
    type: DEPARTMENTDETAIL,
    data,
  };
};

export const PreviousShiftData = (data) => {
  return {
    type: PREVIOUSSHIFTDATA,
    data,
  };
};

export const getPreviousAndCurrentShift = (data) => {
  return {
    type: GETPREVIOUSANDCURRENTSHIFT,
    data,
  };
};

export const userLogin = (data) => {
  return {
    type: USERLOGIN,
    data,
  };
};

export const activedatefilter = (data) => {
  return {
    type: ACTIVEDATEFILTER,
    data,
  };
};

export const selectedshift = (data) => {
  return {
    type: SELECTEDSHIFT,
    data,
  };
};

export const previousshiftdatamodal = (data) => {
  return {
    type: PREVIOUSSHIFTDATAMODAL,
    data,
  };
};

export const gridshiftdatamodal = (data) => {
  return {
    type: GRIDSHIFTDATAMODAL,
    data,
  };
};
export const getPrevShiftData = (data) => {
  return {
    type: GETPREVIOUSSHIFTDATA,
    data,
  };
};
export const setUpdateUserData = (data) => {
  return {
    type: USER_DATA,
    data,
  };
};
export const setUpdateDate = (data) => {
  return {
    type: UPDATED_DATE,
    data,
  };
};
export const setUpdateIndex = (data) => {
  return {
    type: UPDATED_INDEX,
    data,
  };
};
export const setUpdateStack = (data) => {
  return {
    type: STACKED_DATE,
    data,
  };
};
export const setCustomerCode = (data) => {
  return {
    type: CUSTOMER_CODE,
    data,
  };
};
export const setLoadingPage = (data) => {
  return {
    type: LOADING_PAGE,
    data,
  };
};
export const setNavigate = (data) => {
  return {
    type: IS_NAVIGATE,
    data,
  };
};
