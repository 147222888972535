import styled from "styled-components";

export const Parent = styled.div`
height: 500px;
  width:35%;
  background: #191c24;
  padding: 2rem;
  color: white;
  border-radius:10px;
`;
export const heading = styled.div`
  width: 100%;
  font-size: 1.2rem;
  font-weight: bold;
`;
export const Inputsection = styled.div`
  margin: 1rem 0;
  width: 100%;
  display: flex;
  gap: 10px;
`;
export const Input = styled.input`
  padding: 0.5rem;
  font-size: 0.9rem;
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: white;
  width: calc(80% - 10px);
`;
export const Add = styled.button`
  background: #0090e7;
  border: none;
  padding: 0.5rem 1rem;
  color: white;
  border-radius: 5px;
`;
export const Label = styled.label`

  display: flex;
  align-items:center;
  justify-content:space-between;
  position: relative;
  padding-left: 35px;
  padding-bottom:1rem;
  cursor: pointer;
  font-size: 1rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
    transition:all 0.2s linear;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
  position: absolute;
  top: 4px;
  left: 0;
  height: 1rem;
  width: 1rem;
  background-color: transparent;
  border:1px solid #0090e7 ;
}


input:checked ~ .checkmark {
  background-color:#0090e7 ;
}
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
  
}
input:checked ~ .checkmark:after {
  display: block;
  
}
.checkmark:after {
  left: 4px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: solid black;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
 
}
`;

export const Listbox = styled.div`

padding: 0.5rem 0 ;
`;

